import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Card,
  CardBody,
  CardText,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  ButtonGroup,
  Button,
} from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import Pagination from '../../../components/List/Pagination'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import classnames from 'classnames'
import AddressModal from '../../../components/Modal/addressModal'

@inject('stores')
@observer
class PhoneOrderList extends Component {
  @observable
  store = this.props.stores.phoneOrder
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.order" />}
              match={this.props.match}
            />
            <AddressModal
              openModal={this.store.openModal}
              toggleModal={() => this.store.toggleModal()}
              name={this.store.name}
              changeName={(data) => this.store.changeName(data)}
              handleSave={() => this.store.validation(this.props.history)}
              btnDisable={this.store.btnDisable}
              name_validation={this.store.name_validation}
              typesList={this.store.typesList}
              selectedType={this.store.selectedType}
              setType={(value) => this.store.setType(value)}
              areasList={this.store.areasList}
              selectedArea={this.store.selectedArea}
              setArea={(value) => this.store.setArea(value)}
              fieldsList={this.store.fieldsList}
              changeFieldValue={(value, id) =>
                this.store.changeFieldValue(value, id)
              }
              type_validation={this.store.type_validation}
              area_validation={this.store.area_validation}
            />
            <Row className="col-12 justify-content-end p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-block d-md-inline-block">
                  <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.orderby" />
                      {this.store.selectedOrderOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.orderOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeOrderByList(
                                order.column,
                                this.props.history
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="d-block d-md-inline-block ">
                  <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.statusby" />
                      {this.store.selectedFilterOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.filterOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeFilterBy(
                                order.column,
                                this.props.history
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0">
                <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      value={this.store.search}
                      placeholder={`Search ( Id, Customer, Bakery )`}
                      onChange={(e) =>
                        this.store.hendleChangeKey(e.target.value)
                      }
                      onKeyPress={(event) => this.store.handleKeyPress(event)}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0 flex-wrap">
                {/* <div className="col-xl-6 col-md-6 col-sm-12 col-12  p-0 pr-lg-2 d-flex">
                  <div className="d-block d-md-inline-block">
                    <DatePicker
                      className="mr-1 float-md-left  mb-1 custom-datePicker"
                      placeholderText="Click to select 'From'"
                      selected={this.store.startDate}
                      isClearable
                      onChange={(date) =>
                        this.store.setStartDate(date, this.props.history)
                      }
                    />
                  </div>

                  <div className="d-block d-md-inline-block ml-1 ">
                    <DatePicker
                      className="mr-1 float-md-left  mb-1 custom-datePicker"
                      placeholderText="Click to select 'To'"
                      selected={this.store.endDate}
                      isClearable
                      onChange={(date) =>
                        this.store.setEndDate(date, this.props.history)
                      }
                    />
                  </div>
                </div> */}
                <div className="mb-3 col-xl-6 col-md-6 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      value={this.store.search}
                      placeholder={`Search`}
                      onChange={(e) =>
                        this.store.hendleChangeKey(e.target.value)
                      }
                      onKeyPress={(event) =>
                        this.store.handleKeyPress(event, this.props.history)
                      }
                    />
                  </div>
                </div>
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="pl-0 d-xl-block d-md-none">
                <div
                  className="col-12 p-2 d-flex align-items-center mb-3"
                  style={{ borderBottom: '1px solid gray' }}
                >
                  <p
                    className={classnames('col-2 p-2 m-0  text-center', {
                      'color-theme-1': this.store.selectedOrderOption.column.includes(
                        'customerName'
                      ),
                    })}
                    // onClick={() =>
                    //   this.store.changeOrderBy('customerName')
                    // }
                  >
                    Customer
                  </p>
                  <p
                    className={classnames('col-2 p-2 m-0  text-center', {
                      'color-theme-1': this.store.selectedOrderOption.column.includes(
                        'createdAt'
                      ),
                    })}
                    // onClick={() => this.store.changeOrderBy('createdAt')}
                  >
                    Order Date
                  </p>

                  <p
                    className={classnames('col-2 p-2 m-0  text-center', {
                      'color-theme-1': this.store.selectedOrderOption.column.includes(
                        'price'
                      ),
                    })}
                    // onClick={() => this.store.changeOrderBy('price')}
                  >
                    Phone
                  </p>

                  <p
                    className={classnames('col-4 p-2 m-0  text-center', {
                      'color-theme-1': this.store.selectedOrderOption.column.includes(
                        'deliveryStatus'
                      ),
                    })}
                    // onClick={() => this.store.changeOrderBy('deliveryStatus')}
                  >
                    {' '}
                    Order Status
                  </p>
                </div>
              </Colxx>
              {this.store.data
                ? this.store.data.map((item) => (
                    <Colxx xxs="12" className=" mb-3 pl-0" key={item.id}>
                      <Card className="col-12 pl-0 pr-0 pt-1 pb-1 pointer">
                        <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                          <div className="col-xl-12 p-0 col-md-8 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap">
                            <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Customer :{' '}
                              </span>
                              {item.name}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Order Date :{' '}
                              </span>
                              {item.createdAt.split('T')[0]}
                            </CardText>

                            <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Phone Number:{' '}
                              </span>
                              {item.phoneNumber}
                            </CardText>
                            <CardText className="col-xl-4 col-md-12 p-2 m-0 text-small  text-xl-center text-md-left">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Order Status :{' '}
                              </span>
                              <ButtonGroup className="d-flex justify-content-center">
                                <Button
                                  onClick={() =>
                                    this.store.changeStatus(
                                      'pending',
                                      item.id,
                                      this.props.history,
                                      this.state.id
                                    )
                                  }
                                  color={
                                    item.status === 'pending' ? 'info' : 'light'
                                  }
                                >
                                  Pending
                                </Button>

                                <Button
                                  onClick={() =>
                                    this.store.changeStatus(
                                      'completed',
                                      item.id,
                                      this.props.history
                                    )
                                  }
                                  color={
                                    item.status === 'completed'
                                      ? 'success'
                                      : 'light'
                                  }
                                >
                                  <IntlMessages id="pages.completed" />
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.store.changeStatus(
                                      'canceled',
                                      item.id,
                                      this.props.history
                                    )
                                  }
                                  color={
                                    item.status === 'canceled'
                                      ? 'danger'
                                      : 'light'
                                  }
                                  disabled={item.status === 'delivered'}
                                >
                                  <IntlMessages id="pages.cancelled" />
                                </Button>
                              </ButtonGroup>
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small  text-xl-center text-md-left">
                              <Button
                                size="md"
                                color="info"
                                onClick={() =>
                                  this.store.changeOpenModal({
                                    userId: item.userId,
                                    orderId: item.id,
                                    purchaseId: item.purchaseId
                                  })
                                }
                              >
                                Add Address
                              </Button>
                            </CardText>
                          </div>
                        </CardBody>
                      </Card>
                    </Colxx>
                  ))
                : null}
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default PhoneOrderList
