import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ShopList from './list'
import ShopDetail from './detail'

const Shop = ({ match }) => {
  if (window.localStorage.getItem('lilas_role')) {
    const role = window.localStorage.getItem('lilas_role')
    return (
      <div className="dashboard-wrapper">
        <Switch>
          <Redirect
            exact
            from={`${match.url}/`}
            to={
              role === 'admin'
                ? `${match.url}/list`
                : `${match.url}/detail/${window.localStorage.getItem(
                    'lilas_shop_id'
                  )}`
            }
          />
          <Route
            path={`${match.url}/list`}
            component={ ShopList}
          />
          <Route path={`${match.url}/detail/:id`} component={ShopDetail} />
          <Redirect to="/error" />
        </Switch>
      </div>
    )
  } else {
    return (
      <div className="dashboard-wrapper">
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`/login`} />
        </Switch>
      </div>
    )
  }
}
export default Shop
