import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Input, Card, CardBody, CardTitle } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import DeleteModal from '../../../components/Modal/deleteModal'
import { ToastContainer } from 'react-toastify'
import SelectInput from '../../../components/select/index'

@inject('stores')
@observer
class FieldDetail extends Component {
  @observable
  store = this.props.stores.addressTypeDetail
  constructor(props) {
    super(props)
    this.state = { id: null }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    const {fieldsList}= this.store
    console.log(fieldsList)
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.addressType" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={
                  this.store.typeDetail ? this.store.typeDetail.name_en : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteType(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() =>
                  this.store.validation(this.state.id, this.props.history)
                }
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <Row>
                    <Colxx xxs="12" md="6" lg="4" className="mb-4 ">
                      <Card className="mb-2" style={{overflow:'unset'}}>
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              Address Type Detail
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.typeDetail
                                    ? this.store.typeDetail.name_en
                                    : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.typeDetail
                                    ? this.store.typeDetail.name_ar
                                    : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.field" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <SelectInput
                                className="react-select"
                                classNamePrefix="react-select"
                                value={this.store.selectedField}
                                onChange={(value) => this.store.setType(value)}
                                options={fieldsList}
                                isMulti={true}
                              ></SelectInput>
                            </div>
                            <span className="req-validation ">
                              {' '}
                              {this.store.field_validation
                                ? this.store.field_validation.msg
                                  ? this.store.field_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default FieldDetail
