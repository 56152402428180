import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import _ from 'lodash'
import axiFetch from '../config/fetch'
import { errorNotify, successNotify } from '../util/Notify'

export default class ProductDetailStore {
  @observable
  isLoading = true

  @observable
  openItemModal = false

  @observable
  arName = null

  @observable
  enName = null

  @observable
  price = null

  @observable
  enDescription = null

  @observable
  arDescription = null

  @observable
  productList = null

  @observable
  imageFile = null

  @observable
  images = null

  @observable
  category = null

  @observable
  categoryId = null

  @observable
  categoriesList = []

  @observable
  typesList = []

  @observable
  type = null

  @observable
  data = null

  @observable
  cakeDetail = null

  @observable
  orderImages = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  openDeleteModal = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  serving_validation = null

  @observable
  price_validation = null

  @observable
  category_validation = null

  @observable
  bakery_validation = null

  @observable
  searchProduct = null

  @observable
  searchOnProduct = null

  @observable
  limitProduct = 12

  @observable
  offsetProduct = 0

  @observable
  orderProduct = { id: -1 }

  @observable
  filterProduct = {}

  @observable
  currentPageProduct = 1

  @observable
  isLoadingProduct = false

  @observable
  openDeleteModal = false

  @observable
  openProductModal = false

  @observable
  pagination = null

  @observable
  paginationProduct = null

  @observable
  is_available = true

  @observable
  old_price = null

  @observable
  discount = true

  @observable
  shopsList = []

  @observable
  shop = null

  @observable
  en_description_validation = null

  @observable
  showUploader = true

  @action
  handleImageUploader = () => {
    this.showUploader = !this.showUploader
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file, id, history) {
    if (file) {
      this.imageFile = await this.base64ToBlob(file, 'image/jpg')

      const data = {
        file: this.imageFile,
      }
      let formData = new FormData()
      for (let key in data) formData.append(key, data[key])

      const value = await axiFetch.request(
        `products/${id}/addImage`,
        false,
        null,
        false,
        'post',
        false,
        formData,
        history
      )
      if (value) {
        // this.images = value.data
        // successNotify('Item has been added successfully')
        this.handleImageUploader()
        this.orderImages = [value.data]
        await this.data.images.forEach((item) => this.orderImages.push(item.id))
        this.updateImageOrder(id, history)
      }
    }
  }

  @action
  handleDownloadImage = () => {
    var a = document.createElement('a')
    a.href = this.images[0].url
    a.download = 'download'
    a.target = 'blank'
    a.click()
    a = null
  }

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.data.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.data.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.data.description_en,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_description_validation = await checkInput({
      name: 'Arabic Description',
      value: this.data.description_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.category_validation = await checkInput({
      name: 'Category',
      value: this.category[0],
      require: true,
    })

    this.shop_validation = await checkInput({
      name: 'Shop',
      value: this.shop[0],
      require: true,
    })
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.data.price.currentPrice,
      require: true,
      type: 'number',
      minValue: '0',
    })

    this.stock_validation = await checkInput({
      name: 'Stock',
      value: this.data.stock,
      require: true,
      type: 'number',
      minValue: '0',
    })

    this.width_validation = await checkInput({
      name: 'Width',
      value: this.data.width,
      require: true,
      type: 'number',
      minValue: '0',
    })

    this.height_validation = await checkInput({
      name: 'Height',
      value: this.data.height,
      require: true,
      type: 'number',
      minValue: '0',
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.type,
      require: true,
    })

    this.price_validation.res &&
      this.stock_validation.res &&
      this.width_validation.res &&
      this.height_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.en_description_validation.res &&
      this.ar_description_validation.res &&
      this.type_validation.res &&
      this.category_validation.res &&
      this.shop_validation.res &&
      this.handleUpdateProduct(id, history)
  }

  @action
  async getDetail(id, history, role) {
    this.isLoading = true
    this.imageFile = null
    const value = await axiFetch.request(
      'products',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.data = value.data
      this.category = [
        {
          value: value.data.category.id,
          label: value.data.category.name_en,
          image: value.data.category.media.url,
        },
      ]
      this.categoriesList = _.differenceWith(
        this.categoriesList,
        this.category,
        _.isEqual
      )

      this.type = {
        value: value.data.types[0].id,
        label: value.data.types[0].name_en,
      }
      this.shop = [
        {
          value: value.data.shop.id,
          label: value.data.shop.name_en,
          image: value.data.shop.media ? value.data.shop.media.url : null,
        },
      ]
      this.shopsList = _.differenceWith(this.shopsList, this.shop, _.isEqual)
      await this.getDependencies(history, role)
      this.isLoading = false
    } else {
      errorNotify('Something Wrong')
      // this.isLoading = false
    }
  }

  @action
  async getDependencies(history, role) {
    this.isLoading = true

    const categories = await axiFetch.request(
      'categories',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (categories) {
      await categories.data.result.map((category) =>
        this.categoriesList.push({
          value: category.id,
          label: category.name_en,
          image: category.media && category.media.url,
        })
      )
    }

    if (role === 'admin') {
      const shops = await axiFetch.request(
        'shops',
        false,
        false,
        false,
        'get',
        false,
        null,
        history
      )
      if (shops) {
        await shops.data.items.map((shop) =>
          this.shopsList.push({
            value: shop.id,
            label: shop.name_en,
            image: shop.media && shop.media.url,
          })
        )
      }
    }

    const types = await axiFetch.request(
      'types',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (types) {
      await types.data.items.map((type) =>
        this.typesList.push({
          value: type.id,
          label: type.name_en,
        })
      )
    }
  }

  @action
  async deleteProduct(id, history) {
    this.isLoading = true
    const value = await axiFetch.request(
      `products/${id}/delete`,
      false,
      false,
      false,
      'delete',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/product')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  handleUpdateProduct = async (id, history) => {
    this.isLoading = true
    // const similar = []

    // await this.productList.forEach((item) => {
    //   similar.push(item.id)
    // })

    if (!this.orderImages) {
      this.orderImages = []
      await this.data.images.forEach((item) => this.orderImages.push(item.id))
    }
    const data = {
      name_en: this.data.name_en,
      name_ar: this.data.name_ar,
      price: this.data.price.currentPrice,
      description_en: this.data.description_en,
      imageOrders: this.orderImages,
      typeId: this.type.value,
      categoryId: this.category[0].value,
      shopId: this.shop[0].value,
      stock: this.data.stock,
      width: this.data.width,
      height: this.data.height,
      is_discount: this.data.price.is_discount ? 1 : 0,
      // similar: JSON.stringify(similar),
      is_available: this.data.is_available ? 1 : 0,
      description_ar: this.data.description_ar,
      // oldPrice: this.data.price.is_discount ? this.data.price.old_price : 0,
    }
    if (this.data.price.is_discount) {
      data.old_price = this.data.price.oldPrice
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      `products/${id}`,
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  updateImageOrder = async (id, history) => {
    this.isLoading = true
    const data = {
      imageOrders: this.orderImages,
    }

    const value = await axiFetch.request(
      `products/${id}`,
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeOpenItemModal = () => {
    this.openItemModal = true
  }

  @action
  toggleItemModal = () => {
    this.openItemModal = false
  }

  @action
  changeEnName(value) {
    this.data.name_en = value
  }

  @action
  changeArName(value) {
    this.data.name_ar = value
  }

  @action
  changeEnDescription(value) {
    this.data.description_en = value
  }

  @action
  changeArDescription(value) {
    this.data.description_ar = value
  }

  @action
  changePrice(value) {
    this.data.price.currentPrice = value
  }

  @action
  changeHeight = (value) => {
    this.data.height = value
  }

  @action
  changeWidth = (value) => {
    this.data.width = value
  }

  @action
  changeStock = (value) => {
    this.data.stock = value
  }

  @action
  setItems(data, target) {
    switch (target) {
      case 'category':
        this.setCategory(data)
        break
      case 'shop':
        this.setShop(data)
        break
      default:
        break
    }
  }

  @action
  setType(value) {
    this.type = value.selectedOption
  }

  @action
  async setCategory(value) {
    await this.category.push(...value)
    this.categoriesList = await _.differenceWith(
      this.categoriesList,
      this.category,
      _.isEqual
    )
    this.toggleItemModal()
  }
  @action
  removeCategory(value) {
    const data = []
    this.category.forEach((item) => {
      if (item.value !== value.value) {
        data.push(item)
      }
    })
    this.category = data
    this.categoriesList.push(value)
    this.categoriesList = _.intersectionBy(this.categoriesList, 'value')
  }

  @action
  async setShop(value) {
    await this.shop.push(...value)
    this.shopsList = await _.differenceWith(
      this.shopsList,
      this.shop,
      _.isEqual
    )
    this.toggleItemModal()
  }

  @action
  removeShop = (value) => {
    const data = []
    this.shop.forEach((item) => {
      if (item.value !== value.value) {
        data.push(item)
      }
    })
    this.shop = data
    this.shopsList.push(value)

    this.shopsList = _.intersectionBy(this.shopsList, 'value')
  }

  @action
  getProduct = async (history) => {
    this.isLoadingProduct = true
    this.changeOpenProductModal()
    const body = {
      order: this.orderProduct,
      searchOn: this.searchOnProduct,
      search: this.searchProduct,
      limit: this.limitProduct,
      offset: this.offsetProduct,
      filter: this.filterProduct,
    }

    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getcakes',
      body,
      history
    )
    if (value) {
      this.products = value.data.items
      this.paginationProduct =
        parseInt(value.data.pagination.total / this.limitProduct) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoadingProduct = false
    }
  }

  @action
  changeDiscount() {
    this.data.price.is_discount = !this.data.price.is_discount
  }

  @action
  async handleDeleteImage(id, imageId, history) {
    const data = { productId: Number(id), imageId: imageId }
    const value = await axiFetch.request(
      'products/image',
      false,
      false,
      false,
      'delete',
      false,
      data,
      history
    )

    if (value) {
      successNotify('Item has been deleted successfully')

      this.orderImages = []

      await this.data.images.forEach((item) => {
        if (item.id !== imageId) this.orderImages.push(item.id)
      })
      this.updateImageOrder(id, history)
    }
  }

  @action
  changeOpenProductModal = () => {
    this.openProductModal = true
  }

  @action
  toggleProductModal = () => {
    this.openProductModal = false
  }

  @action
  changePage(e) {
    this.offsetProduct = (e - 1) * 12
    this.currentPageProduct = e
    this.getProduct()
  }

  @action
  addItemToSelected = async (value) => {
    await value.map((item) => this.productList.push(item))

    const intersection = _.intersectionBy(this.productList, 'id')
    this.productList = [...intersection]
  }

  @action
  removeFromList = (id) => {
    const product = this.productList.filter((item) => item.id !== id)
    this.productList = product
  }

  @action
  changeOrderImages(arr) {
    this.orderImages = arr
  }

  @action
  handleIsAvailable() {
    this.data.is_available = !this.data.is_available
  }

  @action
  changeOldPrice(value) {
    this.data.price.oldPrice = value
  }

  @action
  reset() {
    this.isLoading = true

    this.openItemModal = false

    this.arName = null

    this.enName = null

    this.price = null

    this.enDescription = null

    this.arDescription = null

    this.productList = null

    this.imageFile = null

    this.images = null

    this.category = null

    this.categoryId = null

    this.categoriesList = []

    this.typesList = []

    this.type = null

    this.data = null

    this.cakeDetail = null

    this.orderImages = null

    this.search = null

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.openDeleteModal = false

    this.en_name_validation = null

    this.ar_name_validation = null

    this.serving_validation = null

    this.price_validation = null

    this.category_validation = null

    this.bakery_validation = null

    this.searchProduct = null

    this.searchOnProduct = null

    this.limitProduct = 12

    this.offsetProduct = 0

    this.orderProduct = { id: -1 }

    this.filterProduct = {}

    this.currentPageProduct = 1

    this.isLoadingProduct = false

    this.openDeleteModal = false

    this.openProductModal = false

    this.pagination = null

    this.paginationProduct = null

    this.is_available = true

    this.old_price = null

    this.discount = true

    this.shopsList = []

    this.shop = null

    this.en_description_validation = null

    this.showUploader = true
  }
}
