import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Thumb } from '../Thumb'
import { Button } from 'reactstrap'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

class ImageGalleryCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: null,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    this.setState({ items: this.props.data })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.data })
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    )

    this.setState({
      items,
    })

    let orderImage = []
    items.map((item) => orderImage.push(item.id))

    this.props.changeOrderImages(orderImage)
  }

  render() {
    return this.state.items ? (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.state.items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`${item.id}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div style={{ position: 'relative', zIndex: 10 }}>
                        {this.state.items.length > 1 && (
                          <Button
                            onClick={() => this.props.deleteImage(item.id)}
                            color="primary"
                            style={{
                              position: 'absolute',
                              width: '30px',
                              height: '30px',
                              borderRadius: '50%',
                              padding: '0',
                              right: '5px',
                              top: '5px',
                              display: 'flex',
                              justifyContent: 'center',
                              zIndex: 1,
                            }}
                          >
                            <i
                              style={{ fontSize: '20px' }}
                              className="iconsminds-close"
                            />
                          </Button>
                        )}
                        <Thumb
                          key="cat-image"
                          className="almost-full-width"
                          src={item.url}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) : null
  }
}

export default ImageGalleryCard
