import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'

export default class UserStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  name = null

  @observable
  email = null

  @observable
  btnDisable = false

  @observable
  typesList = [
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Vendor' },
  ]

  @observable
  selectedType = null

  @observable
  bakeriesList = []

  @observable
  selectedBakery = null

  @observable
  email_validation = null

  @observable
  name_validation = null

  @observable
  type_validation = null

  @observable
  bakery_validation = null

  @action
  async validation(history, type, bakeryId) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.name,
      require: true,
      type: 'string',
      min: 3,
    })
    this.email_validation = await checkInput({
      name: 'Email',
      value: this.email,
      require: true,
      type: 'email',
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    if (this.selectedType && this.selectedType.label !== 'Admin') {
      this.bakery_validation = await checkInput({
        name: 'Shop',
        value: this.selectedBakery,
        require: true,
      })
    }

    // if (type === 'Admin') {
      if (this.selectedType && this.selectedType.label !== 'Admin') {
        this.email_validation.res &&
          this.name_validation.res &&
          this.type_validation.res &&
          this.bakery_validation.res &&
          this.handleSaveNewAdmin(history, type, bakeryId)
      } else {
        this.email_validation.res &&
          this.name_validation.res &&
          this.type_validation.res &&
          this.handleSaveNewAdmin(history, type, bakeryId)
      }
    // } else {
    //   this.email_validation.res &&
    //     this.name_validation.res &&
    //     this.handleSaveNewAdmin(history, type, bakeryId)
    // }
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      filters: this.filter,
      search: this.search,
      pagination: {
        page: this.offset,
        limit: this.limit,
      },
    }
    const value = await axiFetch.request(
      'admins',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =value.data.explain.pagination.totalPages
       
      this.getDependencies(history)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const value = await axiFetch.request(
      'shops',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      await value.data.items.map((item) =>
        this.bakeriesList.push({ value: item.id, label: item.name_en })
      )
    }
  }

  @action
  handleSaveNewAdmin = async (history, type, bakeryId) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      name: this.name,
      email: this.email,
      role: this.selectedType.label ==='Admin'? 9: 2,
      // bakery_id:
      //   this.selectedType.label !== 'admin' ? this.selectedBakery.value : null,
    }
    if (this.selectedType && this.selectedType.label !== 'Admin') {
      data.shopId = this.selectedBakery.value
    }

    const value = await axiFetch.request(
      'admins/new',
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      this.openModal = false
      this.btnDisable = false
      history.push(`/app/users/detail/${value.data}`)
    } else {
      this.btnDisable = false
      this.isLoading = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.name = null
    this.email = null
    this.selectedBakery = null
    this.selectedType = null
    this.name_validation = null
    this.email_validation = null
    this.type_validation = null
    this.bakery_validation = null
  }

  @action
  changeName = (value) => {
    this.name = value
  }

  @action
  changeEmail = (value) => {
    this.email = value
  }

  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }

  @action
  setBakery(value) {
    this.selectedBakery = value.selectedOption
  }

  @action
  setType(value) {
    this.selectedType = value.selectedOption
  }

  @action
  reset() {
    this.data = null
  
    this.pagination = 0
  
    this.currentPage = 1
  
    this.search = ''
  
    this.searchOn = null
  
    this.limit = 12
  
    this.offset = 1
  
    this.order = { createdAt: -1 }
  
    this.filter = {}
  
    this.isLoading = true
  
    this.openModal = false
  
    this.name = null
  
    this.email = null
  
    this.btnDisable = false
  
    this.typesList = [
      { value: 1, label: 'Admin' },
      { value: 2, label: 'Vendor' },
    ]
  
    this.selectedType = null
  
    this.bakeriesList = []
  
    this.selectedBakery = null
  
    this.email_validation = null
  
    this.name_validation = null
  
    this.type_validation = null
  
    this.bakery_validation = null
  }
}
