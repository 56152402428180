import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  // Label,
} from 'reactstrap'
import SelectInput from '../select'
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'

class AddressTypeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      openModal,
      toggleModal,
      name_en,
      name_ar,
      fieldsList,
      selectedField,
      setType,
      changeArName,
      changeEnName,
      handleSave,
      btnDisable,
      name_en_validation,
      name_ar_validation,
      field_validation,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Colxx xxs="12" className="mb-3 p-0">
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.name" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="name"
                  value={name_en ? name_en : ''}
                  type="text"
                  onChange={(e) => changeEnName(e.target.value)}
                />
              </div>
              <span className="req-validation ">
                {' '}
                {name_en_validation
                  ? name_en_validation.msg
                    ? name_en_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.name-ar" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="name_ar"
                  value={name_ar ? name_ar : ''}
                  type="text"
                  onChange={(e) => changeArName(e.target.value)}
                  className="ar"
                />
              </div>
              <span className="req-validation">
                {' '}
                {name_ar_validation
                  ? name_ar_validation.msg
                    ? name_ar_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
            
            <Colxx xxs="12" className=" mb-3 p-0">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="menu.addressType" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <SelectInput
                  className="react-select"
                  classNamePrefix="react-select"
                  value={selectedField}
                  onChange={(value) => setType(value)}
                  options={fieldsList}
                  isMulti={true}
                ></SelectInput>
              </div>
              <span className="req-validation ">
                {' '}
                {field_validation
                  ? field_validation.msg
                    ? field_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default AddressTypeModal
