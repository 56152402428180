import { observable, action } from "mobx";
import axiFetch from "../config/fetch";
import { checkInput } from "../constants/validation";
import { successNotify } from "../util/Notify";


export default class StaticStore {
  @observable
  data = null;

  @observable
  staticDetail = null;

  @observable
  selectedOrderOption = {
    column: "createdAt_asc",
    label: "Created Date (Ascending)",
  };

  @observable
  orderOptions = [
    { column: "name", label: "Title" },
    { column: "name_asc", label: "Title (Ascending)" },
    { column: "createdAt", label: "Created Date" },
    { column: "createdAt_asc", label: "Created Date (Ascending)" },
  ];

  @observable
  pagination = 0;

  @observable
  currentPage = 1;

  @observable
  search = "";

  @observable
  searchOn = null;

  @observable
  limit = 20;

  @observable
  offset = 0;

  @observable
  order = {
    [this.selectedOrderOption.column.includes("_asc")
      ? this.selectedOrderOption.column.split("_asc")[0]
      : this.selectedOrderOption
          .column]: this.selectedOrderOption.column.includes("_asc") ? 1 : -1,
  };

  @observable
  filter = {};

  @observable
  isLoading = true;

  @observable
  detailLoading = true;

  @observable
  ar_name_validation = null;

  @observable
  en_name_validation = null;

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: "English Name",
      value: this.staticDetail.name_en,
      require: true,
      type: "string",
      min: 3,
    });
    this.ar_name_validation = await checkInput({
      name: "Arabic Name",
      value: this.staticDetail.name_ar,
      require: true,
      type: "string",
      min: 3,
    });

    this.en_name_validation.res &&
      this.ar_name_validation.res &&
      this.handleUpdateStatic(history);
  }

  @action
  getData = async (history) => {
    this.isLoading = true;
    const value = await axiFetch.request(
      "statics",
      false,
      false,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.data = value.data.items;
      this.isLoading = false;
    }
  };

  @action
  getStaticDetail = async (id, history) => {
    this.detailLoading = true;

    const value = await axiFetch.request(
      "statics",
      false,
      id,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.staticDetail = value.data;
      this.detailLoading = false;
    } else {
      this.detailLoading = false;
    }
  };

  @action
  handleUpdateStatic = async (id, history) => {
    this.detailLoading = true;
    let body = {
      name_en: this.staticDetail.name_en,
      name_ar: this.staticDetail.name_ar,
      html_en: this.staticDetail.html_en,
      html_ar: this.staticDetail.html_ar,
      in_app: this.staticDetail.in_app,
    };

    const value = await axiFetch.request(
      "statics",
      false,
      id,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      successNotify("Item has been deleted successfully");
      this.getStaticDetail(id, history);
    } else {
      this.detailLoading = false;
    }
  };

  @action
  changeEnName = (value) => {
    this.staticDetail.name_en = value;
  };

  @action
  changeArName = (value) => {
    this.staticDetail.name_ar = value;
  };

  @action
  handleIsActive = () => {
    this.staticDetail.in_app = !this.staticDetail.in_app;
  };

  @action
  changePage(e) {
    this.offset = (e - 1);
    this.currentPage = e;
    this.getData();
  }

  @action
  setRole(value) {
    this.selectedRole = value.selectedOption;
  }

  @action
  setOrg(value) {
    this.selectedOrg = value.selectedOption;
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value;
  };

  @action
  handleKeyPress = (e, history) => {
    if (e.key === "Enter") {
      this.searchOn = ["name_en"];
      this.offset = 0;
      this.currentPage = 1;
      this.getData(history);
    }
  };

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    );
    this.order = {
      [this.selectedOrderOption.column.includes("_asc")
        ? this.selectedOrderOption.column.split("_asc")[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes("_asc") ? 1 : -1,
    };
    this.offset = 0;
    this.currentPage = 1;
    this.getData(history);
  };

  @action
  handleChangeEnContent = (data) => {
    this.staticDetail.html_en = data;
  };

  @action
  handleChangeArContent = (data) => {
    this.staticDetail.html_ar = data;
  };

  @action
  reset() {
    this.isLoading = true;

    this.detailLoading = true

    this.data = null;

    this.staticDetail = null;

    this.selectedOrderOption = {
      column: "createdAt_asc",
      label: "Created Date (Ascending)",
    };

    this.orderOptions = [
      { column: "name", label: "Title" },
      { column: "name_asc", label: "Title (Ascending)" },
      { column: "createdAt", label: "Created Date" },
      { column: "createdAt_asc", label: "Created Date (Ascending)" },
    ];

    this.pagination = 0;

    this.currentPage = 1;

    this.search = "";

    this.searchOn = null;

    this.limit = 20;

    this.offset = 0;

    this.order = {
      [this.selectedOrderOption.column.includes("_asc")
        ? this.selectedOrderOption.column.split("_asc")[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes("_asc") ? 1 : -1,
    };

    this.filter = {};

    this.ar_name_validation = null;

    this.en_name_validation = null;
  }
}
