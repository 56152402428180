import React, { Component } from 'react'
import {
  Modal,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  CardImg,
  CardText,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'

class ItemModal extends Component {
  constructor(props) {
    super(props)
    this.state = { items: [] }
  }
  async componentDidMount() {
    const data = []
    const filterData = []
    if (this.props.data) {
      await this.props.data.forEach((item) => {
        if (this.props.selected) {
          if (this.props.isMulti) {
          } else {
            if (this.props.selected === item.id) {
              item = { ...item, selected: true }
              data.push(item)
            } else {
              item = { ...item, selected: false }
              data.push(item)
            }
          }
        } else {
          item = { ...item, selected: false }
          data.push(item)
        }
      })
    }

    if (this.props.selectedList && this.props.selectedList.length) {
      await data.forEach((item) => {
        this.props.selectedList.forEach((id) => {
          if (item.id === id) {
            filterData.push(item)
          }
        })
      })
      const diff = await data.filter((x) => !filterData.includes(x))
      //  _.differenceWith(
      //   this.props.data,
      //   this.filterData,
      //   _.is
      // )

      await this.setState({ items: [...diff] })
    } else {
      await this.setState({ items: [...data] })
    }
  }
  UNSAFE_componentWillMount() {
    this.setState({ items: [] })
  }
  componentWillUnmount() {
    this.setState({ items: [] })
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const data = []
    await nextProps.data.forEach((item) => {
      item = { ...item, selected: false }
      data.push(item)
    })

    await this.setState({ items: [...data] })
  }
  cancelCustomItem = () => {
    this.props.toggleModal()
  }

  selectedItem = async (value) => {
    if (this.props.isMulti) {
      await this.state.items.forEach((item) => {
        if (item.value === value.value) {
          item.selected = !item.selected
        }
      })
    } else {
      await this.state.items.forEach((item) => {
        if (item.selected) {
          item.selected = !item.selected
        }
      })
      await this.state.items.forEach((item) => {
        if (
          this.props.target === 'collection' || this.props.target === 'banner'
            ? item.id === value.id
            : item.value === value.value
        ) {
          item.selected = !item.selected
        }
      })
    }
    this.setState({ items: this.state.items })
  }

  submitCustomItem = async () => {
    let data = []
    await this.state.items.forEach((item) => {
      if (item.selected) {
        delete item.selected
        data.push(item)
      }
    })
    this.props.addItems(data, this.props.target, this.props.selected)
    // if (this.props.target !== 'collection' || this.props.target !== 'banner') {
    //   this.props.toggleModal()
    // }
  }

  render() {
    const { openModal, target, toggleModal } = this.props
    return (
      <Modal isOpen={openModal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id={`menu.${target}`} />
        </ModalHeader>
        <div className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2">
          {this.state.items
            ? this.state.items.map((item, index) => (
                <Card
                  key={index + 1}
                  onClick={() => this.selectedItem(item)}
                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1 pointer"
                  style={{
                    width: 'max-content',
                    maxWidth: '100%',
                    border: item.selected
                      ? '1px solid green'
                      : '1px solid #ddd',
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div
                      className=" p-0 m-0"
                      style={{ width: '40px !important' }}
                    >
                      <div
                        className="position-relative col-12 m-0"
                        style={{ paddingTop: '100%' }}
                      >
                        <CardImg
                          src={
                            item.media && item.media.url
                              ? item.media.url
                              : item.media && !item.media.url
                              ? item.media
                              : '/assets/img/noimage.png'
                          }
                          className="position-absolute"
                          style={{
                            borderRadius: '50%',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            left: 0,
                          }}
                        ></CardImg>
                      </div>
                    </div>
                    <CardText className="text-small pl-2 pr-2 m-0">
                      {target === 'collection' ? item.name_en : item.label}
                    </CardText>
                    <i
                      className={
                        item.selected
                          ? 'iconsminds-yes selected-icon'
                          : 'iconsminds-add add-icon'
                      }
                      // onClick={() => this.selectedItem(item)}
                    ></i>
                  </div>
                </Card>
              ))
            : null}
        </div>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => this.submitCustomItem()}
          >
            {`Save`}
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            onClick={() => this.cancelCustomItem()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ItemModal
