import axios from 'axios'
import api from '../api'
import { errorNotify } from '../../util/Notify'

export default new (class {
  request(
    resource,
    extra,
    id,
    query,
    method,
    type,
    body,
    history,
    headerObject
  ) {
    return this._requestMaker(
      api.API(resource, extra, id, query, method, type),
      method,
      body,
      type,
      history,
      headerObject
    )
  }
  async _requestMaker(api, method, body, type, history, headerObject) {
    try {
      const header = headerObject
        ? headerObject
        : {
            'Content-Type': 'application/json;charset=UTF-8',
          }

      let res = await axios.request(
        this._configurator(api, method, header, body, 50000)
      )
      return res.data
    } catch (e) {
      this._handleError(e, history)
      return null
    }
  }

  _configurator(url, method, header, body, timeout) {
    let config = {
      withCredentials: true,
    }
    config.url = url
    config.method = method
    if (header) {
      config.headers = header
    }
    if (body) {
      config.data = body
    } else {
      config.data = {}
    }
    if (timeout) {
      config.timeout = timeout
    }
    config.responseEncoding = 'utf8'
    return config
  }

  _handleError(error, history) {
    if (error && error.response && error.response.status === 400) {
      errorNotify(error.response.data.meta.msg)
    }
    if (
      error &&
      error.response &&
      error.response.status === 500 &&
      error.response.data.meta.msg
    ) {
      errorNotify(error.response.data.meta.msg)
    }
    if (error && error.response && error.response.status === 403 && error.response.data.meta.msg) {
      errorNotify(error.response.data.meta.msg)
      if(history){
        history.push('/login')
      }
    }
    if (error && error.response && error.response.status === 401) {
      // errorNotify(error.response.data.meta.msg)
      if (history) {
        history.push('/login')
      }
    }
  }
})()
