import LoginStore from './login.store'
import SettingStore from './setting.store'
import MenuStore from './menu.store'
import ProductStore from './product.store'
import ProductDetailStore from './productDetail.store'
import AddProductStore from './addProduct.store'
import CategoryStore from './category.store'
import TypeStore from './type.store'
import TypeDetailStore from './typeDetail.store'
import AreaStore from './area.store'
import AreaDetailStore from './areaDetail.store'
import ShopStore from './shop.store'
import ShopDetailStore from './shopDetail.store'
import CustomHomeStore from './customHome.store'
import BannerStore from './banner.store'
import BannerDetailStore from './bannerDetail.store'
import CouponStore from './coupon.store'
import StaticStore from './static.store'
import PhoneOrderStore from './phoneOrder.store'
import FieldStore from './field.store'
import FieldDetailStore from './fieldDetail.store'
import AddressTypeStore from './addressType.store'
import AddressTypeDetailStore from './addressTypeDetail.store'


import UserStore from './user.store'
import UserDetailStore from './userDetail.store'
import CustomerStore from './customer.store'
import CustomerDetailStore from './customerDetail.store'
import CustomCakeStore from './customCake.store'
import OrderStore from './order.store'
import CollectionStore from './collection.store'
import CollectionDetailStore from './collectionDetail.store'
import FeaturetteStore from './featurette.store'
import FeaturetteDetailStore from './featuretteDetail.store'
import DashboardStore from './dashboard.store'
import ProfileStore from './profile.store'
import RequireStore from './require.store'
import SettingAppStore from "./settingApp.store";


const login = new LoginStore()
const setting = new SettingStore()
const menu = new MenuStore()
const product = new ProductStore()
const productDetail = new ProductDetailStore()
const addProduct = new AddProductStore()
const category = new CategoryStore()
const type = new TypeStore()
const typeDetail = new TypeDetailStore()
const area = new AreaStore()
const areaDetail = new AreaDetailStore()
const shop = new ShopStore()
const shopDetail = new ShopDetailStore()
const customHome = new CustomHomeStore()
const banner = new BannerStore()
const bannerDetail = new BannerDetailStore()
const coupon = new CouponStore()
const staticPage = new StaticStore()
const phoneOrder = new PhoneOrderStore()
const field = new FieldStore()
const fieldDetail = new FieldDetailStore()
const addressType = new AddressTypeStore()
const addressTypeDetail = new AddressTypeDetailStore()

const user = new UserStore()
const userDetail = new UserDetailStore()
const customer = new CustomerStore()
const customerDetail = new CustomerDetailStore()
const customCake = new CustomCakeStore()
const order = new OrderStore()
const collection = new CollectionStore()
const collectionDetail = new CollectionDetailStore()
const featurette = new FeaturetteStore()
const featuretteDetail = new FeaturetteDetailStore()
const dashboard = new DashboardStore()
const profile = new ProfileStore()
const require = new RequireStore()
const settingApp = new SettingAppStore();


export default {
  login,
  setting,
  menu,
  product,
  productDetail,
  addProduct,
  category,
  type,
  typeDetail,
  area,
  areaDetail,
  shop,
  shopDetail,
  customHome,
  banner,
  bannerDetail,
  staticPage,
  user,
  userDetail,
  customer,
  customerDetail,
  settingApp,
  phoneOrder,
  field,
  fieldDetail,
  addressType,
  addressTypeDetail,
  
  customCake,
  coupon,
  order,
  collection,
  collectionDetail,
  featurette,
  featuretteDetail,
  dashboard,
  profile,
  require,
}
