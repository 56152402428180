import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  CustomInput,
  Label,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'
import ImageUploader from '../Uploader/Uploader'
import { FormikSwitch } from '../FormValidations/FormikFields'
import SelectInput from '../select'

class CategoryModal extends Component {
  constructor(props) {
    super(props)
    this.state = { getImage: false }
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeImageFile(data)
    } else {
      this.setState({ getImage: false })
    }
    this.props.handleSave()
  }

  render() {
    const {
      openModal,
      toggleModal,
      name_en,
      name_ar,
      changeArName,
      changeEnName,
      btnDisable,
      name_en_validation,
      name_ar_validation,
      imageFile_validation,
      isDynamic,
      changeIsDynamic,
      from,
      to,
      changeFromPrice,
      changeToPrice,
      discount,
      changeDiscount,
      selectedTypes,
      setType,
      typeList,
      handleDiscountFilter,
      discountFilter,
      target,
      aspectRation,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Colxx xxs="12" className="mb-3 p-0">
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.name" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="name"
                  value={name_en ? name_en : ''}
                  type="text"
                  onChange={(e) => changeEnName(e.target.value)}
                />
              </div>
              <span className="req-validation ">
                {' '}
                {name_en_validation
                  ? name_en_validation.msg
                    ? name_en_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>

            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.name-ar" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="name_ar"
                  value={name_ar ? name_ar : ''}
                  type="text"
                  onChange={(e) => changeArName(e.target.value)}
                  className="ar"
                />
              </div>
              <span className="req-validation">
                {' '}
                {name_ar_validation
                  ? name_ar_validation.msg
                    ? name_ar_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
            {target === 'category' ? null : (
              <Colxx xxs="12" className=" mb-3 p-0">
                <p className="text-muted text-small mb-2">Is Dynamic</p>
                <div className="mb-0 p-0">
                  <FormikSwitch
                    className="custom-switch custom-switch-primary-inverse"
                    name={'is_dynamic'}
                    value={isDynamic}
                    onChange={() => changeIsDynamic()}
                  />
                </div>
              </Colxx>
            )}

            {isDynamic ? (
              <>
                <Colxx xxs="12" className="p-0 mb-3">
                  <p className="text-muted text-small mb-2">Price (From)</p>
                  <div className="mb-0">
                    <Input
                      name="from"
                      value={from ? from : 0}
                      type="number"
                      onChange={(e) => changeFromPrice(e.target.value)}
                    />
                  </div>
                </Colxx>

                <Colxx xxs="12" className="p-0 mb-3">
                  <p className="text-muted text-small mb-2">Price (To)</p>
                  <div className="mb-0">
                    <Input
                      name="to"
                      value={to ? to : 0}
                      type="number"
                      onChange={(e) => changeToPrice(e.target.value)}
                    />
                  </div>
                </Colxx>
                <Colxx xxs="12" className="p-0 mb-3">
                  <div>
                    <CustomInput
                      className="less-pad-l"
                      key={`remember-me`}
                      type="checkbox"
                      id={`remember-me`}
                      label={
                        <Label
                          className="perm-lbl pointer unselectable italic-on-hover text-small mt-1"
                          onClick={() => {
                            handleDiscountFilter()
                          }}
                        >
                          Discount Filter
                        </Label>
                      }
                      checked={discountFilter}
                      onChange={() => {
                        handleDiscountFilter()
                      }}
                    />
                  </div>
                </Colxx>
                <Colxx xxs="12" className=" mb-3 p-0">
                  <p className="text-muted text-small mb-2">Discount</p>
                  <div className="mb-0 p-0">
                    <FormikSwitch
                      className="custom-switch custom-switch-primary-inverse"
                      name={'discount'}
                      value={discount}
                      onChange={() => changeDiscount()}
                      disabled={!discountFilter}
                    />
                  </div>
                </Colxx>
                <Colxx xxs="12" className=" mb-3 p-0">
                  <p className="text-muted text-small mb-2">
                    <IntlMessages id="pages.type" />
                  </p>
                  <div className="mb-0 p-0">
                    <SelectInput
                      // className="react-select"
                      // classNamePrefix="react-select"
                      value={selectedTypes}
                      onChange={(value) => setType(value)}
                      options={typeList}
                      isMulti={true}
                    ></SelectInput>
                  </div>
                </Colxx>
              </>
            ) : null}
          </Colxx>

          <Colxx xxs="12" className="mb-3 p-0">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.image" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.CategoryModal = instance)}
              images={this.state.imageFile}
              ratio={aspectRation === 'half' ? '2:1' : '1:1'}
              size={{ width: 400, height: aspectRation === 'half' ? 200 : 400 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeImageFile(data)
              }}
            />
            <span className="req-validation">
              {' '}
              {imageFile_validation
                ? imageFile_validation.msg
                  ? imageFile_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => this.handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default CategoryModal
