import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AreaList from './list';
import AreaDetail from './detail';
import CityDetail from './cityDetail';



const Area = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={AreaList} />
            <Route exact path={`${match.url}/detail/:id`} component={AreaDetail} />
            <Route exact path={`${match.url}/city/:id`} component={CityDetail} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Area;