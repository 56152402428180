import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class FeaturetteDetailStore {
  @observable
  featuretteDetail = null

  @observable
  imageFile = null

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  keys = [
    { value: 'category', label: 'Category', key: 'category' },
    { value: 'category2', label: 'All Categories', key: 'category' },
    { value: 'collection', label: 'Collection', key: 'collection' },
    { value: 'collection2', label: 'All Collections', key: 'collection' },
    { value: 'product', label: 'Product', key: 'product' },
    // { value: 'product2', label: 'All Products', key: 'product' },
    { value: 'shop', label: 'Shop', key: 'shop' },
    { value: 'shop2', label: 'All Shops', key: 'shop' },
    { value: 'url', label: 'Url', key: 'url' },
  ]

  @observable
  typeList = []

  @observable
  selectedType = null

  @observable
  itemList = []

  @observable
  selectedItem = null

  @observable
  url = ''

  @observable
  productsList = []

  @observable
  categoryList = []

  @observable
  shopList = []

  @observable
  collectionList = []

  @observable
  productList = []

  @observable
  url = null

  @observable
  isActive = true

  @observable
  productsList = []

  @observable
  en_name_validation = null

  @observable
  imageFile_validation = null

  @observable
  ar_imageFile_validation = null

  @observable
  item_validation = null

  @observable
  url_validation = null

  @observable
  type_validation = null

  @observable
  showUploaderEnglish = true

  // @observable
  // showUploaderArabic = true

  @action
  handleImageUploaderEnglish = () => {
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  handleImageUploaderArabic = () => {
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderEnglish(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  async changeImageFileUploderArabic(file) {
    this.arabicImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  async validation(id, history) {
    if (this.selectedType && this.selectedType.label === 'Url') {
      this.url_validation = await checkInput({
        name: 'Url',
        value: this.url,
        require: true,
        type: 'string',
        min: 3,
      })
    }

    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    if (this.selectedType && !this.selectedType.label.includes('All ')) {
      this.item_validation = await checkInput({
        name: 'Item',
        value: this.selectedItem,
        require: true,
      })
    } else {
      this.item_validation = { res: true }
    }
    if (this.selectedType && this.selectedType.label === 'Url') {
      this.url_validation.res &&
        this.type_validation.res &&
        this.handleUpdateFeaturette(id, history)
    } else {
      this.item_validation.res &&
        this.type_validation.res &&
        this.handleUpdateFeaturette(id, history)
    }
  }

  @action
  async getFeaturetteDetail(id, history) {
    this.isLoading = true
    const value = await axiFetch.request(
      'slides',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.featuretteDetail = value.data
      if (value.data.type) {
        this.keys.forEach((item) => {
          if (item.key === value.data.type) {
            if (value.data.typeDetail && !item.label.includes('All')) {
              this.selectedType = item
            } else if (!value.data.typeDetail && item.label.includes('All')) {
              this.selectedType = item
            }
          }
        })
      } else {
        this.selectedType = { value: 'url', label: 'Url' }
      }

      if (value.data.typeDetail) {
        this.selectedItem = {
          value: value.data.typeDetail.id,
          label: value.data.typeDetail.name_en,
        }
      }
      // this.isActive = value.data.items.is_active;
      this.selectedType && this.getDependencies(history)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const categories = await axiFetch.request(
      'categories',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (categories) {
      await categories.data.result.map((category) =>
        this.categoryList.push({
          value: category.id,
          label: category.name_en,
        })
      )
    }

    const shops = await axiFetch.request(
      'shops',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (shops) {
      await shops.data.items.map((shop) =>
        this.shopList.push({
          value: shop.id,
          label: shop.name_en,
        })
      )
    }

    const collections = await axiFetch.request(
      'collections',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (collections) {
      await collections.data.map((collection) =>
        this.collectionList.push({
          value: collection.id,
          label: collection.name_en,
        })
      )
    }

    const body = {
      sortBy: { createdAt: -1 },
      search: '',
      limit: 500,
      page: 1,
      filter: {},
    }

    const products = await axiFetch.request(
      'products',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (products) {
      await products.data.items.map((product) =>
        this.productsList.push({
          value: product.id,
          label: product.name_en,
        })
      )
    }

    switch (this.selectedType.label) {
      case 'Category':
        this.itemList = [...this.categoryList]
        break
      case 'Collection':
        this.itemList = [...this.collectionList]
        break
      case 'Shop':
        this.itemList = [...this.shopList]
        break
      case 'Product':
        this.itemList = [...this.productsList]
        break
      case 'Url':
        this.url = this.featuretteDetail.link
        this.url_validation = null
        break
      default:
        break
    }
  }

  @action
  setType(data) {
    this.selectedType = data.selectedOption
    this.selectedItem = null
    this.item_validation = null
    switch (this.selectedType.label) {
      case 'Category':
        this.itemList = [...this.categoryList]
        break
      case 'Collection':
        this.itemList = [...this.collectionList]
        break
      case 'Shop':
        this.itemList = [...this.shopList]
        break
      case 'Product':
        this.itemList = [...this.productsList]
        break
      case 'Url':
        this.url = ''
        this.url_validation = null
        break
      default:
        break
    }
  }

  @action
  setItem(data) {
    this.selectedItem = data.selectedOption
  }

  @action
  deleteFeaturette = async (id, history) => {
    this.isLoading = true
    let value = await axiFetch.request(
      `slides/${id}`,
      false,
      false,
      false,
      'delete',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/slide')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  async handleUpdateFeaturette(id, history) {
    this.isLoading = true
    let data = {
      data: JSON.stringify({
        url: this.selectedType.value === 'url' ? this.url : '',
        deeplink:
          this.selectedType.value === 'url'
            ? ''
            : {
                type: this.selectedType.key,
                id: this.selectedType.label.includes('All ')
                  ? ''
                  : this.selectedItem.value,
              },
      }),
    }
    if (this.imageFile) {
      data.file = this.imageFile
    }

    // if (this.arabicImageFile) {
    //   data.image_ar = this.arabicImageFile
    // }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      'slides',
      false,
      id,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getFeaturetteDetail(id, history)
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeArabicImageFile = (file) => {
    this.arabicImageFile = file
  }

  // @action.bound
  // changeEnName = name => {
  //   console.log(this.fillingDetail.name_en);
  //   console.log(name);
  //   this.fillingDetail.name_en = name;
  //   console.log(this.fillingDetail.name_en);
  // };

  @action
  changeName = (name) => {
    this.featuretteDetail.name = name
  }

  @action
  changeUrl = (value) => {
    this.url = value
  }

  @action.bound
  changeIsActive = (target, value) => {
    this.isActive = value
  }

  @action
  reset() {
    this.featuretteDetail = null

    this.imageFile = null

    this.isLoading = true

    this.openDeleteModal = false

    this.keys = [
      { value: 'category', label: 'Category', key: 'category' },
      { value: 'category2', label: 'All Categories', key: 'category' },
      { value: 'collection', label: 'Collection', key: 'collection' },
      { value: 'collection2', label: 'All Collections', key: 'collection' },
      { value: 'product', label: 'Product', key: 'product' },
      // { value: 'product2', label: 'All Products', key: 'product' },
      { value: 'shop', label: 'Shop', key: 'shop' },
      { value: 'shop2', label: 'All Shops', key: 'shop' },
      { value: 'url', label: 'Url', key: 'url' },
    ]

    this.typeList = []

    this.selectedType = null

    this.itemList = []

    this.selectedItem = null

    this.url = ''

    this.productsList = []

    this.categoryList = []

    this.shopList = []

    this.collectionList = []

    this.productList = []

    this.url = null

    this.isActive = true

    this.productsList = []

    this.en_name_validation = null

    this.imageFile_validation = null

    this.ar_imageFile_validation = null

    this.item_validation = null

    this.url_validation = null

    this.type_validation = null

    this.showUploaderEnglish = true
  }
}
