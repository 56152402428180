import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
} from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import CardThumbList from "../../../components/Card/cardThumblist";

@inject("stores")
@observer
class AdminsList extends Component {
  @observable
  store = this.props.stores.staticPage;

  @observable
  setting = this.props.stores.setting;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // this.store.getData(this.props.history);
    this.store.getData(this.props.history);
  }

  componentWillUnmount() {
    this.store.reset();
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.pages" />}
              match={this.props.match}
            />
            {/* <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => this.store.changeOpenModal()}
                >
                  <IntlMessages id="pages.add-new" />
                </Button>
                
                <Row className="col-12 justify-content-end p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex algin-items-center" >             
              <div className="d-block d-md-inline-block ">
              <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                <DropdownToggle caret color="outline-dark" size="xs">
                  <IntlMessages id="pages.orderby" />
                  {this.store.selectedOrderOption.label}
                </DropdownToggle>
                <DropdownMenu>
                  {this.store.orderOptions.map((order, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        onClick={() => this.store.changeOrderByList(order.column, this.props.history)}
                      >
                        {order.label}
                      </DropdownItem>
                    );
                  })}
                  
                </DropdownMenu>
              </UncontrolledDropdown>
              </div>
              </div>
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex  algin-items-center justify-content-end float-lg-right  float-sm-none p-0">
                <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      value={this.store.search}
                      placeholder={`Search`}
                      onChange={(e) =>
                        this.store.hendleChangeKey(e.target.value)
                      }
                      onKeyPress={(event) => this.store.handleKeyPress(event)}
                    />
                  </div>
                </div>
              </div>
            </Row> */}
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <Row>
                  {this.store.data
                    ? this.store.data.map((ingredient) => (
                        <CardThumbList
                          key={ingredient.id}
                          data={ingredient}
                          path="pages/detail"
                        />
                      ))
                    : null}
                </Row>
              </Colxx>
            </Row>
          </>
        )}
      </Fragment>
    );
  }
}

export default AdminsList;
