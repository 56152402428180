import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import moment from 'moment'
import { successNotify } from '../util/Notify'

export default class OrderStore {
  @observable
  data = null

  @observable
  filterOptions = [
    { column: 'all', label: 'All' },
    { column: 'ordered', label: 'Ordered', key: '0' },
    { column: 'in_progress', label: 'In Transit', key: '1' },
    { column: 'delivered', label: 'Completed', key: '2' },
    { column: 'cancelled', label: 'Cancelled', key: '3' },
  ]

  @observable
  selectedFilterOption = { column: 'all', label: 'All' }

  @observable
  selectedOrderOption = { column: 'id', label: 'Order Date' }

  @observable
  orderOptions = [
    { column: 'id', label: 'Id' },
    { column: 'id_asc', label: 'Id (Ascending)' },
    { column: 'createdAt', label: 'Order Date' },
    { column: 'createdAt_asc', label: 'Order Date (Ascending)' },
    { column: 'customerName', label: 'Customer' },
    { column: 'customerName_asc', label: 'Customer (Ascending)' },
    { column: 'lilasProfit', label: 'Lilas Profit' },
    {
      column: 'lilasProfit_asc',
      label: 'Lilas Profit (Ascending)',
    },
    { column: 'deliveryDate', label: 'Delivery Date' },
    { column: 'deliveryDate_asc', label: 'Delivery Date (Ascending)' },
    { column: 'price', label: 'Price' },
    { column: 'price_asc', label: 'Price (Ascending)' },
    { column: 'deliveryStatus', label: 'Delivery Status' },
    { column: 'deliveryStatus_asc', label: 'Delivery Status (Ascending)' },
  ]

  @observable
  orderDetail = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  startDate = null

  @observable
  endDate = null

  @action
  getData = async (history , role) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters: this.filter,
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }
    // if(role!=='admin'){
    //   body.filters.shopId=window.localStorage.getItem('lilas_shop_id')
    // }
    const value = await axiFetch.request(
      'orders',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination = value.data.explain.pagination.totalPages
      // Math.ceil(
      //   value.data.explain.pagination.totalPage / this.limit
      // )
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getUserOrders= async (id, history)=>{
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters: {userId:id},
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }
    const value = await axiFetch.request(
      'orders',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination = value.data.explain.pagination.totalPages
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getOrderDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'orders',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.orderDetail = value.data
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  changeStatus = async (value, id, history, parentId) => {
    this.isLoading = true
    const body = { deliveryStatus: value }
    const data = await axiFetch.request(
      'orders',
      false,
      id,
      false,
      'post',
      false,
      body,
      history
    )
    if (data) {
      successNotify('Item has been updated successfully')
      this.getOrderDetail(parentId, history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.searchOn = [
        'code',
        'extra_detail.customer.bakeryName',
        'extra_detail.customer.name',
      ]
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeDashboardFilter = async (key, value, from, to, history) => {
    if (from && to) {
      this.startDate = from
      this.endDate = to
      this.filter.fromDate = moment(from).format('X')
      this.filter.toDate = moment(to).format('X')
    }
    this.startDate = from
    this.endDate = to
    if (key) {
      this.selectedFilterOption = await this.filterOptions.find(
        (x) => x.key === value
      )
      this.filter.delivery_status = value
      // this.filter = { [key]: value }
    } else {
      delete this.filter.delivery_status
    }
    history.push('/app/order/list')
  }

  @action
  changeFilterBy = async (column, history) => {
    this.selectedFilterOption = await this.filterOptions.find(
      (x) => x.column === column
    )

    this.selectedFilterOption.column === 'all'
      ? delete this.filter.status
      : (this.filter.status = this.selectedFilterOption.column)

    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  setStartDate = (date, history) => {
    this.startDate = date
    if (date) {
      this.filter.fromDate = moment(this.startDate).format('X')
    } else {
      delete this.filter.fromDate
    }

    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  setEndDate = (date, history) => {
    this.endDate = date
    if (date) {
      this.filter.toDate = moment(this.endDate).format('X')
    } else {
      delete this.filter.toDate
    }

    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  changeOrderBy = async (column, history) => {
    if (this.selectedOrderOption.column === column) {
      let target = column.includes('_asc')
        ? column.split('_asc')[0]
        : `${column}_asc`
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    } else {
      let target = column
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
  this.data = null

  this.filterOptions = [
    { column: 'all', label: 'All' },
    { column: 'ordered', label: 'Ordered', key: '0' },
    { column: 'in_progress', label: 'In Transit', key: '1' },
    { column: 'delivered', label: 'Completed', key: '2' },
    { column: 'cancelled', label: 'Cancelled', key: '3' },
  ]

  this.selectedFilterOption = { column: 'all', label: 'All' }

  this.selectedOrderOption = { column: 'id', label: 'Order Date' }

  this.orderOptions = [
    { column: 'id', label: 'Id' },
    { column: 'id_asc', label: 'Id (Ascending)' },
    { column: 'createdAt', label: 'Order Date' },
    { column: 'createdAt_asc', label: 'Order Date (Ascending)' },
    { column: 'customerName', label: 'Customer' },
    { column: 'customerName_asc', label: 'Customer (Ascending)' },
    { column: 'lilasProfit', label: 'Lilas Profit' },
    {
      column: 'lilasProfit_asc',
      label: 'Lilas Profit (Ascending)',
    },
    { column: 'deliveryDate', label: 'Delivery Date' },
    { column: 'deliveryDate_asc', label: 'Delivery Date (Ascending)' },
    { column: 'price', label: 'Price' },
    { column: 'price_asc', label: 'Price (Ascending)' },
    { column: 'deliveryStatus', label: 'Delivery Status' },
    { column: 'deliveryStatus_asc', label: 'Delivery Status (Ascending)' },
  ]

  this.orderDetail = null

  this.pagination = 0

  this.currentPage = 1

  this.imageFile = null

  this.search = ''

  this.searchOn = null

  this.limit = 12

  this.offset = 1

  this.order = { createdAt: -1 }

  this.filter = {}

  this.isLoading = true

  this.openModal = false

  this.startDate = null

  this.endDate = null
  }
}
