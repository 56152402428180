import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  CustomInput,
  CardImg,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { Thumb } from '../../../components/Thumb'
import DeleteModal from '../../../components/Modal/deleteModal'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Uploader from '../../../components/Uploader'
import SelectInput from '../../../components/select/index'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import CollectionModal from '../../../components/Modal/collectionModal'

@inject('stores')
@observer
class CollectionDetail extends Component {
  @observable
  store = this.props.stores.collection

  @observable
  login = this.props.stores.login

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, getImage: false }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getCollectionDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFileSqr = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploderSqr(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.updateValidation(this.state.id, this.props.history)
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <ToastContainer />
            {this.store.openProductModal ? (
              <CollectionModal
                openModal={this.store.openProductModal}
                data={this.store.products}
                loading={this.store.isLoadingProduct}
                toggleModal={() => this.store.toggleProductModal()}
                pagination={this.store.paginationProduct}
                limit={this.store.limitProduct}
                currentPage={this.store.currentPageProduct}
                changePage={(e) => this.store.changePage(e)}
                target="product"
                addItems={(data) => this.store.addItemToSelected(data)}
              />
            ) : null}
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.collection" />}
                match={this.props.match}
              />
              {/* {this.login.userType==="admin" && */}
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>

              <DeleteModal
                target={
                  this.store.collectionDetail
                    ? this.store.collectionDetail.name_en
                    : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteCollection(this.state.id, this.props.history)
                }
              />
              {/* {this.login.userType==="admin" &&  */}
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => {
                  this.store.showUploaderSqr
                    ? this.store.updateValidation(
                        this.state.id,
                        this.props.history
                      )
                    : this.handleSave()
                }}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <Row>
                    <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              {`Square Image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          {this.store.showUploaderSqr ? (
                            <>
                              {' '}
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploaderSqr()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Image</strong>
                                </Label>
                              </Button>
                              <Thumb
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.imageFile}
                                src={
                                  this.store.collectionDetail
                                    ? this.store.collectionDetail.media
                                      ? this.store.collectionDetail.media.url.includes(
                                          'https'
                                        )
                                        ? this.store.collectionDetail.media.url
                                        : `https://media.lilas.dev.nizek.com/${this.store.collectionDetail.media.url}`
                                      : '/assets/img/noimage.png'
                                    : '/assets/img/noimage.png'
                                }
                              />
                            </>
                          ) : (
                            <Uploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              getImage={this.state.getImage}
                              flag={true}
                              ratio="1:1"
                              size={{ width: 400, height: 400 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploaderSqr()
                              }
                              changeImageFile={(data) => {
                                this.changeImageFileSqr(data)
                              }}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Colxx>

                    <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                      <Card className="mb-2" style={{ overflow: 'unset' }}>
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.name" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-3">
                                <Input
                                  name="name"
                                  value={
                                    this.store.collectionDetail
                                      ? this.store.collectionDetail.name_en
                                      : ''
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeEnName(e.target.value)
                                  }
                                />
                                <span className="req-validation ">
                                  {this.store.en_name_validation
                                    ? this.store.en_name_validation.msg
                                      ? this.store.en_name_validation.msg
                                      : null
                                    : null}
                                </span>
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.name-ar" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-3">
                                <Input
                                  name="name_ar"
                                  value={
                                    this.store.collectionDetail
                                      ? this.store.collectionDetail.name_ar
                                      : ''
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeArName(e.target.value)
                                  }
                                  className="ar"
                                />
                                <span className="req-validation ">
                                  {this.store.ar_name_validation
                                    ? this.store.ar_name_validation.msg
                                      ? this.store.ar_name_validation.msg
                                      : null
                                    : null}
                                </span>
                              </p>
                            </Colxx>

                            <Colxx xxs="12" className=" mb-3">
                              <p className="text-muted text-small mb-2">
                                Is Dynamic
                              </p>
                              <div className="mb-0">
                                <FormikSwitch
                                  className="custom-switch custom-switch-primary-inverse"
                                  name={'is_dynamic'}
                                  value={this.store.isDynamic ? true : false}
                                  onChange={() => this.store.changeIsDynamic()}
                                />
                              </div>
                            </Colxx>

                            {this.store.isDynamic ? (
                              <>
                                <Colxx xxs="12" className=" mb-3">
                                  <p className="text-muted text-small mb-2">
                                    Price (From)
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="from"
                                      value={
                                        this.store.fromPrice
                                          ? this.store.fromPrice
                                          : 0
                                      }
                                      type="number"
                                      onChange={(e) =>
                                        this.store.changeFromPrice(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Colxx>

                                <Colxx xxs="12" className="mb-3">
                                  <p className="text-muted text-small mb-2">
                                    Price (To)
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="to"
                                      value={
                                        this.store.toPrice
                                          ? this.store.toPrice
                                          : 0
                                      }
                                      type="number"
                                      onChange={(e) =>
                                        this.store.changeToPrice(e.target.value)
                                      }
                                    />
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" className="mb-3">
                                  <div>
                                    <CustomInput
                                      className="less-pad-l"
                                      key={`remember-me`}
                                      type="checkbox"
                                      id={`remember-me`}
                                      label={
                                        <Label
                                          className="perm-lbl pointer unselectable italic-on-hover text-small mt-1"
                                          onClick={() => {
                                            this.store.handleDiscountFilter()
                                          }}
                                        >
                                          Discount Filter
                                        </Label>
                                      }
                                      checked={this.store.discountFilter}
                                      onChange={() => {
                                        this.store.handleDiscountFilter()
                                      }}
                                    />
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" className="mb-3">
                                  <p className="text-muted text-small mb-2">
                                    Discount
                                  </p>
                                  <div className="mb-0">
                                    <FormikSwitch
                                      className="custom-switch custom-switch-primary-inverse"
                                      name={'discount'}
                                      value={this.store.discount}
                                      onChange={() =>
                                        this.store.changeDiscount()
                                      }
                                      disabled={!this.store.discountFilter}
                                    />
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" className=" mb-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.type" />
                                  </p>
                                  <div className="mb-0">
                                    <SelectInput
                                      // className="react-select"
                                      // classNamePrefix="react-select"
                                      value={this.store.selectedTypes}
                                      onChange={(value) =>
                                        this.store.setType(value)
                                      }
                                      options={this.store.typesList}
                                      isMulti={true}
                                    ></SelectInput>
                                  </div>
                                </Colxx>
                              </>
                            ) : null}
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                    {this.store.isDynamic ? null : (
                      <Colxx
                        xxs="12"
                        md="12"
                        lg="4"
                        className="mb-4"
                        style={{ height: 'max-content' }}
                      >
                        <Card className="mb-2">
                          <CardBody className=" pb-0">
                            <CardTitle className="mb-3 col-12">
                              <span className="font-weight-bold text-uppercase">
                                {this.store.collectionDetail
                                  ? `Collection Product`
                                  : null}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx
                              xxs="12"
                              className="p-0 m-0 mb-3 d-flex flex-wrap"
                            >
                              {this.store.productList
                                ? this.store.productList.map((item) => (
                                    <div
                                      key={item.id}
                                      // href={`/app/product/detail/${item.id}`}
                                      className="p-1 col-6"
                                    >
                                      <Card className="col-12 m-0 p-0">
                                        <div
                                          className="position-relative col-12"
                                          style={{ paddingTop: '100%' }}
                                        >
                                          <Button
                                            onClick={() =>
                                              this.store.removeFromList(item.id)
                                            }
                                            color="primary"
                                            style={{
                                              position: 'absolute',
                                              width: '30px',
                                              height: '30px',
                                              borderRadius: '50%',
                                              padding: '0',
                                              right: '5px',
                                              top: '5px',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              zIndex: 1,
                                            }}
                                          >
                                            <i
                                              style={{ fontSize: '20px' }}
                                              className="iconsminds-close"
                                            />
                                          </Button>

                                          <p
                                            className="pointer m-0"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/app/product/detail/${item.id}`
                                              )
                                            }}
                                            style={{
                                              position: 'absolute',
                                              width: '90%',
                                              minHeight: '30px',
                                              height:'max-content',
                                              borderRadius: '0.75rem',
                                              padding: '8px',
                                              right: '5%',
                                              top: '33%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              zIndex: 1,
                                              color: 'white',
                                              backgroundColor:
                                                'rgba(0, 0, 0, 0.75',
                                            }}
                                          >
                                            {item.name_en}
                                          </p>
                                          <CardImg
                                            src={
                                              item.medias
                                                ? item.medias[0].url
                                                : item.media.url
                                            }
                                          ></CardImg>
                                        </div>
                                      </Card>
                                    </div>
                                  ))
                                : null}
                            </Colxx>
                            <Button
                              color="primary"
                              size="lg"
                              className="pointer mb-4 col-12"
                              style={{}}
                              onClick={() => {
                                this.store.getProduct(this.props.history)
                              }}
                            >
                              <Label
                                className="btn btn-default no-padding"
                                style={{
                                  padding: '0px',
                                  marginBottom: '0px',
                                }}
                              >
                                <strong>Add New Product</strong>{' '}
                              </Label>
                            </Button>
                            <Colxx xxs="12" lg="12" className="mb-3"></Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                    )}
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default CollectionDetail
