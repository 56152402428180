import servers from './config'
import url from './url'

let config = servers['testServer']

export default new (class {
  API = (resource, extra, id, query, method, type) => {
    let uri = `${config.application.protocol}://${config.application.host}/${
      config.application.app
    }/${resource}${extra ? `/${extra}` : ""}${id ? `/${id}` : ""}${
      query ? `/${query}` : ""
    }${type ? `/${url[resource][method][type]}` : ""}`;
    return uri;
  };

  
})()
