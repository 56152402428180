import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Card, CardBody, Input, CardTitle, Button } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import Pagination from '../../../components/List/Pagination'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import { ToastContainer } from 'react-toastify'

@inject('stores')
@observer
class SettingsList extends Component {
  @observable
  store = this.props.stores.settingApp

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
    // this.setting.getPermissions(this.props.history);
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.settings" />}
              match={this.props.match}
            />
            <Button
              color="primary"
              className="btn-shadow float-right"
              size="lg"
              onClick={() =>
                this.store.handleUpdateSettings(this.props.history)
              }
            >
              <IntlMessages id="pages.save" />
            </Button>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row className="d-flex flex-wrap col-12">
              <Colxx
                xxs="12"
                sm="12"
                md="12"
                lg="12"
                className="mb-8 d-flex flex-wrap"
              >
                {this.store.data.map((item) => (
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12 col-12 h-100">
                    <Card className=" col-12 h-100 ">
                      <CardBody className="p-1 pt-4">
                        <CardTitle className="mb-3">
                          <span className="font-weight-bold text-uppercase">
                              {item.desc && ` ${item.desc}`}
                          </span>
                        </CardTitle>
                        <Separator className="mb-5" />
                        <Colxx xxs="12" lg="12" key={item.id} className="mb-1">
                          <div className="mb-0 d-flex flex-wrap">
                            {item.type === 'Boolean' ? (
                              <div
                                className="d-flex align-items-center col-12 p-0"
                                key={item.id}
                                style={{ height: 42.58 }}
                              >
                                <FormikSwitch
                                  className="custom-switch custom-switch-primary-inverse"
                                  name={item.key}
                                  value={Number(item.value) ? true : false}
                                  disabled={!item.edit}
                                  onChange={(e) => {
                                    this.store.changeItem(
                                      Number(item.value) ? 0 : 1,
                                      item.id
                                    )
                                  }}
                                />
                              </div>
                            ) : (
                              <Input
                                className="col-sm-12 col-md-12 "
                                style={{
                                  borderColor: item.edit ? '#FF5800' : '#eee',
                                }}
                                name="name"
                                value={item.value}
                                min={item.type === 'Number' ? '0' : true}
                                type={
                                  item.type === 'Number' ? 'number' : 'text'
                                }
                                // disabled={!item.edit}
                                onChange={(e) =>
                                  this.store.changeItem(e.target.value, item.id)
                                }
                              />
                            )}

                            
                          </div>
                          <span className="req-validation ">
                            {this.store.en_name_validation
                              ? this.store.en_name_validation.msg
                                ? this.store.en_name_validation.msg
                                : null
                              : null}
                          </span>
                          <div className="mt-3">
                            {/* <span className="text-small text-muted">
                              {item.desc && `* ${item.desc}`}
                            </span> */}
                          </div>
                        </Colxx>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default SettingsList
