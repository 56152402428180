import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { RecThumb } from '../../../components/Thumb/recThumb'
import DeleteModal from '../../../components/Modal/deleteModal'
import SelectInput from '../../../components/select'
// import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Uploader from '../../../components/Uploader'

@inject('stores')
@observer
class FeaturetteDetail extends Component {
  @observable
  store = this.props.stores.featuretteDetail
  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, getImage: false }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getFeaturetteDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFileEnglish = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploderEnglish(data)
    } else {
      this.setState({ getImage: false })
    }
    // this.store.showUploaderArabic &&
      this.store.validation(this.state.id, this.props.history)
  }

  // changeImageFileArabic = async (data) => {
  //   if (data) {
  //     this.setState({ getImage: false })
  //     await this.store.changeImageFileUploderArabic(data)
  //   } else {
  //     this.setState({ getImage: false })
  //   }
  //   this.store.validation(this.state.id, this.props.history)
  // }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.slide" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={"Selected Item"}
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteFeaturette(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => {
                  this.store.showUploaderEnglish
                    ? this.store.validation(this.state.id, this.props.history)
                    : this.handleSave()
                }}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <Row>
                    <Colxx xxs="12" sm="12" lg="4" className="mb-4">
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              {`English Image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-3" />
                          {this.store.showUploaderEnglish ? (
                            <>
                              {' '}
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploaderEnglish()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Image</strong>
                                </Label>
                              </Button>
                              <RecThumb
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.imageFile}
                                src={
                                  this.store.featuretteDetail
                                    ? this.store.featuretteDetail.media
                                      ? this.store.featuretteDetail.media
                                      : '/assets/img/noimage.png'
                                    : '/assets/img/noimage.png'
                                }
                              />
                            </>
                          ) : (
                            <Uploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              getImage={this.state.getImage}
                              flag={true}
                              ratio="2:1"
                              size={{ width: 400, height: 200 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploaderEnglish()
                              }
                              changeImageFile={(data) => {
                                this.changeImageFileEnglish(data)
                              }}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Colxx>
                    {/* <Colxx xxs="12" sm="12" lg="4" className="mb-4">
                      <Card className="mb-2 ">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              {`Arabic Image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-3" />
                          {this.store.showUploaderArabic ? (
                            <>
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploaderArabic()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Arabic Image</strong>
                                </Label>
                              </Button>
                              <RecThumb
                            key="cat-image"
                            className="almost-full-width"
                            file={this.store.arabicImageFile}
                            src={
                              this.store.featuretteDetail
                                ? this.store.featuretteDetail.image_ar.url
                                : '/assets/img/noimage.png'
                            }
                          />
                            </>
                          ) : (
                            <Uploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              getImage={this.state.getImage}
                              flag={true}
                              ratio="2:1"
                              size={{ width: 400, height: 200 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploaderArabic()
                              }
                              changeImageFile={(data) => {
                                this.changeImageFileArabic(data)
                              }}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Colxx> */}
                    <Colxx xxs="12" sm="12" lg="4" className="mb-8">
                      <Card className="mb-2" style={{ overflow: 'unset' }}>
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                           

                            <Colxx xxs="12" className=" mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.type" />
                                <span className="req"> *</span>
                              </p>
                              <div className="mb-0">
                                <SelectInput
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  value={
                                    this.store.selectedType
                                  }
                                  onChange={(value) =>
                                    this.store.setType(value)
                                  }
                                  options={this.store.keys}
                                  isMulti={false}
                                ></SelectInput>
                              </div>
                              <span className="req-validation">
                                {' '}
                                {this.store.type_validation
                                  ? this.store.type_validation.msg
                                    ? this.store.type_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                            {this.store.selectedType && (this.store.selectedType.label === 'Category' ||
                            this.store.selectedType.label === 'Shop' ||
                            this.store.selectedType.label === 'Collection' ||
                            this.store.selectedType.label === 'Product') ? (
                              <Colxx xxs="12" className=" mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.item" />
                                  <span className="req"> *</span>
                                </p>
                                <div className="mb-0">
                                  <SelectInput
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={
                                      this.store.selectedItem
                                    }
                                    onChange={(value) =>
                                      this.store.setItem(value)
                                    }
                                    options={this.store.itemList}
                                    isMulti={false}
                                  ></SelectInput>
                                </div>
                                <span className="req-validation">
                                  {this.store.item_validation
                                    ? this.store.item_validation.msg
                                      ? this.store.item_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                            ) : null}
                            {this.store.selectedType && this.store.selectedType.label === 'Url' ? (
                              <Colxx xxs="12" lg="12" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.url" />
                                  <span className="req"> *</span>
                                </p>
                                <div className="mb-0">
                                  <Input
                                    name="url"
                                    value={this.store.url ? this.store.url : ''}
                                    type="text"
                                    onChange={(e) =>
                                      this.store.changeUrl(e.target.value)
                                    }
                                  />
                                </div>
                                <span className="req-validation">
                                  {this.store.url_validation
                                    ? this.store.url_validation.msg
                                      ? this.store.url_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                            ) : null}

                            {/* <Colxx xxs="12" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.isActive" />
                              </p>
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse mr-2"
                                name={'is_active'}
                                value={this.store.isActive}
                                onChange={(e) =>
                                  this.store.changeIsActive(
                                    e.target,
                                    !this.store.isActive
                                  )
                                }
                              />
                            </Colxx> */}
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default FeaturetteDetail
