import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  // Badge,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  // CardText,
  // CardImg,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import classnames from 'classnames'
import OrderList from './order'

@inject('stores')
@observer
class CustomerDetail extends Component {
  @observable
  store = this.props.stores.customerDetail

  @observable
  setting = this.props.stores.setting

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, activeFirstTab: '1' }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getCustomerDetail(path)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  toggleTab = (id) => {
    this.setState({ activeFirstTab: id })
    this.store.getCustomerDetail(this.state.id, this.props.history)
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.customers" />}
              match={this.props.match}
            />
            <Colxx xs="12" className="p-0">
              <Nav tabs className="separator-tabs m-0 mb-5 ">
                <NavItem>
                  <NavLink
                    className={classnames('pointer', {
                      active: this.state.activeFirstTab === '1',
                      // "nav-link": true
                    })}
                    onClick={() => {
                      this.toggleTab('1')
                    }}
                    to="#"
                  >
                    <IntlMessages id="pages.details" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames('pointer', {
                      active: this.state.activeFirstTab === '2',

                      // "nav-link": true
                    })}
                    onClick={() => this.toggleTab('2')}
                    to="#"
                  >
                    {/* {this.store.customerDetail
                      ? ` (${this.store.customerDetail.orders.length}) `
                      : '(0)'} */}
                    <IntlMessages id="pages.orders" />
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames('pointer', {
                      active: this.state.activeFirstTab === '3',

                      // "nav-link": true
                    })}
                    onClick={() => this.toggleTab('3')}
                    to="#"
                  >
                    <IntlMessages id="pages.addresses" />
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Colxx>
            {/* <Separator className="mb-5" /> */}
          </Colxx>
          {this.store.isLoading ? (
            <div className="loading"></div>
          ) : this.state.activeFirstTab === '1' ? (
            <Colxx xxs="12">
              <Colxx xxs="12">
                <Row className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: '25px' }}
                  >
                    <Row>
                      <Colxx xxs="12" sm="8" lg="6" className="mb-4">
                        <Card className="mb-2">
                          <CardBody>
                            <CardTitle className="mb-3">
                              <span className="font-weight-bold text-uppercase">
                                {`Detail`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />

                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.userName" />
                              </p>
                              <p className="mb-3">
                                {this.store.customerDetail
                                  ? this.store.customerDetail.name
                                  : '---'}
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.email" />
                              </p>
                              <p className="mb-3">
                                {this.store.customerDetail
                                  ? this.store.customerDetail.email
                                  : '---'}
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.phoneNumber" />
                              </p>
                              <p className="mb-3">
                                {this.store.customerDetail
                                  ? this.store.customerDetail.phonenumber
                                    ? this.store.customerDetail.phonenumber
                                    : '---'
                                  : '---'}
                              </p>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Row>
              </Colxx>
            </Colxx>
          ) : this.state.activeFirstTab === '2' ? (
            <OrderList match={this.props.match}/>
          ) : (
            <>
              <Row className="col-12">
                <Colxx xxs="12" className="pl-0 d-xl-block d-md-none">
                  <div
                    className="col-12 p-2 d-flex align-items-center mb-3"
                    style={{ borderBottom: '1px solid gray' }}
                  >
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Title
                    </p>

                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Block
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Street
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Avenue
                    </p>

                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Building
                    </p>

                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Floor
                    </p>
                  </div>
                </Colxx>
              </Row>
            </>
          )}
        </Row>
      </Fragment>
    )
  }
}

export default CustomerDetail
