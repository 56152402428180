import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Card,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardBody,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import DisplayOption from '../../../components/DisolayOption'
import CardImageList from '../../../components/Card/cardImageList'
import ModalContainer from '../../../components/Modal'
import SelectInput from '../../../components/select'
import Pagination from '../../../components/List/Pagination'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

@inject('stores')
@observer
class ProductList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      role:null
    }
  }
  @observable
  store = this.props.stores.product

  @observable
  login = this.props.stores.login

  @observable
  setting = this.props.stores.setting

  async componentDidMount() {
    const role = window.localStorage.getItem('lilas_role')
    if (role) {
      await this.store.getDependencies(this.props.history, role)
      this.store.getData(this.props.history, role)
      this.setState({role})
    } else {
      this.props.history.push('/login')
    }
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.product" />}
              match={this.props.match}
            />
            <Button
              color="primary"
              className="btn-shadow float-right"
              size="lg"
              onClick={() => this.props.history.push('/app/product/addProduct')}
            >
              <IntlMessages id="pages.add-new" />
            </Button>
            <ModalContainer
              openModal={this.store.openModal}
              toggleModal={() => this.store.toggleModal()}
            />
            <Row className="col-12 justify-content-start p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12">
                <DisplayOption />
                <div className="d-block d-md-inline-block mb-2">
                  <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.orderby" />
                      {this.store.selectedOrderOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.orderOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeOrderByList(
                                order.column,
                                this.props.history,
                                this.state.role
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row className="mb-4">
          <Colxx>
            <Card className="col-12" style={{ overflow: 'unset' }}>
              <CardBody className="p-3 d-flex flex-wrap">
                <Colxx xl="4">
                  <Label>Category</Label>{' '}
                  <SelectInput
                    className="react-select"
                    classNamePrefix="react-select"
                    value={this.store.category}
                    onChange={(value) =>
                      this.store.setCategory(
                        value,
                        this.props.history,
                        this.state.role
                      )
                    }
                    options={this.store.categoryList}
                    isMulti={false}
                  />
                </Colxx>
                <Colxx xl="4">
                  <Label>Shop</Label>{' '}
                  <SelectInput
                    // disabled={this.login.userType === 'admin' ? false : true}
                    className="react-select"
                    classNamePrefix="react-select"
                    value={this.store.shop}
                    onChange={(value) =>
                      this.store.setBakery(
                        value,
                        this.props.history,
                        this.state.role
                      )
                    }
                    options={
                      this.store.shopList
                      // !this.store.loadingDependencies &&
                      // this.store.bakeryList.length
                      //   ? this.store.bakeryList
                      //   : []
                    }
                    isMulti={false}
                  />
                </Colxx>
                <Colxx xl="4">
                  <Label>Search</Label> <br />{' '}
                  <div className="search-sm d-inline-block  align-top w-100">
                    <input
                      className="w-100 "
                      type="text"
                      name="keyword"
                      placeholder={'Search'}
                      value={this.store.search}
                      onChange={(e) =>
                        this.store.handleChangeSearch(e.target.value)
                      }
                      onKeyPress={(event) =>
                        this.store.handleKeyPress(
                          event,
                          this.props.history,
                          this.state.role
                        )
                      }
                    />
                  </div>
                </Colxx>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                {this.setting.displayMode === 'thumblist' ? (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((cake) => (
                          <CardThumbList
                            key={cake.id}
                            data={cake}
                            path="product/detail"
                          />
                        ))
                      : null}
                  </Row>
                ) : (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((cake) => (
                          <CardImageList
                            key={cake.id}
                            data={cake}
                            path="product/detail"
                          />
                        ))
                      : null}
                  </Row>
                )}
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) =>
                  this.store.changePage(e, this.props.history, this.state.role)
                }
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default ProductList
