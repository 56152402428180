import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class AddressTupeDetailStore {
  @observable
  typeDetail = null

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  fieldsList = []

  @observable
  selectedField = []

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  field_validation = null

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.typeDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.typeDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.field_validation = await checkInput({
      name: 'Field',
      value: this.selectedField,
      require: true,
    })
    
    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.field_validation.res &&
      this.handleUpdateField(id, history)
  }

  @action
  getDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'addresses/types',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.typeDetail = value.data
      await this.getDependencies(history)
      value.data.addressFields.forEach((item) => {
        this.selectedField.push({value:item.id, label:item.title})
      })

      // this.stylesList.forEach((item) => {
      //   if (item.value === value.data.style) {
      //     this.selectedStyle = item
      //   }
      // })
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const fields = await axiFetch.request(
      'addresses/fields',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (fields) {
      await fields.data.items.map((field) =>
        this.fieldsList.push({
          value: field.id,
          label: field.title,
        })
      )
    }
  }

  @action
  deleteType = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'addresses/types',
      false,
      `${id}/delete`,
      false,
      'post',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/addressType')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  handleUpdateField = async (id, history) => {
    const ids = []
    await this.selectedField.forEach((item) => {
      ids.push(item.value)
    })
    this.isLoading = true
    let body = {
      name_en: this.typeDetail.name_en,
      name_ar: this.typeDetail.name_ar,
      field_ids:ids
    }

    const value = await axiFetch.request(
      'addresses/types',
      false,
      id,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getDetail(id)
    } else {
      this.isLoading = false
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action.bound
  changeEnName = (name) => {
    this.typeDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.typeDetail.name_ar = name
  }

  @action
  setType(data) {
    this.selectedField = data.selectedOption
  }

  @action
  setStyle(data) {
    this.selectedStyle = data.selectedOption
  }

  @action
  reset() {
    this.typeDetail = null
  
    this.isLoading = true
  
    this.openDeleteModal = false
  
    this.fieldsList = []
  
    this.selectedField = []
  
    this.en_name_validation = null
  
    this.ar_name_validation = null
  
    this.field_validation = null
  }
}
