import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class ProfileStore {
  @observable
  profileDetail = null

  @observable
  isLoading = false

  @observable
  recent_password = ''

  @observable
  password = ''

  @observable
  confirm_pass = ''

  @observable
  name_validation = null

  @observable
  password_validation = null

  @observable
  confirm_pass_validation = null

  @observable
  openPassModal = false

  @observable
  saveBtnDisable = false

  @observable
  recent_password_validation = null

  @action
  changePassModal = () => {
    this.openPassModal = true
  }

  @action
  togglePassModal = () => {
    this.openPassModal = false

    this.recent_password = ''

    this.password = ''

    this.confirm_pass = ''

    this.password_validation = null

    this.confirm_pass_validation = null

    this.recent_password_validation = null
  }

  @action
  async changePassValidation(id, history) {
    this.password_validation = await checkInput({
      name: 'Old password',
      value: this.password,
      require: true,
      type: 'string',
    })
    this.recent_password_validation = await checkInput({
      name: 'New password',
      value: this.recent_password,
      require: true,
      min: 6,
    })
    this.confirm_pass_validation = await checkInput({
      name: 'Confirm password',
      value: this.confirm_pass,
      require: true,
      min: 6,
    })

    if (
      this.recent_password &&
      this.confirm_pass &&
      this.recent_password !== this.confirm_pass
    ) {
      this.confirm_pass_validation = {
        res: false,
        msg: 'New password and confirm password must be matched!',
      }
      return
    }

    this.recent_password_validation.res &&
      this.password_validation.res &&
      this.confirm_pass_validation.res &&
      this.updatePassword(id, history)
  }

  @action
  async validation(id, history) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.profileDetail.name,
      require: true,
      type: 'string',
    })

    this.name_validation.res && this.updateProfile(id, history)
  }

  @action
  getProfileDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'admins',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.profileDetail = value.data
      this.isLoading = false
    }
  }

  @action
  updateProfile = async (id, history) => {
    this.isLoading = true
    this.saveBtnDisable = true
    const body = {
      name: this.profileDetail.name,
      // phone: this.profileDetail.phone,
    }

    const value = await axiFetch.request(
      'admins',
      false,
      id,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.isLoading = false
      this.saveBtnDisable = false
      this.getProfileDetail(id, history)
    } else {
      this.isLoading = false
      this.saveBtnDisable = false
    }
  }

  @action
  updatePassword = async (id, history) => {
    this.isLoading = true
    const body = {
      currentPassword: this.password,
      newPassword: this.recent_password,
      confirmPassword: this.confirm_pass,
    }

    const value = await axiFetch.request(
      `admins/${id}/change-password`,
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.isLoading = false
      successNotify('Password has been updated successfully')
      this.togglePassModal()
      this.getProfileDetail(id, history)
      setTimeout(() => {
        history.push(`/login`)
      }, 1000)
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action.bound
  changeName = (name) => {
    this.profileDetail.name = name
  }

  @action.bound
  changePhone = (value) => {
    this.profileDetail.phone = value
  }

  changePassword = (pass) => {
    this.password = pass
  }

  changeRecentPassword = (pass) => {
    this.recent_password = pass
  }

  changeConfirmPassword = (pass) => {
    this.confirm_pass = pass
  }

  @action
  reset() {
    this.profileDetail = null
  
    this.isLoading = false
  
    this.recent_password = ''
  
    this.password = ''
  
    this.confirm_pass = ''
  
    this.name_validation = null
  
    this.password_validation = null
  
    this.confirm_pass_validation = null
  
    this.openPassModal = false
  
    this.saveBtnDisable = false
  
    this.recent_password_validation = null
  }
}
