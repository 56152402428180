import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { Thumb } from '../../../components/Thumb'
import DeleteModal from '../../../components/Modal/deleteModal'
import AreaList from './area'
import DayList from './day'
import classnames from 'classnames'
import CustomCake from './custom'
import Uploader from '../../../components/Uploader'

@inject('stores')
@observer
class ShopDetail extends Component {
  @observable
  store = this.props.stores.shopDetail

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, getImage: false, role: null }
  }
  componentDidMount() {
    const role = window.localStorage.getItem('lilas_role')
    if (role) {
      let path = this.props.match.params.id
      this.setState({ id: path, role: role, activeFirstTab: '1' })
      this.store.getShopDetail(path, this.props.history, role)
    } else {
      this.props.history.push('/login')
    }
  }

  componentWillUnmount() {
    this.store.reset()
  }

  toggleTab = (id) => {
    this.setState({ activeFirstTab: id })
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFileMain = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploderMain(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.showUploaderCover &&
      !this.store.coverImageFile &&
      this.store.validation(this.state.id, this.props.history)
  }

  changeImageFileCover = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploderCover(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.validation(this.state.id, this.props.history)
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.shop" />}
                match={this.props.match}
              />
              {this.state.role === 'admin' && (
                <Button
                  color="btn-outline-secondary"
                  className="btn-shadow float-right ml-2"
                  size="lg"
                  onClick={() => this.store.changeOpenDeleteModal()}
                >
                  <IntlMessages id="pages.delete" />
                </Button>
              )}
              <DeleteModal
                target={
                  this.store.shopDetail ? this.store.shopDetail.name_en : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteShop(this.state.id, this.props.history)
                }
              />
              {this.state.activeFirstTab === '3' ? (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.store.areaValidation(this.state.id, this.props.history)
                  }
                >
                  <IntlMessages id="pages.saveArea" />
                </Button>
              ) : this.state.activeFirstTab === '2' ? (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.store.setShopSchedule(
                      this.state.id,
                      this.props.history
                    )
                  }
                >
                  Save Timing
                </Button>
              ) : this.state.activeFirstTab === '1' ? (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => {
                    this.store.showUploaderMain && this.store.showUploaderCover
                      ? this.store.validation(this.state.id, this.props.history)
                      : this.handleSave()
                  }}
                >
                  <IntlMessages id="pages.save" />
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.customCake.handleSave(
                      this.state.id,
                      this.props.history
                    )
                  }
                >
                  <IntlMessages id="pages.saveCustomCake" />
                </Button>
              )}
              <Colxx xs="12" className="p-0">
                <Nav tabs className="separator-tabs m-0 mb-5 ">
                  <NavItem className="pointer">
                    <NavLink
                      className={classnames('pointer', {
                        active: this.state.activeFirstTab === '1',
                      })}
                      onClick={() => {
                        this.toggleTab('1')
                      }}
                      to="#"
                    >
                      <IntlMessages id="pages.details" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pointer">
                    <NavLink
                      className={classnames('pointer', {
                        active: this.state.activeFirstTab === '2',
                      })}
                      onClick={() => this.toggleTab('2')}
                      to="#"
                    >
                      <IntlMessages id="pages.timing" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pointer">
                    <NavLink
                      className={classnames('pointer', {
                        active: this.state.activeFirstTab === '3',
                      })}
                      onClick={() => this.toggleTab('3')}
                      to="#"
                    >
                      <IntlMessages id="pages.areas" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Colxx>
            </Colxx>
            {this.state.activeFirstTab === '1' ? (
              this.store.shopDetail ? (
                <>
                  <Colxx xxs="12">
                    <Row className="d-flex ">
                      <Colxx
                        xxs="12"
                        lg="12"
                        className="mb-6 border-bottom"
                        style={{ marginBottom: '25px' }}
                      >
                        <Row>
                          <Colxx xxs="12" sm="6" lg="3" className="mb-4">
                            <Card className="mb-2">
                              <CardBody>
                                <CardTitle className="mb-3">
                                  <span className="font-weight-bold text-uppercase">
                                    {`Logo`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                {this.store.showUploaderMain ? (
                                  <>
                                    {' '}
                                    <Button
                                      color="primary"
                                      size="lg"
                                      className="pointer mb-4 col-12"
                                      style={{}}
                                      onClick={() => {
                                        this.store.handleImageUploaderMain()
                                      }}
                                    >
                                      <Label
                                        className="btn btn-default no-padding col-12"
                                        style={{
                                          padding: '0px',
                                          marginBottom: '0px',
                                        }}
                                      >
                                        <strong>Change Logo</strong>
                                      </Label>
                                    </Button>
                                    <Thumb
                                      key="cat-image"
                                      className="almost-full-width"
                                      file={this.store.imageFile}
                                      src={
                                        this.store.shopDetail.medias
                                          ? this.store.shopDetail.medias[0] &&
                                            this.store.shopDetail.medias[0]
                                              .mediaableType === 'shopLogo'
                                            ? this.store.shopDetail.medias[0]
                                                .url
                                            : this.store.shopDetail.medias[1] &&
                                              this.store.shopDetail.medias[1]
                                                .mediaableType === 'shopLogo'
                                            ? this.store.shopDetail.medias[1]
                                                .url
                                            : '/assets/img/noimage.png'
                                          : '/assets/img/noimage.png'
                                      }
                                    />
                                  </>
                                ) : (
                                  <Uploader
                                    multiple={true}
                                    ref={(instance) =>
                                      (this.productDetail = instance)
                                    }
                                    images={this.state.imageFile}
                                    getImage={this.state.getImage}
                                    flag={true}
                                    ratio="1:1"
                                    size={{ width: 400, height: 400 }}
                                    handleImageUploader={() =>
                                      this.store.handleImageUploaderMain()
                                    }
                                    changeImageFile={(data) => {
                                      this.changeImageFileMain(data)
                                    }}
                                  />
                                )}

                                <span className="req-validation ">
                                  {this.store.imageFile_validation
                                    ? this.store.imageFile_validation.msg
                                      ? this.store.imageFile_validation.msg
                                      : null
                                    : null}
                                </span>
                              </CardBody>
                            </Card>
                          </Colxx>
                          <Colxx xxs="12" sm="6" lg="3" className="mb-4">
                            <Card className="mb-2">
                              <CardBody>
                                <CardTitle className="mb-3">
                                  <span className="font-weight-bold text-uppercase">
                                    {`Banner Image`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                {this.store.showUploaderCover ? (
                                  <>
                                    {' '}
                                    <Button
                                      color="primary"
                                      size="lg"
                                      className="pointer mb-4 col-12"
                                      style={{}}
                                      onClick={() => {
                                        this.store.handleImageUploaderCover()
                                      }}
                                    >
                                      <Label
                                        className="btn btn-default no-padding col-12"
                                        style={{
                                          padding: '0px',
                                          marginBottom: '0px',
                                        }}
                                      >
                                        <strong>Change Banner Image</strong>
                                      </Label>
                                    </Button>
                                    <Thumb
                                      key="cat-image"
                                      className="almost-full-width"
                                      file={this.store.coverImageFile}
                                      src={
                                        this.store.shopDetail.medias
                                          ? this.store.shopDetail.medias[0] &&
                                            this.store.shopDetail.medias[0]
                                              .mediaableType === 'shopBanner'
                                            ? this.store.shopDetail.medias[0]
                                                .url
                                            : this.store.shopDetail.medias[1] &&
                                              this.store.shopDetail.medias[1]
                                                .mediaableType === 'shopBanner'
                                            ? this.store.shopDetail.medias[1]
                                                .url
                                            : '/assets/img/noimage.png'
                                          : '/assets/img/noimage.png'
                                      }
                                    />
                                  </>
                                ) : (
                                  <Uploader
                                    multiple={true}
                                    ref={(instance) =>
                                      (this.productDetail = instance)
                                    }
                                    images={this.state.imageFile}
                                    getImage={this.state.getImage}
                                    flag={true}
                                    ratio="1:1"
                                    size={{ width: 400, height: 400 }}
                                    handleImageUploader={() =>
                                      this.store.handleImageUploaderCover()
                                    }
                                    changeImageFile={(data) => {
                                      this.changeImageFileCover(data)
                                    }}
                                  />
                                )}

                                <span className="req-validation ">
                                  {this.store.coverImageFile_validation
                                    ? this.store.coverImageFile_validation.msg
                                      ? this.store.coverImageFile_validation.msg
                                      : null
                                    : null}
                                </span>
                              </CardBody>
                            </Card>
                          </Colxx>
                          <Colxx xxs="12" sm="12" lg="6" className="mb-4">
                            <Card className="mb-2">
                              <CardBody>
                                <CardTitle className="mb-3 col-12">
                                  <span className="font-weight-bold text-uppercase">
                                    {`Detail`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                <Colxx xxs="12" className="d-flex flex-wrap">
                                  <Colxx xxs="12" lg="6">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.name" />
                                      <span className="req"> *</span>
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="name"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.name_en
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeEnName(
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="req-validation ">
                                        {this.store.en_name_validation
                                          ? this.store.en_name_validation.msg
                                            ? this.store.en_name_validation.msg
                                            : null
                                          : null}
                                      </span>
                                    </div>
                                  </Colxx>
                                  <Colxx xxs="12" lg="6">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.name-ar" />
                                      <span className="req"> *</span>
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="name_ar"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.name_ar
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeArName(
                                            e.target.value
                                          )
                                        }
                                        className="ar"
                                      />
                                    </div>
                                    <span className="req-validation ">
                                      {this.store.ar_name_validation
                                        ? this.store.ar_name_validation.msg
                                          ? this.store.ar_name_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                  {this.state.role === 'admin' && (
                                    <Colxx xxs="12" lg="6" className="mt-3">
                                      <p className="text-muted text-small mb-2">
                                        {/* <IntlMessages id="pages.commission" /> */}
                                        Commission
                                      </p>
                                      <div className="mb-0">
                                        <Input
                                          name="commission"
                                          value={
                                            this.store.shopDetail
                                              ? this.store.shopDetail.commission
                                                ? this.store.shopDetail
                                                    .commission
                                                : 0
                                              : ''
                                          }
                                          type="number"
                                          min="0"
                                          // max="100"
                                          onChange={(e) =>
                                            this.store.changeCommission(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Colxx>
                                  )}
                                  <Colxx xxs="12" lg="6" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      As Soon Possible Time (min)
                                      <span className="req"> *</span>
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="asSoonPossibleTime"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail
                                                .asSoonPossibleTime
                                              ? this.store.shopDetail
                                                  .asSoonPossibleTime
                                              : 0
                                            : ''
                                        }
                                        type="number"
                                        min="0"
                                        onChange={(e) =>
                                          this.store.changeAsSoonPossibleTime(
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="req-validation ">
                                        {this.store.asap_validation
                                          ? this.store.asap_validation.msg
                                            ? this.store.asap_validation.msg
                                            : null
                                          : null}
                                      </span>
                                    </div>
                                  </Colxx>

                                  <Colxx xxs="12" lg="6" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.email" /> 1
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="email1"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.email1
                                              ? this.store.shopDetail.email1
                                              : ''
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeEmail1(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>
                                  <Colxx xxs="12" lg="6" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.email" /> 2
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="email2"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.email2
                                              ? this.store.shopDetail.email2
                                              : ''
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeEmail2(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>

                                  <Colxx xxs="12" lg="6" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.phone" /> 1
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="phone1"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.phone1
                                              ? this.store.shopDetail.phone1
                                              : ''
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changePhone1(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>

                                  <Colxx xxs="12" lg="6" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.phone" /> 2
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="phone2"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.phone2
                                              ? this.store.shopDetail.phone2
                                              : ''
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changePhone2(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>
                                  {/* <Colxx xxs="12" lg="6" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.is_available" />
                                    </p>
                                    <div className="mb-0">
                                      <FormikSwitch
                                        className="custom-switch custom-switch-primary-inverse mr-2"
                                        name={`is_available`}
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.is_available
                                            : true
                                        }
                                        onChange={() =>
                                          this.store.handleChangeIsAvailable(
                                            this.store.shopDetail.is_available
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.address" />
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="address"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.address
                                            : ''
                                        }
                                        type="textarea"
                                        onChange={(e) =>
                                          this.store.changeAddress(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12" className="mt-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.arAddress" />
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="arabicAddress"
                                        className="ar"
                                        value={
                                          this.store.shopDetail
                                            ? this.store.shopDetail.address_ar
                                              ? this.store.shopDetail.address_ar
                                              : ''
                                            : ''
                                        }
                                        type="textarea"
                                        onChange={(e) =>
                                          this.store.changeArAddress(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx> */}
                                </Colxx>
                              </CardBody>
                            </Card>
                          </Colxx>
                        </Row>
                      </Colxx>
                    </Row>
                  </Colxx>
                </>
              ) : (
                <div className="col-12">
                  <h2 className="text-center col-12">No Result</h2>
                </div>
              )
            ) : this.state.activeFirstTab === '2' ? (
              <DayList />
            ) : this.state.activeFirstTab === '3' ? (
              <AreaList />
            ) : (
              <CustomCake id={this.state.id} />
            )}
          </Row>
        )}
      </Fragment>
    )
  }
}

export default ShopDetail
