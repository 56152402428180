import React, { Component, Fragment } from 'react'
import {
  Row,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  CardTitle,
  // Badge,
} from 'reactstrap'
// import { NavLink } from 'react-router-dom'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { Colxx, Separator } from '../../components/CustomBootstrap'
import BreadcrumbContainer from '../../components/BreadcrumbContainer'
import BigCalendar from 'react-big-calendar'
import 'react-table/react-table.css'
import '../../assets/css/vendor/react-big-calendar.css'
import IntlMessages from '../../util/IntlMessages'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import moment from 'moment'
// import _ from 'lodash'
import { ToastContainer } from 'react-toastify'

import {
  LineShadow,
  chartTooltip,
  // centerTextPlugin
} from '../../components/Charts'
import { ThemeColors } from '../../util/ThemeColors'
// import ApprovalModal from "../../components/Modal/approvalModal";
const localizer = BigCalendar.momentLocalizer(moment)

const colors = ThemeColors()

@inject('stores')
@observer
class DefaultDashboard extends Component {
  @observable
  store = this.props.stores.dashboard

  @observable
  eventDetail = this.props.stores.eventDetail
  constructor(props) {
    super(props)
    this.state = {
      target: 'today',
      sale: [],
      labels: [1, 2, 3],
      helper: true,
      liveEvent: null,
    }
  }

  async componentDidMount() {
    await this.store.getTodayData(this.props.history)
    await this.store.getBaseData(this.props.history)
    this.store.getMonthDataCal(
      moment().month(this.store.targetMonth).format('M'),
      this.store.targetYear,
      this.props.history
    )
    this.setState({ sale: this.store.sale })
  }

  componentWillUnmount() {
    clearInterval(this.state.liveEvent)
  }

  chart = () => {
    const options = {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: chartTooltip,
      // scaleBeginAtZero: true,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
              lineWidth: 1,
              color: 'rgba(0,0,0,0.1)',
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              padding: 50,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    }

    const data = {
      labels: this.store.labels.length ? this.store.labels : [],
      datasets: [
        {
          label: 'Cost',
          data: this.store.sale.length ? this.store.sale : [],
          borderColor: colors.themeColor1,
          pointBackgroundColor: colors.foregroundColor,
          pointBorderColor: colors.themeColor1,
          pointHoverBackgroundColor: colors.themeColor1,
          pointHoverBorderColor: colors.foregroundColor,
          pointRadius: 6,
          pointBorderWidth: 2,
          pointHoverRadius: 8,
          fill: false,
        },
      ],
    }
    return <LineShadow options={options} data={data} />
  }

  CalendarToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV')
      const date = moment(toolbar.date)
      const month = moment().month(date.format('MMMM')).format('M')
      const year = moment().year(date.format('YYYY')).format('Y')
      const m = month === 1 ? 12 : parseInt(month) - 1
      const y = month === 12 ? parseInt(year) - 1 : parseInt(year)
      this.store.getMonthDataCal(m, y)
    }
    const goToNext = () => {
      toolbar.onNavigate('NEXT')
      const date = moment(toolbar.date)
      const month = moment().month(date.format('MMMM')).format('M')
      const year = moment().year(date.format('YYYY')).format('Y')
      const m = month === 12 ? 1 : parseInt(month) + 1
      const y = month === 12 ? parseInt(year) + 1 : parseInt(year)
      this.store.getMonthDataCal(m, y)
    }
    const goToCurrent = () => {
      toolbar.onNavigate('TODAY')
      const today = new Date()
      const month = today.getMonth()
      const year = today.getFullYear()
      const m = parseInt(month) + 1
      const y = parseInt(year)
      this.store.getMonthDataCal(m, y)
    }

    const label = () => {
      const date = moment(toolbar.date)
      return (
        <span>
          <span>{date.format('MMMM')} </span>
          <span> {date.format('YYYY')}</span>
        </span>
      )
    }

    return (
      <div className="big-calendar-header">
        <div className="float-left">
          <label>{label()}</label>
        </div>

        <div className="float-right">
          <div>
            <button
              className="btn btn-primary calendar-today-btn mr-2"
              onClick={goToCurrent}
            >
              Today
            </button>
            <button className="btn calendar-prev-btn  mr-1" onClick={goToBack}>
              <span className="simple-icon-arrow-left" />
            </button>
            <button className="btn calendar-next-btn " onClick={goToNext}>
              <span className="simple-icon-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    )
  }

  handleTarget = (value) => {
    this.setState({ target: value })
  }

  handleApprovalEvant = (id, history) => {
    this.store.handleApprovalModal()
    this.eventDetail.handleApprovalUser(id, history)
  }

  getWaitingApproval = async () => {
    const events = await this.store.getWaitingApproval(this.props.history)

    if (events.length) {
      if (events.length === 1) {
        this.eventDetail.handleApprovalUser(events[0].id, this.props.history)
      } else {
        this.store.handleApprovalModal()
      }
    }
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {/* <ApprovalModal
          data={this.store.approvalsList}
          openModal={this.store.openApprovalModal}
          toggleModal={() => this.store.handleApprovalModal()}
          action={(id) => this.handleApprovalEvant(id, this.props.history)}
        /> */}
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.dashboards" />}
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row className="d-flex justify-content-around mb-4">
              <Row className="col-xl-6 col-lg-6 col-md-12 col-xs-12 p-0 justify-content-center">
                <Row className="col-12 mb-4 " style={{ height: 'max-content' }}>
                  <Card
                    className="d-flex flex-wrap flex-row justify-content-around position-relative col-12"
                    style={{ height: 'max-content', overflow: 'unset' }}
                  >
                    <UncontrolledDropdown
                      // style={{borderRight:'1px solid #ddd'}}
                      className="pt-3 pb-3  position-relative col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center"
                      // style={{ width: "max-content" }}
                    >
                      <DropdownToggle color="" className="btn btn-link">
                        <span>
                          <IntlMessages
                            id={`dashboards.${this.store.target}`}
                          />{' '}
                          <i className="simple-icon-arrow-down ml-4" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('today')}
                        >
                          <IntlMessages id="dashboards.day" />
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('month')}
                        >
                          <IntlMessages id="dashboards.month" />
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('year')}
                        >
                          <IntlMessages id="dashboards.year" />
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('allTime')}
                        >
                          <IntlMessages id="dashboards.allTime" />
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                      // style={{borderRight:'1px solid  #ddd'}}
                      className="pt-3 pb-3  position-relative col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center"
                    >
                      <DropdownToggle
                        color=""
                        className="btn btn-link"
                        disabled={
                          this.store.target === 'today' ||
                          this.store.target === 'year' ||
                          this.store.target === 'allTime'
                        }
                      >
                        <span>
                          <span>{this.store.targetMonth}</span>{' '}
                          <i className="simple-icon-arrow-down ml-4" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="padding-drop">
                        {!this.store.months
                          ? null
                          : this.store.months.map((month, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => this.store.handleMonth(month)}
                              >
                                <span>{month}</span>
                              </DropdownItem>
                            ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                      // style={{borderRight:'1px solid  #ddd'}}
                      className="pt-3 pb-3  col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center"
                    >
                      <DropdownToggle
                        color=""
                        className="btn btn-link "
                        disabled={
                          this.store.target === 'today' ||
                          this.store.target === 'allTime'
                        }
                      >
                        <span>
                          <span>{this.store.targetYear}</span>{' '}
                          <i className="simple-icon-arrow-down ml-4" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {!this.store.availableYears.length
                          ? null
                          : this.store.availableYears.map((year, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => this.store.handleYear(year)}
                              >
                                <span>{year}</span>
                              </DropdownItem>
                            ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <div className="pt-3 pb-3 col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center">
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          this.store.handleFilter(this.props.history)
                        }
                      >
                        <span className="font-weight-bold">
                          Apply <i className="simple-icon-refresh ml-4" />
                        </span>
                      </button>
                    </div>
                  </Card>
                </Row>
                <Row className="col-12 p-0">
                  <Colxx
                    xl="6"
                    md="6"
                    xs="6"
                    xxs="12"
                    className="mb-4"
                    style={{ height: 255 }}
                  >
                    <Card
                      className="col-xl-12 col-md-12 col-xs-12 h-100 pointer"
                      // onClick={() =>
                      //   this.order.changeDashboardFilter(
                      //     false,
                      //     false,
                      //     this.store.fromDate,
                      //     this.store.toDate,
                      //     this.props.history
                      //   )
                      // }
                    >
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-coins iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.total-sale" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.data
                            ? this.store.data.totalSale + ' KD'
                            : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                  <Colxx
                    xl="6"
                    md="6"
                    xs="6"
                    xxs="12"
                    className="mb-4"
                    style={{ height: 255 }}
                  >
                    <Card
                      className="col-xl-12 col-md-12 col-xs-12 h-100 pointer"
                      // onClick={() =>
                      //   this.order.changeDashboardFilter(
                      //     false,
                      //     false,
                      //     this.store.fromDate,
                      //     this.store.toDate,
                      //     this.props.history
                      //   )
                      // }
                    >
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-checkout iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.total-sale-item" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.data ? this.store.data.totalItemSale : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                </Row>
              </Row>
              <Row className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-12 p-lg-0 p-sm-2 p-2">
                <Colxx className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-12 p-0 d-flex flex-wrap">
                  <div className="mb-4 col-xl-6 col-md-6 col-xs-12 pointer">
                    <Card
                      className="col-xl-12 col-md-12 col-xs-12 pointer"
                      // onClick={() =>
                      //   this.order.changeDashboardFilter(
                      //     'delivery_status',
                      //     '1',
                      //     this.store.fromDate,
                      //     this.store.toDate,
                      //     this.props.history
                      //   )
                      // }
                    >
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-scooter iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.inTransit" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.data ? this.store.data.inTransit : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="mb-4 col-xl-6 col-md-6 col-xs-12 pointer">
                    <Card
                      className=" col-xl-12 col-md-12 col-xs-12 pointer"
                      // onClick={() =>
                      //   this.order.changeDashboardFilter(
                      //     'delivery_status',
                      //     '0',
                      //     this.store.fromDate,
                      //     this.store.toDate,
                      //     this.props.history
                      //   )
                      // }
                    >
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-box-close iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.preparing-orders" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.data
                            ? this.store.data.perparingOrders
                            : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </div>
                </Colxx>
                <Colxx className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-12 p-0 d-flex flex-wrap">
                  <div className="mb-4 col-xl-4 col-md-4 col-xs-12 pointer">
                    <Card
                      className="col-xl-12 col-md-12 col-xs-12 pointer"
                      // onClick={() =>
                      //   this.order.changeDashboardFilter(
                      //     'delivery_status',
                      //     '2',
                      //     this.store.fromDate,
                      //     this.store.toDate,
                      //     this.props.history
                      //   )
                      // }
                    >
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-sand-watch-2 iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.submitted-orders" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.data
                            ? this.store.data.submittedOrders
                            : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="mb-4 col-xl-4 col-md-4 col-xs-12 pointer">
                    <Card
                      className="col-xl-12 col-md-12 col-xs-12 pointer"
                      // onClick={() =>
                      //   this.order.changeDashboardFilter(
                      //     'delivery_status',
                      //     '2',
                      //     this.store.fromDate,
                      //     this.store.toDate,
                      //     this.props.history
                      //   )
                      // }
                    >
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-yes iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.completed-orders" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.data
                            ? this.store.data.compeletedOrders
                            : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="mb-4 col-xl-4 col-md-4 col-xs-12 pointer">
                    <Card
                      className=" col-xl-12 col-md-12 col-xs-12 pointer"
                      // onClick={() =>
                      //   this.order.changeDashboardFilter(
                      //     'delivery_status',
                      //     '3',
                      //     this.store.fromDate,
                      //     this.store.toDate,
                      //     this.props.history
                      //   )
                      // }
                    >
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-close iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.cancelled-orders" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.data
                            ? this.store.data.cancelledOrders
                            : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </div>
                </Colxx>
              </Row>
            </Row>

            <Row className="p-0 col-12 m-0">
              <Colxx md="12" className="mb-4 p-0">
                <Card>
                  <CardBody>
                    <CardTitle>
                      <IntlMessages id="dashboards.sales" />
                      {' KD '}
                      {this.store.target === 'year'
                        ? `( ${this.store.targetYear} )`
                        : this.store.target === 'month'
                        ? `( ${this.store.targetMonth} ${this.store.targetYear} )`
                        : this.store.target === 'today'
                        ? `( ${this.store.targetMonth} ${this.store.targetYear} )`
                        : 'All Time'}
                    </CardTitle>
                    <div className="dashboard-line-chart">{this.chart()}</div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <Colxx md="12" className="mb-4 p-0">
              <Card className="h-100">
                <CardBody className="position-relative">
                  <CardTitle>
                    <IntlMessages id="dashboards.calendar" />
                  </CardTitle>
                  {this.store.calLoading ? (
                    <div className="loading"></div>
                  ) : null}
                  <BigCalendar
                    localizer={localizer}
                    style={{ padding: '0 0 50px 0 ' }}
                    events={this.store.event ? this.store.event : []}
                    views={['month']}
                    components={{
                      toolbar: this.CalendarToolbar,
                    }}
                  />
                </CardBody>
              </Card>
            </Colxx>
          </>
        )}
      </Fragment>
    )
  }
}

export default DefaultDashboard
