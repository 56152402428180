import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Card, CardBody, CardTitle, Input } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import SelectInput from '../../../components/select/index'
// import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import { ToastContainer } from 'react-toastify'
import ActiveModal from '../../../components/Modal/activeModal'

@inject('stores')
@observer
class UserDetail extends Component {
  @observable
  store = this.props.stores.userDetail

  @observable
  login = this.props.stores.login

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getUserDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.users" />}
                match={this.props.match}
              />

              <Button
                color={this.store.userDetail.status ? "danger" : "success"}
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                {this.store.userDetail.status ? "InActive" : "Active"}
              </Button>

              <ActiveModal
                target={
                  this.store.userDetail ? this.store.userDetail.name : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                status={this.store.userDetail.status ? "InActive" : "Active"}
                action={() =>
                  this.store.deleteUser(this.state.id, this.props.history)
                }
              />

              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() =>
                  this.store.validation(
                    this.state.id,
                    this.props.history,
                    this.login.userType
                  )
                }
              >
                <IntlMessages id="pages.save" />
              </Button>

              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Colxx xxs="12">
                <Row className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: '25px' }}
                  >
                    <Row>
                      <Colxx xxs="12" sm="8" lg="6" className="mb-4">
                        <Card className="mb-2" style={{ overflow: 'unset' }}>
                          <CardBody>
                            <CardTitle className="mb-3">
                              <span className="font-weight-bold text-uppercase">
                                {`Detail`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />

                            <Colxx xxs="12" lg="12" className="mt-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.email" />
                              </p>
                              <div className="mb-0">
                                <Input
                                  name="email"
                                  value={
                                    this.store.userDetail
                                      ? this.store.userDetail.email
                                      : ''
                                  }
                                  disabled={true}
                                  type="text"
                                />
                              </div>
                            </Colxx>
                            <Colxx xxs="12" lg="12" className="mt-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.personName" />
                                <span className="req"> *</span>
                              </p>
                              <div className="mb-0">
                                <Input
                                  name="name"
                                  value={
                                    this.store.userDetail
                                      ? this.store.userDetail.name
                                      : ''
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeName(e.target.value)
                                  }
                                />
                                <span className="req-validation ">
                                  {this.store.name_validation
                                    ? this.store.name_validation.msg
                                      ? this.store.name_validation.msg
                                      : null
                                    : null}
                                </span>
                              </div>
                            </Colxx>

                            <Colxx xxs="12" className="mt-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.type" />
                                <span className="req"> *</span>
                              </p>
                              <div className="mb-0">
                                <SelectInput
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  value={this.store.selectedType}
                                  onChange={(value) =>
                                    this.store.setType(value)
                                  }
                                  options={this.store.typesList}
                                  isMulti={false}
                                ></SelectInput>
                                <span className="req-validation">
                                  {this.store.type_validation
                                    ? this.store.type_validation.msg
                                      ? this.store.type_validation.msg
                                      : null
                                    : null}
                                </span>
                              </div>
                            </Colxx>

                            {this.store.selectedType.label !== 'Admin' && (
                              <Colxx xxs="12" className="mt-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="menu.shop" />
                                  <span className="req"> *</span>
                                </p>
                                <div className="mb-0">
                                  <SelectInput
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={this.store.selectedBakery}
                                    onChange={(value) =>
                                      this.store.setBakery(value)
                                    }
                                    options={this.store.bakeriesList}
                                    isMulti={false}
                                  ></SelectInput>
                                  <span className="req-validation">
                                    {this.store.bakery_validation
                                      ? this.store.bakery_validation.msg
                                        ? this.store.bakery_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </div>
                              </Colxx>
                            )}
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Row>
              </Colxx>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default UserDetail
