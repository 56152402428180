import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class UserDetailStore {
  @observable
  userDetail = null

  @observable
  imageFile = null

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  search = ''

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  typesList = [
    { value: 9, label: 'Admin' },
    { value: 2, label: 'Vendor' },
  ]

  @observable
  typesListMap = [
    { value: 9, label: 'Admin' },
    { value: 2, label: 'Vendor' },
  ]

  @observable
  selectedType = null

  @observable
  bakeriesList = []

  @observable
  selectedBakery = null

  @observable
  name_validation = null

  @observable
  type_validation = null

  @observable
  bakery_validation = null

  @action
  async validation(id, history, type) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.userDetail.name,
      require: true,
      type: 'string',
      min: 3,
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    if (this.selectedType && this.selectedType.label !== 'Admin') {
      this.bakery_validation = await checkInput({
        name: 'Shop',
        value: this.selectedBakery,
        require: true,
      })
    }

    // if (type === 'admin') {
    if (this.selectedType && this.selectedType.label !== 'Admin') {
      this.name_validation.res &&
        this.type_validation.res &&
        this.bakery_validation.res &&
        this.handleUpdateUser(id, history, type)
    } else {
      this.name_validation.res &&
        this.type_validation.res &&
        this.handleUpdateUser(id, history, type)
    }
    // } else {
    //   this.name_validation.res && this.handleUpdateUser(id, history, type)
    // }
  }

  @action
  getUserDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'admins',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.userDetail = value.data
      await this.getDependencies(history)
      await this.typesListMap.forEach((item) => {
        if ((item.value === value.data.role)) {
          this.selectedType = item
        }
      })
      this.isLoading = false
    }
  }

  @action
  deleteUser = async (id, history) => {

    this.isLoading = true
    let data = {
      status : this.userDetail.status ? 0 :1
      // name: this.userDetail.name,
      // role: this.selectedType.value
    }
    // if (this.selectedType.label !== 'Admin') {
    //   data.shopId = this.selectedBakery.value
    // }

    const value = await axiFetch.request(
      'admins',
      false,
      id,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      this.openDeleteModal = false
      this.getUserDetail(id, history)
    } else {
      this.isLoading = false
    }
    // this.isLoading = true
    // const value = await axiFetch.request(
    //   'admins',
    //   false,
    //   `${id}/delete`,
    //   false,
    //   'post',
    //   false,
    //   null,
    //   history
    // )
    // if (value) {
    //   this.openDeleteModal = false
    //   successNotify(value.meta.msg)
    //   setTimeout(() => {
    //     history.push('/app/users/list')
    //   }, 1000)
    // } else {
    //   this.isLoading = false
    // }
  }

  @action
  handleUpdateUser = async (id, history) => {
    this.isLoading = true
    let data = {
      name: this.userDetail.name,
      role: this.selectedType.value
    }
    if (this.selectedType.label !== 'Admin') {
      data.shopId = this.selectedBakery.value
    }

    const value = await axiFetch.request(
      'admins',
      false,
      id,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      this.reset()
      this.getUserDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const value = await axiFetch.request(
      'shops',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      await value.data.items.forEach((item) => {
        if (item.id === this.userDetail.shopId) {
          this.selectedBakery = { value: item.id, label: item.name_en }
        }
        this.bakeriesList.push({ value: item.id, label: item.name_en })
      })
    }
  }

  @action
  setBakery(value) {
    this.selectedBakery = value.selectedOption
  }

  @action
  setType(value) {
    this.selectedType = value.selectedOption
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action.bound
  changeName = (name) => {
    this.userDetail.name = name
  }

  @action
  reset() {
  this.userDetail = null

  this.imageFile = null

  this.isLoading = true

  this.openDeleteModal = false

  this.search = ''

  this.limit = 12

  this.offset = 1

  this.order = { createdAt: -1 }

  this.filter = {}

  this.typesList = [
    { value: 9, label: 'Admin' },
    { value: 2, label: 'Vendor' },
  ]

  this.typesListMap = [
    { value: 9, label: 'Admin' },
    { value: 2, label: 'Vendor' },
  ]

  this.selectedType = null

  this.bakeriesList = []

  this.selectedBakery = null

  this.name_validation = null

  this.type_validation = null

  this.bakery_validation = null
  }
}
