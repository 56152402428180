import React, { Component, Fragment } from 'react'
import { Row, Card, CardTitle, Form, Label, Input, Button } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { ToastContainer } from 'react-toastify'
import { Colxx } from '../../components/CustomBootstrap/index'
import IntlMessages from '../../util/IntlMessages'
import Loading from '../../components/Loading/index'
import logo from '../../assets/img/lilas-logo.png'
import mobileLogo from '../../assets/img/lilas-logo.png'

@inject('stores')
@observer
class ResetLayout extends Component {
  constructor(props) {
    super(props)
    this.state = { code: null }
  }
  @observable
  store = this.props.stores.login

  async componentDidMount() {
    let path = this.props.location.pathname.split('/')[2]
    if (this.props.location.pathname.split('/')[1] === 'reset-password-app') {
      this.store.checkUserCode(path, this.props.history)
    } else {
      this.store.checkCode(path, this.props.history)
    }
    this.setState({ code: path })
    document.body.classList.add('background')
  }
  componentWillUnmount() {
    document.body.classList.remove('background')
  }

  // getUrlParameter(name) {
  //   name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  //   var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  //   var results = regex.exec(window.location.search);
  //   return results === null
  //     ? ""
  //     : decodeURIComponent(results[1].replace(/\+/g, " "));
  // }

  render() {
    return (
      <Fragment>
        <ToastContainer />

        <div className="fixed-background" />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : this.store.expire ? (
          <Row className="h-100">
            <Colxx xxs="12" md="8" lg="6" className="mx-auto my-auto">
              <Card className="auth-card">
                <div className="form-side">
                  <div className="d-flex align-items-center flex-column">
                    <NavLink to={`/`} className="white logo-single">
                      <div className="logo d-none d-xs-block ">
                        <img
                          src={logo}
                          alt="crowded logo"
                          style={{ width: '100%' }}
                          className="mr-auto ml-auto"
                        />
                      </div>

                      <div className="logo-mobile d-block d-xs-none text-center">
                        <img
                          src={mobileLogo}
                          alt="crowded logo"
                          style={{ width: '25%' }}
                          className="mx-auto my-auto"
                        />
                      </div>
                    </NavLink>
                  </div>
                  <p className="text-center req-validation">
                    {this.store.expire}
                  </p>
                </div>
              </Card>
            </Colxx>
          </Row>
        ) : (
          <main>
            <div className="container">
              <Row className="h-100">
                <Colxx xxs="12" md="8" lg="6" className="mx-auto my-auto">
                  <Card className="auth-card">
                    <div className="form-side">
                      <div className="d-flex align-items-center flex-column">
                        <NavLink to={`/`} className="white logo-single">
                          <div className="logo d-none d-xs-block ">
                            <img
                              src={logo}
                              alt="crowded logo"
                              style={{ width: '100%' }}
                              className="mr-auto ml-auto"
                            />
                          </div>

                          <div className="logo-mobile d-block d-xs-none text-center">
                            <img
                              src={mobileLogo}
                              alt="crowded logo"
                              style={{ width: '25%' }}
                              className="mx-auto my-auto"
                            />
                          </div>
                        </NavLink>
                      </div>
                      <CardTitle className="mb-4">
                        <IntlMessages id="pages.changePassword" />
                      </CardTitle>

                      <Form>
                        <Label className="form-group has-float-label mb-4">
                          <Input
                            type="password"
                            value={this.store.newPassword}
                            onChange={(e) =>
                              this.store.changeNewPassword(e.target.value)
                            }
                          />
                          <p className="req-validation text-small mt-1 mb-3">
                            {this.store.new_password_validation
                              ? this.store.new_password_validation.msg
                                ? this.store.new_password_validation.msg
                                : null
                              : null}
                          </p>
                          <IntlMessages id="pages.newPassword" />
                        </Label>

                        <Label className="form-group has-float-label mb-4">
                          <Input
                            type="password"
                            value={this.store.confirmPassword}
                            onChange={(e) =>
                              this.store.changeConfirmPassword(e.target.value)
                            }
                          />
                          <IntlMessages id="pages.confirmPassword" />
                          <p className="req-validation text-small mt-1 mb-3">
                            {this.store.confirm_password_validation
                              ? this.store.confirm_password_validation.msg
                                ? this.store.confirm_password_validation.msg
                                : null
                              : null}
                          </p>
                        </Label>

                        <div className="d-flex justify-content-end align-items-center">
                          <Button
                            style={{ width: 128.33, height: 42.53 }}
                            color="primary"
                            className="btn-shadow"
                            size="lg"
                            disabled={this.store.btnDisable}
                            onClick={() =>
                              this.store.validationPassword(
                                this.props.history,
                                this.state.code,
                                this.props.location.pathname.split('/')[1]
                              )
                            }
                          >
                            {!this.store.loginLoading ? (
                              <IntlMessages id="pages.save" />
                            ) : (
                              <Loading />
                            )}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Card>
                </Colxx>
              </Row>
            </div>
          </main>
        )}
      </Fragment>
    )
  }
}

export default ResetLayout
