import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'
import axiFetch from '../config/fetch'
import _ from 'lodash'

export default class SettingAppStore {
  @observable
  data = []

  @observable
  isLoading = true

  @observable
  noResult = false

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  offset = 0

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })
    this.imageFile_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleSaveNewOuterShell(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    this.noResult = false
    // const body = {
    //   pagination: {
    //     page: this.offset,
    //     // limit: this.limit,
    //   },
    // };
    const value = await axiFetch.request(
      'settings',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.data = []
      value.data.forEach((item) => {
        this.data.push({
          id: item.id,
          key: item.key,
          value: item.value,
          edit: false,
          desc: item.description,
          type: item.valueType,
        })
      })
      this.isLoading = false
      if (_.isEmpty(value.data)) {
        this.noResult = true
      }
    } else {
      this.isLoading = false
      this.noResult = true
    }
  }

  @action
  handleUpdateSettings = async (history) => {
    this.isLoading = true
    let dataError = false
    await this.data.forEach(async (item) => {
      // body.push({ id: item.id, value: item.value, valueType: item.type });
      const value = await axiFetch.request(
        'settings',
        false,
        item.id,
        false,
        'post',
        false,
        { value: item.value },
        history
      )
      if (value) {
        successNotify(`${item.key} has been updated successfully`)
      } else {
        dataError = true
      }
    })
    if (dataError) {
      this.isLoading = false

      return
    }
    // this.reset()
    this.getData(history)
  }

  @action
  changeItem = (name, id) => {
    this.data.forEach((item) => {
      if (item.id === id) {
        item.value = name
      }
      return
    })
  }

  @action
  changeEditMode = (id) => {
    this.data.forEach((item) => {
      if (item.id === id) {
        item.edit = !item.edit
      } else {
        item.edit = false
      }
    })
  }

  @action
  changePage(e) {
    this.offset = e - 1
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = []

    this.isLoading = true

    this.noResult = false

    this.pagination = 0

    this.currentPage = 1

    this.offset = 0
  }
}
