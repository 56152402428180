import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'

export default class UserStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = {}

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { name: 1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  name = null

  @observable
  password = null

  @observable
  email = null

  @observable
  btnDisable = false

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters: this.filter,
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }

    const value = await axiFetch.request(
      'users',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =value.data.explain.pagination.totalPages
        // Math.ceil(value.data.explain.pagination.total / this.limit) +
        // (value.data.explain.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    } else {
      this.isLoading = true
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeName = (value) => {
    this.name = value
  }

  @action
  changePassword = (value) => {
    this.password = value
  }

  @action
  changeEmail = (value) => {
    this.email = value
  }

  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.search = ''

    this.searchOn = {}

    this.limit = 12

    this.offset = 1

    this.order = { name: 1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.name = null

    this.password = null

    this.email = null
  }
}
