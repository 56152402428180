import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import { errorNotify } from '../util/Notify'

export default class ShopStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  coverImageFile = null

  @observable
  search = ''

  @observable
  searchOn = ['name_en', 'name_ar']

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { name_en: 1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  newArName = ''

  @observable
  newEnName = ''

  @observable
  phone = null

  @observable
  email = null

  @observable
  address = null

  @observable
  arAddress = null

  @observable
  btnDisable = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  imageFile_validation = null

  @observable
  address_validation = null

  @observable
  ar_address_validation = null

  @observable
  phone_validation = null

  @observable
  email_validation = null

  @observable
  coverImageFile_validation = null

  @observable
  asap=null

  @observable
  asap_validation=null

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.phone_validation = await checkInput({
      name: 'Phone Number',
      require: true,
      value: this.phone,
      type: 'number',
    })

    this.email_validation = await checkInput({
      name: 'Email',
      value: this.email,
      require: true,
      type: 'email',
    })

    this.asap_validation = await checkInput({
      name:"As Soon Possible Time",
      value: this.asap,
      require:true,
      minValue:10,
      type:'number' 
    })

    this.coverImageFile_validation = await checkInput({
      name: 'Banner',
      value: this.coverImageFile,
      require: true,
    })

    this.imageFile_validation = await checkInput({
      name: 'Logo',
      value: this.imageFile,
      require: true,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.email_validation.res &&
      this.phone_validation.res &&
      this.imageFile_validation.res &&
      this.coverImageFile_validation.res &&
      this.asap_validation.res &&
      this.handleSaveNewShop(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters: this.filter,
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }
    const value = await axiFetch.request(
      'shops',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.explain.pagination.total / this.limit) +
        (value.data.explain.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    } else {
      this.isLoading = true
    }
  }

  @action
  handleSaveNewShop = async (history) => {
    this.isLoading = true
    this.btnDisable = true
    let body = {
      data: JSON.stringify({
        name_en: this.newEnName,
        name_ar: this.newArName,
        email1: this.email,
        phone1: this.phone,
        asSoonPossibleTime: this.asap,
        is_active: 1,
      }),
      logo:this.imageFile,
      banner:this.coverImageFile,
    }

    let formData = new FormData()
    for (let key in body) formData.append(key, body[key])

    await axios
      .post(`${servicePath}/shops/new`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        history.push(`/app/shop/detail/${data.data}`)
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 400) {
          errorNotify(error.response.data.meta.msg)
        }
        if (
          error &&
          error.response &&
          error.response.status === 500 &&
          error.response.data.meta.msg
        ) {
          errorNotify(error.response.data.meta.msg)
        }
        if (error && error.response && error.response.status === 403) {
          if (history) {
            history.push('/login')
          }
        }
        this.isLoading = false
        this.btnDisable = false
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = null
    this.newEnName = null
    this.phone = null
    this.email=null
    this.asap=null
    this.coverImageFile=null
    this.imageFile=null
    this.en_name_validation = null
    this.ar_name_validation = null
    this.asap_validation = null
    this.email_validation = null
    this.phone_validation = null
    this.coverImageFile_validation=null
    this.imageFile_validation= null
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  // @action
  // changeImageFile = (file) => {
  //   this.imageFile = file
  // }

  // @action
  // changeCoverImageFile = (file) => {
  //   this.coverImageFile = file
  // }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  changeImageFile(file) {
    this.imageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeCoverImageFile = (file) => {
    this.coverImageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  changePhone = (phone) => {
    this.phone = phone
  }

  @action
  changeEmail = (email) => {
    this.email = email
  }

  @action
  changeAsap = (asap) => {
    this.asap = asap
  }

  @action
  changeArAddress = (address) => {
    this.arAddress = address
  }

  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
  this.data = null

  this.pagination = 0

  this.currentPage = 1

  this.imageFile = null

  this.coverImageFile = null

  this.search = ''

  this.searchOn = ['name_en', 'name_ar']

  this.limit = 12

  this.offset = 1

  this.order = { name_en: 1 }

  this.filter = {}

  this.isLoading = true

  this.openModal = false

  this.newArName = ''

  this.newEnName = ''

  this.phone = null

  this.email = null

  this.address = null

  this.arAddress = null

  this.btnDisable = false

  this.en_name_validation = null

  this.ar_name_validation = null

  this.imageFile_validation = null

  this.address_validation = null

  this.ar_address_validation = null

  this.phone_validation = null

  this.email_validation = null

  this.coverImageFile_validation = null

  this.asap=null

  this.asap_validation=null
  }
}
