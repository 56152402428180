import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class FieldDetailStore {
  @observable
  fieldDetail = null

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  typesList = [
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'phone', label: 'Phone' },
  ]

  @observable
  stylesList = [
    { value: 'half', label: 'Half' },
    { value: 'full', label: 'Full' },
  ]

  @observable
  selectedType = null

  @observable
  selectedStyle = null

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  title_validation = null

  @observable
  type_validation = null

  @observable
  style_validation = null

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.fieldDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.fieldDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.title_validation = await checkInput({
      name: 'Title',
      value: this.fieldDetail.title,
      require: true,
      type: 'string',
      min: 3,
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })
    this.style_validation = await checkInput({
      name: 'Style',
      value: this.selectedStyle,
      require: true,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.title_validation.res &&
      this.type_validation.res &&
      this.style_validation.res &&
      this.handleUpdateField(id, history)
  }

  @action
  getDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'addresses/fields',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.fieldDetail = value.data
      this.typesList.forEach((item) => {
        if (item.value === value.data.joiconfig_type) {
          this.selectedType = item
        }
      })

      this.stylesList.forEach((item) => {
        if (item.value === value.data.style) {
          this.selectedStyle = item
        }
      })
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  deleteType = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'addresses/fields',
      false,
      `${id}/delete`,
      false,
      'post',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/field')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  handleUpdateField = async (id, history) => {
    this.isLoading = true
    let body = {
      name_en: this.fieldDetail.name_en,
      name_ar: this.fieldDetail.name_ar,
      title: this.fieldDetail.title,
      style: this.selectedStyle.value,
      is_required: this.fieldDetail.is_required,
      joiconfig_type: this.selectedType.value,
    }

    const value = await axiFetch.request(
      'addresses/fields',
      false,
      id,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getDetail(id)
    } else {
      this.isLoading = false
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action.bound
  changeEnName = (name) => {
    this.fieldDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.fieldDetail.name_ar = name
  }

  @action
  changeTitle = (value) => {
    this.fieldDetail.title = value
  }

  @action
  changeState = () => {
    this.fieldDetail.is_required = this.fieldDetail.is_required ? 0 : 1
  }
  @action
  setType(data) {
    this.selectedType = data.selectedOption
  }

  @action
  setStyle(data) {
    this.selectedStyle = data.selectedOption
  }

  @action
  reset() {
    this.fieldDetail = null
  
    this.isLoading = true
  
    this.openDeleteModal = false
  
    this.typesList = [
      { value: 'text', label: 'Text' },
      { value: 'number', label: 'Number' },
      { value: 'phone', label: 'Phone' },
    ]
  
    this.stylesList = [
      { value: 'half', label: 'Half' },
      { value: 'full', label: 'Full' },
    ]
  
    this.selectedType = null
  
    this.selectedStyle = null
  
    this.en_name_validation = null
  
    this.ar_name_validation = null
  
    this.title_validation = null
  
    this.type_validation = null
  
    this.style_validation = null
  }
}
