import { observable, action } from "mobx";
import _ from "lodash";
import axiFetch from "../config/fetch";
import { checkInput } from "../constants/validation";
import { successNotify } from "../util/Notify";

export default class CollectionDetailStore {
  @observable
  collectionDetail = null;

  @observable
  productList = [];

  @observable
  products = null;

  @observable
  search = null;

  @observable
  searchOn = null;

  @observable
  limit = 12;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = false;

  @observable
  searchProduct = null;

  @observable
  searchOnProduct = null;

  @observable
  limitProduct = 12;

  @observable
  offsetProduct = 0;

  @observable
  orderProduct = { id: -1 };

  @observable
  filterProduct = {};

  @observable
  currentPageProduct = 1;

  @observable
  isLoadingProduct = false;

  @observable
  openDeleteModal = false;

  @observable
  openProductModal = false;

  @observable
  pagination = null;

  @observable
  paginationProduct = null;

  @observable
  en_name_validation = null;

  @observable
  ar_name_validation = null;

  @observable
  isDynamic = false

  @observable
  discount = false

  @observable
  typesList = []

  @observable
  selectedTypes = []

  @observable
  fromPrice = 0

  @observable
  toPrice = 0

  @observable
  discountFilter = false

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: "English Name",
      value: this.collectionDetail.name_en,
      require: true,
      type: "string",
      min: 3,
    });
    this.ar_name_validation = await checkInput({
      name: "Arabic Name",
      value: this.collectionDetail.name_ar,
      require: true,
      type: "string",
      min: 3,
    });

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateCollection(id, history);
  }

  @action
  getCollectionDetail = async (id, history) => {
    this.isLoading = true
    this.imageFile = null
    this.landscapeImageFile = null
    const value = await axiFetch.request(
      'collections',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.collectionDetail = value.data
      await this.getAllTypes(history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getAllTypes = async (history) => {
    const types = await axiFetch.request(
      'types',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (types) {
      await types.data.items.map((type) =>
        this.typesList.push({ value: type.id, label: type.name_en })
      )
    }
  }

  @action
  async deleteCollection(id, history) {
    this.isLoading = true
    let value = await axiFetch.request(
      `collections/${id}/delete`,
      false,
      false,
      false,
      'post',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/collection')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  getProduct = async (history) => {
    this.isLoadingProduct = true;
    this.changeOpenProductModal();
    const body = {
      order: this.orderProduct,
      searchOn: this.searchOnProduct,
      search: this.searchProduct,
      limit: this.limitProduct,
      offset: this.offsetProduct,
      filter: this.filterProduct,
    };
    const value = await axiFetch.request(
      "cake/getcakes",
      false,
      false,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      this.products = value.data.items;
      this.paginationProduct =
        parseInt(value.data.pagination.total / this.limitProduct) +
        (value.data.pagination.total % this.limit ? 1 : 0);
      this.isLoadingProduct = false;
    } else {
      this.isLoading = false;
    }
  };

  @action
  async handleUpdateCollection(id, history) {
    this.isLoading = true
    let data = {
      data: JSON.stringify({
        name_en: this.collectionDetail.name_en,
        name_ar: this.collectionDetail.name_ar,
      }),
    }
    if (this.imageFile) {
      data.file = this.imageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    let value = await axiFetch.request(
      'collections',
      false,
      id,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.isLoading = false

      successNotify('Item has been updated successfully')
      await this.reset()
      this.getCollectionDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  addProductToCollection= async(id)=>{

    
    // if(this.productList && this.productList.length){

    //   let value = await axiFetch.request(
    //     `collections/${id}/add-products`,
    //     false,
    //     id,
    //     false,
    //     'post',
    //     false,
    //     formData,
    //     history
    //   )
    //   if (value) {

    //   }
        
    // }
  }

  @action
  addItemToSelected = (value) => {
    value.map((item) => this.productList.push(item));
    const intersection = _.intersectionBy(this.productList, "id");
    this.productList = intersection;
  };

  @action
  removeFromList = (id) => {
    const product = this.productList.filter((item) => item.id !== id);
    this.productList = product;
  };

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true;
  };

  @action
  changeOpenProductModal = () => {
    this.openProductModal = true;
  };

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false;
  };

  @action
  toggleProductModal = () => {
    this.openProductModal = false;
  };

  @action
  changePage(e) {
    this.offsetProduct = (e - 1) * 12;
    this.currentPageProduct = e;
    this.getProduct();
  }

  @action.bound
  changeEnName = (name) => {
    this.collectionDetail.name_en = name;
  };

  @action
  changeArName = (name) => {
    this.collectionDetail.name_ar = name;
  };


  @action
  changeIsDynamic = () => {
    this.isDynamic = !this.isDynamic
  }

  @action
  changeDiscount = () => {
    this.discount = !this.discount
  }

  @action
  handleDiscountFilter = () => {
    this.discountFilter = !this.discountFilter
  }

  @action
  changeToPrice = (value) => {
    this.toPrice = value
  }

  @action
  changeFromPrice = (value) => {
    this.fromPrice = value
  }

  @action
  setType = (value) => {
    this.selectedTypes = value.selectedOption
  }

  @action
  reset() {
    this.collectionDetail = null;

    this.productList = [];

    this.products = null;

    this.search = null;

    this.searchOn = null;

    this.limit = 12;

    this.offset = 0;

    this.order = { id: -1 };

    this.filter = {};

    this.isLoading = false;

    this.searchProduct = null;

    this.searchOnProduct = null;

    this.limitProduct = 12;

    this.offsetProduct = 0;

    this.orderProduct = { id: -1 };

    this.filterProduct = {};

    this.currentPageProduct = 1;

    this.isLoadingProduct = false;

    this.openDeleteModal = false;

    this.openProductModal = false;

    this.pagination = null;

    this.paginationProduct = null;

    this.en_name_validation = null;

    this.ar_name_validation = null;
  }
}
