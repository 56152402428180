import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import moment from 'moment'
import { successNotify, errorNotify } from '../util/Notify'
import { checkInput } from '../constants/validation'

export default class PhoneOrderStore {
  @observable
  data = null

  @observable
  filterOptions = [
    { column: 'all', label: 'All' },
    { column: 'pending', label: 'Pending', key: '0' },
    { column: 'completed', label: 'Completed', key: '1' },
    { column: 'canceled', label: 'Canceled', key: '3' },
  ]

  @observable
  selectedFilterOption = { column: 'all', label: 'All' }

  @observable
  selectedOrderOption = { column: 'id', label: 'Order Date' }

  @observable
  orderOptions = [
    { column: 'status', label: 'Status' },
    { column: 'status_asc', label: 'Status (Ascending)' },
    { column: 'createdAt', label: 'Order Date' },
    { column: 'createdAt_asc', label: 'Order Date (Ascending)' },
    { column: 'name', label: 'Customer' },
    { column: 'name_asc', label: 'Customer (Ascending)' },
  ]

  @observable
  orderDetail = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  startDate = null

  @observable
  endDate = null

  @observable
  name = ''

  @observable
  typesList = []

  @observable
  selectedType = null

  @observable
  areasList = []

  @observable
  selectedArea = null

  @observable
  fieldsList = []

  @observable
  name_validation = null

  @observable
  type_validation = null

  @observable
  area_validation = null

  @observable
  selectedOrder = null

  @action
  validation = async (history) => {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.name,
      require: true,
      type: 'string',
      min: 3,
    })

    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    this.area_validation = await checkInput({
      name: 'Area',
      value: this.selectedArea,
      require: true,
    })

    for (let item of this.fieldsList) {
      if (item.is_required && !item.value) {
        errorNotify('Please fill all required fields ')
        return false
      }
    }

    this.name_validation.res &&
      this.type_validation.res &&
      this.area_validation.res &&
      this.handleSaveNewAddress(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters: this.filter,
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }
    const value = await axiFetch.request(
      'phoneOrder/list',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination = value.data.explain.pagination.totalPages
      await this.getAllTypes(history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  async getAllTypes(history) {
    const types = await axiFetch.request(
      'addresses/types',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (types) {
      await types.data.items.map((type) =>
        this.typesList.push({
          value: type.id,
          label: type.name_en,
          addressFields: type.addressFields,
        })
      )
    }

    const areas = await axiFetch.request(
      'areas',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (areas) {
      await areas.data.items.map((area) =>
        this.areasList.push({
          value: area.id,
          label: area.name_en,
        })
      )
    }
  }

  @action
  handleSaveNewAddress = async (history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      name: this.name,
      typeId: this.selectedType.value,
      areaId: this.selectedArea.value,
      purchaseId: this.selectedOrder.purchaseId,
      userId: this.selectedOrder.userId
    }

    for (let item of this.fieldsList) {
      if (item.value) {
        data[item.title] = item.value
      }
    }

    const value = await axiFetch.request(
      `phoneOrder/setAddress`,
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      this.openModal = false
      this.btnDisable = false
      this.isLoading = false
      successNotify('Address has been added successfully')
      this.changeStatus(
        'completed',
        this.selectedOrder.orderId,
        history
      )
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  getOrderDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'orders',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.orderDetail = value.data
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  changeStatus = async (value, id, history) => {
    this.isLoading = true
    const body = { id: id, status: value }
    const data = await axiFetch.request(
      'phoneOrder/changeStatus',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (data) {
      successNotify('Item has been updated successfully')
      this.getData(history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.searchOn = [
        'code',
        'extra_detail.customer.bakeryName',
        'extra_detail.customer.name',
      ]
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeDashboardFilter = async (key, value, from, to, history) => {
    if (from && to) {
      this.startDate = from
      this.endDate = to
      this.filter.fromDate = moment(from).format('X')
      this.filter.toDate = moment(to).format('X')
    }
    this.startDate = from
    this.endDate = to
    if (key) {
      this.selectedFilterOption = await this.filterOptions.find(
        (x) => x.key === value
      )
      this.filter.delivery_status = value
      // this.filter = { [key]: value }
    } else {
      delete this.filter.delivery_status
    }
    history.push('/app/order/list')
  }

  @action
  changeFilterBy = async (column, history) => {
    this.selectedFilterOption = await this.filterOptions.find(
      (x) => x.column === column
    )

    this.selectedFilterOption.column === 'all'
      ? delete this.filter.status
      : (this.filter.status = this.selectedFilterOption.column)

    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  setStartDate = (date, history) => {
    this.startDate = date
    if (date) {
      this.filter.fromDate = moment(this.startDate).format('X')
    } else {
      delete this.filter.fromDate
    }

    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  setEndDate = (date, history) => {
    this.endDate = date
    if (date) {
      this.filter.toDate = moment(this.endDate).format('X')
    } else {
      delete this.filter.toDate
    }

    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  changeOrderBy = async (column, history) => {
    if (this.selectedOrderOption.column === column) {
      let target = column.includes('_asc')
        ? column.split('_asc')[0]
        : `${column}_asc`
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    } else {
      let target = column
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeOpenModal = (id) => {
    this.selectedOrder = id
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.name = null
    this.name_validation = null
    this.type_validation = null
    this.area_validation = null
    this.selectedArea = null
    this.selectedType = null
    this.selectedOrder = null
    this.fieldsList = []
  }

  @action
  changeName = (value) => {
    this.name = value
  }

  @action
  setType = (value) => {
    this.selectedType = value.selectedOption
    this.fieldsList = value.selectedOption.addressFields
  }

  @action
  setArea = (value) => {
    this.selectedArea = value.selectedOption
  }

  @action
  changeFieldValue = async (value, id) => {
    const fields = [...this.fieldsList]
    await fields.forEach((item) => {
      if (item.id === id) {
        item.value = value
      }
    })
    this.fieldsList = fields
  }

  @action
  reset() {
  this.data = null

  this.filterOptions = [
    { column: 'all', label: 'All' },
    { column: 'pending', label: 'Pending', key: '0' },
    { column: 'completed', label: 'Completed', key: '1' },
    { column: 'canceled', label: 'Canceled', key: '3' },
  ]

  this.selectedFilterOption = { column: 'all', label: 'All' }

  this.selectedOrderOption = { column: 'id', label: 'Order Date' }

  this.orderOptions = [
    { column: 'status', label: 'Status' },
    { column: 'status_asc', label: 'Status (Ascending)' },
    { column: 'createdAt', label: 'Order Date' },
    { column: 'createdAt_asc', label: 'Order Date (Ascending)' },
    { column: 'name', label: 'Customer' },
    { column: 'name_asc', label: 'Customer (Ascending)' },
  ]

  this.orderDetail = null

  this.pagination = 0

  this.currentPage = 1

  this.search = ''

  this.searchOn = null

  this.limit = 12

  this.offset = 1

  this.order = { createdAt: -1 }

  this.filter = {}

  this.isLoading = true

  this.openModal = false

  this.startDate = null

  this.endDate = null

  this.name = ''

  this.typesList = []

  this.selectedType = null

  this.areasList = []

  this.selectedArea = null

  this.fieldsList = []

  this.name_validation = null

  this.type_validation = null

  this.area_validation = null

  this.selectedOrder = null
  }
}
