import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class AreaDetailStore {
  @observable
  areaDetail = null

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  newArName = null

  @observable
  newEnName = null

  @observable
  btnDisable = false

  @observable
  new_en_name_validation = null

  @observable
  new_ar_name_validation = null

  @observable
  openModal = false

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.areaDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.areaDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateArea(id, history)
  }

  @action
  getAreaDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'areas',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.areaDetail = value.data
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  deleteArea = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'areas',
      false,
      `${id}/delete`,
      false,
      'post',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/area')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  handleUpdateArea = async (id, history) => {
    this.isLoading = true
    let body = {
      name_en: this.areaDetail.name_en,
      name_ar: this.areaDetail.name_ar,
    }

    const value = await axiFetch.request(
      'areas',
      false,
      id,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getAreaDetail(id)
    } else {
      this.isLoading = false
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action.bound
  changeEnName = (name) => {
    this.areaDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.areaDetail.name_ar = name
  }

  @action
  async addValidation(id,history) {
    this.new_en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.new_ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.new_ar_name_validation.res &&
      this.new_en_name_validation.res &&
      this.handleSaveNewCity(id,history)
  }


  @action
  handleSaveNewCity = async (id, history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
      parent_id:id
    }
    const value = await axiFetch.request(
      'areas/new',
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      this.openModal = false
      this.btnDisable = false
      history.push(`/app/area/city/${value.data}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = null
    this.newEnName = null
    this.new_en_name_validation = null
    this.new_ar_name_validation = null
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  reset() {
    this.areaDetail = null

    this.isLoading = true

    this.en_name_validation = null

    this.ar_name_validation = null

    this.newArName = null

    this.newEnName = null

    this.btnDisable = false

    this.new_en_name_validation = null

    this.new_ar_name_validation = null

    this.openModal = false
  }
}
