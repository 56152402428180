import React, { Component, Fragment } from 'react'
import {
  Row,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  CustomInput,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Colxx } from '../../components/CustomBootstrap/index'
import IntlMessages from '../../util/IntlMessages'
import Loading from '../../components/Loading/index'
import logo from "../../assets/img/lilas-logo.png";
import mobileLogo from "../../assets/img/lilas-logo.png";

@inject('stores')
@observer
class LoginLayout extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '', password: '' }
  }
  @observable
  store = this.props.stores.login

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.store.email !== '' && this.store.password !== '') {
        this.store.loginUser(
          { email: this.store.email, password: this.store.password },
          this.props.history
        )
      }
    }
  }

  onUserLogin() {
    if (this.store.email !== '' && this.store.password !== '') {
      this.store.loginUser(
        { email: this.store.email, password: this.store.password },
        this.props.history
      )
    }
  }
  componentDidMount() {
    this.store.checkRemember()
    document.body.classList.add('background')
  }
  componentWillUnmount() {
    document.body.classList.remove('background')
  }

  _handleEmail = (event) => {
    this.setState({ email: event.target.value })
  }

  _handlePassword = (event) => {
    this.setState({ password: event.target.value })
  }

  render() {
    return (
      <Fragment>
        <div className="fixed-background" />
        <ToastContainer />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="8" lg="6" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="form-side">
                    <div className="d-flex align-items-center flex-column" style={{height:'140px'}}>
                      <NavLink to={`/`} className="white logo-single">
                        <div className="logo d-none d-xs-block ">
                          <img
                          src={logo}
                          alt="cake logo"
                          style={{ width: "100%", borderRadius:"50%" }}
                          className="mr-auto ml-auto"
                        />
                        </div>

                        <div className="logo-mobile d-block d-xs-none text-center" >
                          <img
                          src={mobileLogo}
                          alt="cake logo"
                          style={{ width: "50%", borderRadius:"50%" }}
                          className="mx-auto my-auto"
                        />
                        </div>
                      </NavLink>
                    </div>

                    <CardTitle className="mb-4">
                      <IntlMessages id="user.login-title" />
                    </CardTitle>

                    <Form>
                      <Label className="form-group has-float-label mb-4">
                        <Input
                          type="email"
                          value={this.store.email}
                          onChange={(e) =>
                            this.store._handleEmail(e.target.value)
                          }
                          onKeyPress={(event) => this.handleKeyPress(event)}
                        />
                        <IntlMessages id="user.email" />
                      </Label>
                      <Label className="form-group has-float-label mb-2">
                        <Input
                          type="password"
                          value={this.store.password}
                          onChange={(e) =>
                            this.store._handlePassword(e.target.value)
                          }
                          onKeyPress={(event) => this.handleKeyPress(event)}
                        />
                        <IntlMessages
                          id="user.password"
                        />
                      </Label>
                      <div>
                        <CustomInput
                          className="less-pad-l"
                          key={`remember-me`}
                          type="checkbox"
                          id={`remember-me`}
                          label={
                            <Label
                              className="perm-lbl pointer unselectable italic-on-hover text-small"
                              onClick={(e) => {
                                this.store.handleRememberMe()
                              }}
                            >
                              Remember me
                            </Label>
                          }
                          checked={this.store.rememberMe}
                          onChange={() => {
                            this.store.handleRememberMe()
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <NavLink to={`/forgot-password`}>
                          <IntlMessages id="user.forgot-password-question" />
                        </NavLink>
                        <Button
                          style={{ width: 128.33, height: 42.53 }}
                          color="primary"
                          className="btn-shadow"
                          size="lg"
                          onClick={() => this.onUserLogin()}
                          disabled={this.store.btnDisable}
                        >
                          {!this.store.loginLoading ? (
                            <IntlMessages id="user.login-button" />
                          ) : (
                            <Loading></Loading>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    )
  }
}

export default LoginLayout
