import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Card, CardBody, CardTitle, Input } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import SunEditor from 'suneditor-react'
import { ToastContainer } from 'react-toastify'

@inject('stores')
@observer
class StaticDetail extends Component {
  @observable
  store = this.props.stores.staticPage

  @observable
  setting = this.props.stores.setting

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getStaticDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />

        {this.store.detailLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.pages" />}
                match={this.props.match}
              />

              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() =>
                  this.store.validation(this.state.id, this.props.history)
                }
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Colxx xxs="12">
                <Row className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: '25px' }}
                  >
                    <Row>
                      <Colxx xxs="12" lg="12" className="mb-4">
                        <Card className="mb-2" style={{ overflow: 'unset' }}>
                          <CardBody className="">
                            <CardTitle className="mb-3 col-12">
                              <span className="font-weight-bold text-uppercase">
                                {`Detail`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx className="d-flex flex-wrap">
                              <Colxx
                                xxs="12"
                                md="4"
                                lg="4"
                                className="mt-lg-0 mt-md-0 mt-sm-3"
                              >
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.name" />
                                  <span className="req"> *</span>
                                </p>
                                <p className="mb-0">
                                  <Input
                                    name="name"
                                    value={
                                      this.store.staticDetail
                                        ? this.store.staticDetail.name_en
                                        : ''
                                    }
                                    type="text"
                                    onChange={(e) =>
                                      this.store.changeEnName(e.target.value)
                                    }
                                  />
                                  <span className="req-validation ">
                                    {this.store.en_name_validation
                                      ? this.store.en_name_validation.msg
                                        ? this.store.en_name_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </p>
                              </Colxx>
                              <Colxx
                                xxs="12"
                                md="4"
                                lg="4"
                                className="mt-lg-0 mt-md-0 mt-sm-3"
                              >
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.name-ar" />
                                  <span className="req"> *</span>
                                </p>
                                <p className="mb-0">
                                  <Input
                                    name="name-ar"
                                    className="ar"
                                    value={
                                      this.store.staticDetail
                                        ? this.store.staticDetail.name_ar
                                        : ''
                                    }
                                    type="text"
                                    onChange={(e) =>
                                      this.store.changeArName(e.target.value)
                                    }
                                  />
                                  <span className="req-validation ">
                                    {this.store.ar_name_validation
                                      ? this.store.ar_name_validation.msg
                                        ? this.store.ar_name_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </p>
                              </Colxx>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>

                      <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                        <Card
                          className="mb-2 h-100"
                          style={{ overflow: 'unset' }}
                        >
                          <CardBody>
                            <CardTitle className="mb-3">
                              <span className="font-weight-bold text-uppercase">
                                {`English Content`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <SunEditor
                              setContents={
                                this.store.staticDetail
                                  ? this.store.staticDetail.html_en
                                  : ''
                              }
                              onChange={(data) =>
                                this.store.handleChangeEnContent(data)
                              }
                              setOptions={{
                                placeholder: 'Please type here...',
                                autoFocus: false,
                                showPathLabel: true,
                                charCounter: true,
                                // maxCharCount: 720,
                                width: '100%',
                                height: 'auto',
                                minHeight: '250px',
                                buttonList: [
                                  [
                                    'undo',
                                    'redo',
                                    'font',
                                    'fontSize',
                                    'formatBlock',
                                  ],
                                  [
                                    'bold',
                                    'underline',
                                    'italic',
                                    'strike',
                                    'subscript',
                                    'superscript',
                                    'removeFormat',
                                  ],
                                  [
                                    'fontColor',
                                    'hiliteColor',
                                    'outdent',
                                    'indent',
                                    'align',
                                    'horizontalRule',
                                    'list',
                                    'table',
                                  ],
                                  [
                                    'link',
                                    'image',
                                    'video',
                                    'fullScreen',
                                    'showBlocks',
                                    'codeView',
                                    'preview',
                                    'print',
                                    'save',
                                  ],
                                ],
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Colxx>

                      <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                        <Card
                          className="mb-2 h-100"
                          style={{ overflow: 'unset' }}
                        >
                          <CardBody>
                            <CardTitle className="mb-3">
                              <span className="font-weight-bold text-uppercase">
                                {`Arabic Content`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />

                            <SunEditor
                              setContents={
                                this.store.staticDetail
                                  ? this.store.staticDetail.html_ar
                                  : ''
                              }
                              onChange={(data) =>
                                this.store.handleChangeArContent(data)
                              }
                              setOptions={{
                                placeholder: 'Please type here...',
                                autoFocus: false,
                                showPathLabel: true,
                                charCounter: true,
                                // maxCharCount: 720,
                                width: '100%',
                                height: 'auto',
                                minHeight: '250px',
                                buttonList: [
                                  [
                                    'undo',
                                    'redo',
                                    'font',
                                    'fontSize',
                                    'formatBlock',
                                  ],
                                  [
                                    'bold',
                                    'underline',
                                    'italic',
                                    'strike',
                                    'subscript',
                                    'superscript',
                                    'removeFormat',
                                  ],
                                  [
                                    'fontColor',
                                    'hiliteColor',
                                    'outdent',
                                    'indent',
                                    'align',
                                    'horizontalRule',
                                    'list',
                                    'table',
                                  ],
                                  [
                                    'link',
                                    'image',
                                    'video',
                                    'fullScreen',
                                    'showBlocks',
                                    'codeView',
                                    'preview',
                                    'print',
                                    'save',
                                  ],
                                ],
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Row>
              </Colxx>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default StaticDetail
