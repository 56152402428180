import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Card,
  CardBody,
  Col,
  CardText,
  CardImg,
  // NavLink,
  ButtonGroup,
  Button,
} from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'

@inject('stores')
@observer
class OrderDetail extends Component {
  @observable
  store = this.props.stores.order
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getOrderDetail(path, this.props.history)
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.order" />}
              match={this.props.match}
            />
            {/* <Button
              color="primary"
              className="btn-shadow float-right"
              size="lg"
              onClick={() => window.print()}
            >
              <IntlMessages id="pages.print" />
            </Button> */}
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row></Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : this.store.orderDetail ? (
          <>
            <div className="col-12">
              <Colxx xxs="12">
                <Card>
                  <CardBody>
                    <Colxx xxs="12">
                      <div
                        className="d-flex  p-3 h-100 flex-wrap"
                        style={{ border: '1px solid gray' }}
                      >
                        <Col className="p-2 col-xl-4 col-md-4 col-sm-12 col-12">
                          <Card
                            className="col-12  h-100"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <CardBody className="p-2 pt-3 pb-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Name :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.user
                                    .name
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Phone :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.user
                                    .phone
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Email :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.user
                                    .email
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Address :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.address
                                    .name
                                }
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Shipping Phone :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.address
                                    .phone_number
                                }
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col className="p-2 col-xl-4 col-md-4 col-sm-12 col-12">
                          <Card
                            className="col-12 h-100"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <CardBody className="p-2 pt-3 pb-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Purchase Status :
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail
                                    .purchaseStatus
                                }
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Payment Method :
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail
                                    .payment_type
                                }
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Total Price :{' '}
                                </span>{' '}
                                {`${this.store.orderDetail.purchaseDetail.pay} KD`}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Lilas Profit :{' '}
                                </span>{' '}
                                {`${this.store.orderDetail.purchaseDetail.lilasProfit} KD`}
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="p-2 col-xl-4 col-md-4 col-sm-12 col-12">
                          <Card
                            className="col-12  h-100"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <CardBody className="p-2 pt-3 pb-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Id :{' '}
                                </span>
                                {
                                  this.store.orderDetail.purchaseDetail
                                    .purchaseCode
                                }
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Order Date :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.purchaseDate.split(
                                    'T'
                                  )[0]
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Order Time :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.purchaseDate.split(
                                    'T'
                                  )[1]
                                }
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Delivery Date :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.purchaseDetail.purchaseDeliveryDate.split(
                                    'T'
                                  )[0]
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Delivery Time :{' '}
                                </span>{' '}
                                {`
                                  ${
                                    this.store.orderDetail.purchaseDetail.purchaseDeliveryDate.split(
                                      'T'
                                    )[1]
                                  } `}
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      </div>
                      <div
                        className="d-flex align-items-center p-3 flex-wrap"
                        style={{ border: '1px solid gray' }}
                      >
                        <Colxx
                          xxs="12"
                          className="p-0 d-xl-block d-md-none mb-3"
                          style={{ borderBottom: '1px solid gray' }}
                        >
                          <div className="col-8 p-2 d-flex align-items-center">
                            <div className="col-2">
                              <p className="col-12 p-2 m-0 text-muted text-small text-center">
                                Image
                              </p>
                            </div>

                            <div className="col-10 d-flex align-items-center">
                              <p className="col-4 p-2 m-0 text-muted text-small text-center">
                                Prudoct Name
                              </p>
                              <p className="col-2 p-2 m-0 text-muted text-small text-center">
                                Count
                              </p>
                              <p className="col-3 p-2 m-0 text-muted text-small text-center">
                                Price (KD)
                              </p>
                              <p className="col-3 p-2 m-0 text-muted text-small text-center">
                                Total Price (KD)
                              </p>
                            </div>
                          </div>
                        </Colxx>
                        {this.store.orderDetail.orders.map((item, index) => (
                          <Card className="col-12 p-0 mb-3" key={index}>
                            <CardBody className="p-0 d-flex">
                              <div className="col-8 p-0 d-flex flex-wrap justify-content-between">
                                {item.products.map((product, index) => (
                                  <Colxx
                                    xxs="12"
                                    className="pl-0 pr-0"
                                    key={index}
                                  >
                                    <NavLink
                                      className="p-0"
                                      to={`/app/product/detail/${product.id}`}
                                    >
                                      <div className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                                        <div className="position-relative col-xl-2 col-md-4 col-sm-12 div-print">
                                          <div
                                            className="col-12 position-relative"
                                            style={{ paddingTop: '100%' }}
                                          >
                                            <CardImg
                                              src={
                                                product.medias
                                                  ? product.medias.url
                                                  : '/assets/img/noimage.png'
                                              }
                                              className=" p-2"
                                              style={{}}
                                            ></CardImg>
                                          </div>
                                        </div>
                                        <div className="col-xl-10 col-md-8 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap pt-3 pb-3">
                                          <CardText className="col-xl-4 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                            <span className="d-xl-none d-md-inline font-weight-bold">
                                              Product name :{' '}
                                            </span>
                                            {product.name_en}
                                          </CardText>
                                          <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                            <span className="d-xl-none d-md-inline font-weight-bold">
                                              Count :{' '}
                                            </span>
                                            {product.count}
                                          </CardText>
                                          <CardText className="col-xl-3 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                            <span className="d-xl-none d-md-inline font-weight-bold">
                                              Price (KD):{' '}
                                            </span>
                                            {product.price}
                                          </CardText>
                                          <CardText className="col-xl-3 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                            <span className="d-xl-none d-md-inline font-weight-bold">
                                              Total Price (KD):{' '}
                                            </span>
                                            {product.price * product.count}
                                          </CardText>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </Colxx>
                                ))}
                                <Col className="p-2 col-xl-12 col-md-12 col-sm-12 div-print d-flex align-items-center justify-content-center">
                                  <ButtonGroup className="d-flex justify-content-center">
                                    {/* <Button
                                      onClick={() =>
                                        this.store.changeStatus(
                                          'pending',
                                          item.id,
                                          this.props.history,
                                          this.state.id
                                        )
                                      }
                                      color={
                                        item.status === 'pending'
                                          ? 'light'
                                          : 'danger'
                                      }
                                    >
                                      Pending
                                    </Button> */}
                                    <Button
                                      onClick={() =>
                                        this.store.changeStatus(
                                          'ordered',
                                          item.id,
                                          this.props.history,
                                          this.state.id
                                        )
                                      }
                                      color={
                                        item.status === 'ordered'
                                          ? 'light'
                                          : 'danger'
                                      }
                                    >
                                      Ordered
                                    </Button>

                                    <Button
                                      onClick={() =>
                                        this.store.changeStatus(
                                          'delivery_on_process',
                                          item.id,
                                          this.props.history,
                                          this.state.id
                                        )
                                      }
                                      color={
                                        item.status === 'delivery_on_process'
                                          ? 'light'
                                          : 'danger'
                                      }
                                    >
                                      <IntlMessages id="pages.inTransit" />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        this.store.changeStatus(
                                          'delivered',
                                          item.id,
                                          this.props.history,
                                          this.state.id
                                        )
                                      }
                                      color={
                                        item.status === 'delivered'
                                          ? 'light'
                                          : 'danger'
                                      }
                                    >
                                      <IntlMessages id="pages.completed" />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        this.store.changeStatus(
                                          'canceled',
                                          item.id,
                                          this.props.history,
                                          this.state.id
                                        )
                                      }
                                      color={
                                        item.status === 'canceled'
                                          ? 'light'
                                          : 'danger'
                                      }
                                      disabled={item.status === 'delivered'}
                                    >
                                      <IntlMessages id="pages.cancelled" />
                                    </Button>
                                  </ButtonGroup>
                                </Col>
                              </div>
                              <div
                                className="col-4 p-0"
                                style={{ borderLeft: '1px solid gray' }}
                              >
                                <Col className="p-2 col-xl-12 col-md-12 col-sm-12 col-12 h-100">
                                  <Card
                                    className="col-12  h-100"
                                    style={{ backgroundColor: '#eee' }}
                                  >
                                    <CardBody className="p-2 pt-3 pb-3">
                                      <CardText className="text-small text-muted">
                                        <span className="font-weight-bold text-black ">
                                          Id :{' '}
                                        </span>
                                        {item.code}
                                      </CardText>

                                      <CardText className="text-small text-muted">
                                        <span className="font-weight-bold text-black ">
                                          status :{' '}
                                        </span>{' '}
                                        {item.status}
                                      </CardText>
                                      <CardText className="text-small text-muted">
                                        <span className="font-weight-bold text-black ">
                                          Shop :{' '}
                                        </span>{' '}
                                        {item.products[0].shop.name_en}
                                      </CardText>
                                      <CardText className="text-small text-muted">
                                        <span className="font-weight-bold text-black ">
                                          Delivery Date :{' '}
                                        </span>{' '}
                                        {item.deliveryDate}
                                      </CardText>
                                      <CardText className="text-small text-muted">
                                        <span className="font-weight-bold text-black ">
                                          Delivery Charge :{' '}
                                        </span>{' '}
                                        {item.deliveryCharge}
                                      </CardText>

                                      <CardText className="text-small text-muted">
                                        <span className="font-weight-bold text-black ">
                                          Sub Total :{' '}
                                        </span>{' '}
                                        {item.subtotal}
                                      </CardText>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                      </div>
                      <div
                        className="d-flex align-items-center p-3 flex-wrap"
                        style={{ border: '1px solid gray' }}
                      >
                        {/* <Colxx xxs="12" className="p-0 ">
                          <div>
                            <Card className="col-12 pl-0 pr-0 pointer">
                              <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                                <div
                                  className="col-12 d-flex align-items-center flex-wrap p-2"
                                  style={{ borderBottom: "1px solid gray" }}
                                >
                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    {`Sub Total : ${this.store.orderDetail.subtotal} KD`}
                                  </CardText>
                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                   {`Coupon : ${this.store.orderDetail.coupon? this.store.orderDetail.coupon : 0} KD`}
                                  </CardText>

                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    {`Shipping : ${this.store.orderDetail.delivery} KD`}
                                  </CardText>
                                </div>
                                <CardText className="col-12 text-one font-weight-bold text-center text-muted m-0 p-3">
                                  Total :{" "}
                                  {`${this.store.orderDetail.total_price.value} ${this.store.orderDetail.summary.currency}`}
                                </CardText>
                              </CardBody>
                            </Card>
                          </div>
                        </Colxx> */}
                      </div>
                    </Colxx>
                  </CardBody>
                </Card>
              </Colxx>
            </div>
          </>
        ) : null}
      </Fragment>
    )
  }
}

export default OrderDetail
