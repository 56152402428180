import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'

export default class AreaStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  bakeryDetail = null

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  newArName = null

  @observable
  newEnName = null

  @observable
  btnDisable = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  selectedOrderOption = { column: 'createdAt', label: 'Create Date' }

  @observable
  orderOptions = [
    { column: 'createdAt', label: 'Create Date' },
    { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
    { column: 'name_en', label: 'Name' },
    { column: 'name_en_asc', label: 'Name (Ascending)' },
    { column: 'name_ar', label: 'Arabic Name' },
    { column: 'name_ar_asc', label: 'Arabic Name (Ascending)' },
  ]

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleSaveNewArea(history)
  }

  @action
  getData = async (history, id) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters:{
        parent_id: id
    },
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }
    const value = await axiFetch.request(
      'areas',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =value.data.explain.pagination.totalPages
        // parseInt(value.data.explain.pagination.total / this.limit) +
        // (value.data.explain.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  handleSaveNewArea = async (history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
    }
    const value = await axiFetch.request(
      'areas/new',
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      this.openModal = false
      this.btnDisable = false
      history.push(`/app/area/detail/${value.data}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = null
    this.newEnName = null
    this.en_name_validation = null
    this.ar_name_validation = null
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  changePage(e, history, id) {
    this.offset = e
    this.currentPage = e
    this.getData(history, id)
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.bakeryDetail = null

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 1

    this.order = { createdAt: -1 }

    this.filter = {}

    this.isLoading = true

    this.openModal = false

    this.newArName = null

    this.newEnName = null

    this.btnDisable = false

    this.en_name_validation = null

    this.ar_name_validation = null

    this.selectedOrderOption = { column: 'createdAt', label: 'Create Date' }

    this.orderOptions = [
      { column: 'createdAt', label: 'Create Date' },
      { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
      { column: 'name_en', label: 'Name' },
      { column: 'name_en_asc', label: 'Name (Ascending)' },
      { column: 'name_ar', label: 'Arabic Name' },
      { column: 'name_ar_asc', label: 'Arabic Name (Ascending)' },
    ]
  }
}
