

import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import axiFetch from '../config/fetch'
import { successNotify } from '../util/Notify'

export default class CustomHomeStore {
  @observable
  isLoading = true

  @observable
  openCategoryModal = false

  @observable
  openCollectionModal = false

  @observable
  openBannerModal = false

  @observable
  items = [
    {
      key: 'banner',
      label: 'Banner',
      selected: false,
      isMulti: false,
      data: [],
    },
    {
      key: 'slider',
      label: 'Slider',
      selected: false,
      isMulti: false,
      data: [],
    },
    {
      key: 'productList',
      label: 'Product List',
      selected: false,
      isMulti: true,
    },
  ]

  @action
  async getDefault(history) {
    this.isLoading = true
    axios
      .get(`${servicePath}/homepages`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        // this.layout = data.data
        const value = []

        data.data[0] &&
          (await data.data.forEach(async (item) => {
            if (item.type === 'slider') {
              const slides = []
              await item.items.forEach((slide) => {
                slides.push({
                  id: slide.slide.id,
                  media: slide.slide.media.url,
                })
              })
              value[item.order - 1] = {
                type: item.type,
                key: item.type,
                items: slides,
              }
            }
            if (item.type === 'banner') {
              value[item.order - 1] = {
                type: item.type,
                key: item.type,
                item: { id: item.item.id, image: item.item.media.url },
              }
            }
            if (item.type === 'productList') {
              value[item.order - 1] = {
                type: item.type,
                key: item.type,
                item: {
                  id: item.typeId,
                  image: item.media.url,
                  name: item.name_en,
                },
              }
            }
          }))
        this.layout = value

        await this.getCollections(history)
        await this.getSlides(history)
        await this.getBanners(history)

        this.isLoading = false
      })
      .catch((err) => {
        checkUnauthorized(err, history)
      })
  }

  @action
  async getSlides(history) {
    const value = await axiFetch.request(
      'slides',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.slides = value.data.items
    }
  }
  @action
  async getBanners(history) {
    const value = await axiFetch.request(
      'banners',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.banners = value.data.items
    }
  }
  @action
  async getCollections(history) {
    const value = await axiFetch.request(
      'collections',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.collections = value.data
    }
  }

  @action
  handleChangeLayout(data) {
    this.items.forEach((item) => {
      if (item.key === data.key) {
        item.selected = true
        this.layout.unshift(item)
        item.selected = false
      }
    })
    if (data.key === 'slider') {
      this.handleChangeCategoryModal()
    }
    if (data.key === 'banner') {
      this.handleChangeBannerModal()
    }
    if (data.key === 'productList') {
      this.handleChangeCollectionModal()
    }
  }

  @action
  handleChangeCategoryModal() {
    this.openCategoryModal = true
  }

  @action
  toggleCategoryModal() {
    this.openCategoryModal = false
    let data = this.layout
    data.splice(0, 1)
    this.layout = [...data]
  }

  @action
  handleChangeBannerModal() {
    this.openBannerModal = true
  }

  @action
  toggleBannerModal() {
    this.openBannerModal = false
    let data = this.layout
    data.splice(0, 1)
    this.layout = [...data]
  }

  @action
  handleChangeCollectionModal() {
    this.openCollectionModal = true
  }

  @action
  toggleCollectionModal() {
    this.openCollectionModal = false
    let data = this.layout
    data.splice(0, 1)
    this.layout = [...data]
  }

  @action
  addItemToCategoryList = (value, target, history) => {
    this.openCategoryModal = false

    if (target === 'slide') {
      const data = [...this.layout]
      data[0].data = value
      data[0].items = value
      data[0].type = 'slider'
      this.layout = data
    }
    this.saveHomePage(history)
  }

  @action
  addItemToCollectionList(value, target, id, history) {
    if (target === 'banner') {
      this.openBannerModal=false
      const data = [...this.layout]
      // data[0].data = value
      data[0].type = 'banner'
      data[0].item = { id: value[0].id, image: value[0].media }
      this.layout = data
    }
    if (target === 'collection') {
      this.openCollectionModal=false
      const data = [...this.layout]
      // data[0].data = value
      data[0].type = 'productList'
      data[0].item = { id: value[0].id, image: value[0].media.url }
      this.layout = data
    }
    this.saveHomePage(history)
  }

  @action
  removeItem = async (value, order) => {
    this.isLoading = true
    let data = [...this.layout]
    await data.splice(order, 1)
    this.layout = [...data]
    this.isLoading = false
  }

  @action
  changeLayout(data) {
    this.layout = data
  }

  @action
  saveHomePage = async (history) => {
    this.isLoading = true
    const data = []
    console.log(JSON.stringify(this.layout))
    for (const item of this.layout) {
      if (item.key === 'slider') {
        const ids = []
        await item.items.forEach((slide) => {
          ids.push(slide.id)
        })
        await data.push({
          type: item.key,
          typeIds: ids,
        })
      }
      if (item.key === 'banner') {
        await data.push({
          type: item.key,
          typeId: item.item.id,
        })
      }
      if (item.key === 'productList') {
        await data.push({
          type: item.key,
          typeId: item.item.id,
        })
      }
    }

    axios
      .post(
        `${servicePath}/homepages/add`,
        { items: data },
        {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
      )
      .then((res) => res.data)
      .then(async (data) => {
        successNotify('Item has been updated successfully')
        await this.reset()
        await this.getDefault(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }
  @action
  reset = () => {
    this.isLoading = true

    this.openCategoryModal = false

    this.openCollectionModal = false

    this.openBannerModal = false

    this.items = [
      {
        key: 'banner',
        label: 'Banner',
        selected: false,
        isMulti: false,
        data: [],
      },
      {
        key: 'slider',
        label: 'Slider',
        selected: false,
        isMulti: false,
        data: [],
      },
      {
        key: 'productList',
        label: 'Product List',
        selected: false,
        isMulti: true,
        info: null,
      },
    ]
  }
}
