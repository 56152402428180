import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BannerList from './list';
import BannerDetail from './detail';


const Banner = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={BannerList} />
            <Route path={`${match.url}/detail/:id`} component={BannerDetail} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Banner;