import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'
import SelectInput from '../select'
import ImageUploader from '../Uploader/Uploader'

class FeaturetteModal extends Component {
  constructor(props) {
    super(props)
    this.state = { getImage: false }
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeImageFile(data)
    } else {
      this.setState({ getImage: false })
    }
    this.props.handleSave()
  }

  // changeArabicImageFile = async (data) => {
  //   if (data) {
  //     this.setState({ getImage: false })
  //     await this.props.changeArabicImageFile(data)
  //   } else {
  //     this.setState({ getImage: false })
  //   }
  //   this.props.handleSave()
  // }

  render() {
    const {
      openModal,
      toggleModal,
      btnDisable,
      imageFile_validation,
      // name,
      // changeName,
      url,
      changeUrl,
      typeList,
      selectedType,
      setType,
      itemList,
      selectedItem,
      setItem,
      // name_validation,
      // ar_imageFile_validation,
      item_validation,
      url_validation,
      type_validation,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader
          toggle={toggleModal}
          className="d-flex justify-content-center align-items-center"
        >
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody className="p-2">
          {/* <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.featuretteName" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <Input
                name="name"
                value={name ? name : ''}
                type="text"
                onChange={(e) => changeName(e.target.value)}
              />
            </div>
            <span className="req-validation">
              {name_validation
                ? name_validation.msg
                  ? name_validation.msg
                  : null
                : null}
            </span>
          </Colxx> */}
          <Colxx xxs="12" className=" mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.type" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <SelectInput
                className="react-select"
                classNamePrefix="react-select"
                value={selectedType}
                onChange={(value) => setType(value)}
                options={typeList}
                isMulti={false}
              ></SelectInput>
            </div>
            <span className="req-validation">
              {type_validation
                ? type_validation.msg
                  ? type_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
          {selectedType && selectedType.value === 'url' ? (
            <Colxx xxs="12" className="mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.url" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="url"
                  value={url ? url : ''}
                  type="text"
                  onChange={(e) => changeUrl(e.target.value)}
                />
              </div>
              <span className="req-validation">
                {url_validation
                  ? url_validation.msg
                    ? url_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          ) : null}
          {selectedType && (selectedType.label === 'Category' ||
          selectedType.label === 'Shop' ||
          selectedType.label === 'Collection' ||
          selectedType.label === 'Product') ? (
            <Colxx xxs="12" className=" mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.item" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <SelectInput
                  className="react-select"
                  classNamePrefix="react-select"
                  value={selectedItem}
                  onChange={(value) => setItem(value)}
                  options={itemList}
                  isMulti={false}
                ></SelectInput>
              </div>
              <span className="req-validation">
                {item_validation
                  ? item_validation.msg
                    ? item_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          ) : null}
          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.enImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.VendorModal = instance)}
              images={this.state.imageFile}
              ratio="2:1"
              size={{ width: 400, height: 200 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeImageFile(data)
              }}
            />
            <span className="req-validation">
              {' '}
              {imageFile_validation
                ? imageFile_validation.msg
                  ? imageFile_validation.msg
                  : null
                : null}
            </span>
           
          </Colxx>
          {/* <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.arImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.featureteModal = instance)}
              images={this.state.imageFile}
              ratio="2:1"
              size={{ width: 400, height: 200 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeArabicImageFile(data)
              }}
            />
           
            <span className="req-validation">
              {' '}
              {ar_imageFile_validation
                ? ar_imageFile_validation.msg
                  ? ar_imageFile_validation.msg
                  : null
                : null}
            </span>
           
          </Colxx> */}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => this.handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default FeaturetteModal
