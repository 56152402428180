import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Input, Card, CardBody, CardTitle } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import SelectInput from '../../../components/select'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ImageUploader from '../../../components/Uploader/Uploader'

@inject('stores')
@observer
class AddProduct extends Component {
  @observable
  store = this.props.stores.addProduct

  @observable
  login = this.props.stores.login

  constructor(props) {
    super(props)
    this.state = { selectedOption: null, role:null }
  }
  componentDidMount() {
    const role  =  window.localStorage.getItem('lilas_role')
    if(role){
     this.setState({ role:role })
     this.store.getDependencies(this.props.history, role)
    }
    else{
      this.props.history.push('/login')
    }
  }
  componentWillUnmount() {
    this.store.reset()
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFile(data)
    } else {
      this.setState({ getImage: false })
      await this.store.changeImageFile(null)
    }
    this.store.validation(
      this.props.history,
      this.state.role
    )
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.product" />}
                match={this.props.match}
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => this.handleSave()}
                // onClick={() => this.store.validation(this.props.history)}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <Card className="mb-12" style={{ overflow: 'unset' }}>
                    <CardBody>
                      <CardTitle></CardTitle>
                      <Row>
                        <Colxx xxs="12" sm="4" lg="3" className="mb-4">
                          <ImageUploader
                            multiple={false}
                            ref={(instance) => (this.VendorModal = instance)}
                            images={this.state.imageFile}
                            ratio="1:1"
                            size={{ width: 400, height: 400 }}
                            getImage={this.state.getImage}
                            changeImageFile={(data) => {
                              this.changeImageFile(data)
                            }}
                          />
                          <span className="req-validation ">
                            {this.store.image_validation
                              ? this.store.image_validation.msg
                                ? this.store.image_validation.msg
                                : null
                              : null}
                          </span>
                        </Colxx>
                        <Colxx
                          xxs="12"
                          sm="8"
                          lg="9"
                          className="mb-8  d-flex flex-wrap"
                          style={{ height: 'max-content' }}
                        >
                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.enName ? this.store.enName : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.arName ? this.store.arName : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.price" />( KD )
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="price"
                                value={this.store.price ? this.store.price : ''}
                                type="number"
                                min="0"
                                onChange={(e) =>
                                  this.store.changePrice(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.price_validation
                                ? this.store.price_validation.msg
                                  ? this.store.price_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          {this.state.role === 'admin' && (
                            <Colxx xxs="12" lg="6" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="menu.shop" />
                                <span className="req"> *</span>
                              </p>

                              <SelectInput
                                className="react-select"
                                classNamePrefix="react-select"
                                value={
                                  this.store.shop ? this.store.shop : null
                                }
                                onChange={(value) =>
                                  this.store.setShop(value)
                                }
                                options={this.store.shopList}
                                isMulti={false}
                              />
                              <span className="req-validation ">
                                {this.store.shop_validation
                                  ? this.store.shop_validation.msg
                                    ? this.store.shop_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                           )} 

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.category" />
                              <span className="req"> *</span>
                            </p>

                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={
                                this.store.category ? this.store.category : null
                              }
                              onChange={(value) =>
                                this.store.setCategory(value)
                              }
                              options={this.store.categoriesList}
                              isMulti={false}
                            />
                            <span className="req-validation ">
                              {this.store.category_validation
                                ? this.store.category_validation.msg
                                  ? this.store.category_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.type" />
                              <span className="req"> *</span>
                            </p>

                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={
                                this.store.type ? this.store.type : null
                              }
                              onChange={(value) =>
                                this.store.setType(value)
                              }
                              options={this.store.typesList}
                              isMulti={false}
                            />
                            <span className="req-validation ">
                              {this.store.type_validation
                                ? this.store.type_validation.msg
                                  ? this.store.type_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.stock" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="stock"
                                value={this.store.stock ? this.store.stock : ''}
                                type="number"
                                min="0"
                                onChange={(e) =>
                                  this.store.changeStock(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.stock_validation
                                ? this.store.stock_validation.msg
                                  ? this.store.stock_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.width" />( cm )
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="width"
                                value={this.store.width ? this.store.width : ''}
                                type="number"
                                min="0"
                                onChange={(e) =>
                                  this.store.changeWidth(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.width_validation
                                ? this.store.width_validation.msg
                                  ? this.store.width_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.height" />( cm )
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="height"
                                value={this.store.height ? this.store.height : ''}
                                type="number"
                                min="0"
                                onChange={(e) =>
                                  this.store.changeHeight(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.height_validation
                                ? this.store.height_validation.msg
                                  ? this.store.height_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" />
                          <Colxx xxs="12" lg="6">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="address"
                                value={
                                  this.store.enDescription
                                    ? this.store.enDescription
                                    : ''
                                }
                                type="textarea"
                                onChange={(e) =>
                                  this.store.changeEnDescription(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.en_description_validation
                                ? this.store.en_description_validation.msg
                                  ? this.store.en_description_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description-ar" />
                              {/* <span className="req"> *</span> */}
                            </p>
                            <div className="mb-3">
                              <Input
                                className="ar"
                                name="address"
                                value={
                                  this.store.arDescription
                                    ? this.store.arDescription
                                    : ''
                                }
                                type="textarea"
                                onChange={(e) =>
                                  this.store.changeArDescription(e.target.value)
                                }
                              />
                            </div>
                            {/* <span className="req-validation ">
                              {this.store.ar_description_validation
                                ? this.store.ar_description_validation.msg
                                  ? this.store.ar_description_validation.msg
                                  : null
                                : null}
                            </span> */}
                          </Colxx>
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default AddProduct
