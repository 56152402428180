import React, { Component } from 'react'
import { Card, CardText, CardBody, Badge } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { Colxx } from '../CustomBootstrap'

class CardThumbList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { data, path } = this.props
    return (
      <Colxx xxs="12" md="12" lg="6">
        <NavLink to={`/app/${path}/${data.id}`} className="d-flex col-12">
          <Card className="d-flex flex-row mb-3 col-12 p-0">
            {data.media || data.medias ? (
              <img
                alt={data.name_en}
                src={
                  data.media
                    ? data.media.url.includes('https://')
                      ? data.media.url
                      : `https://media.lilas.dev.nizek.com/${data.media.url}`
                    : data.medias
                    ? data.medias[0].url
                    : '/assets/img/noimage.png'
                }
                className="list-thumbnail responsive border-0"
              />
            ) : null}

            {data.name_en && (data.media || data.medias) ? (
              <div className="p-0 pl-3 d-flex flex-grow-1 min-width-zero">
                <div className="card-body p-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <div className="w-50 w-sm-100 d-flex flex-row flex-grow-1">
                    <p className="list-item-heading font-weight-bold text-one m-0 truncate col-6">{`${data.name_en}`}</p>
                    {data.price ? (
                      <p className="list-item-heading font-weight-bold text-one m-0 truncate col-6">{`${data.price} KD`}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            {/* {path === 'collection/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{` ${data.name_ar}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{`${
                    data.in_homepage ? 'Show in Home' : 'Dont Show in Home'
                  }`}</CardText>
                </CardBody>
              </NavLink>
            ) : null} */}

            {path === 'type/detail' ||
            path === 'addressType/detail' ||
            (path === 'area/detail') | (path === 'area/city') ? (
              <div className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-center truncate font-weight-bold text-one">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-center truncate font-weight-bold text-one">{` ${data.name_ar}`}</CardText>
                </CardBody>
              </div>
            ) : null}

            {path === 'field/detail' ? (
              <div className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-center truncate font-weight-bold text-one">{` ${data.title}`}</CardText>
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-center truncate font-weight-bold text-one">{` ${data.joiconfig_type}`}</CardText>
                </CardBody>
              </div>
            ) : null}

            {path === 'customers/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name}`}</CardText>
                  <CardText className="col-xl-5 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.email}`}</CardText>
                  <CardText className="col-xl-3 col-md-12 m-xl-0 mb-md-2 text-left truncate">{`${
                    data.phonenumber ? data.phonenumber : '-'
                  }`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'users/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12 p-3">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name}`}</CardText>
                  <CardText className="col-xl-5 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.email}`}</CardText>
                  <CardText className="col-xl-3 col-md-12 m-xl-0 mb-md-2 text-center truncate text-white pt-1 pb-1">
                    <Badge
                      color={
                        data.role === 10 || data.role === 9 ? 'primary' : 'info'
                      }
                      className="text-one"
                    >{`${
                      data.role === 10
                        ? 'Admin'
                        : data.role === 9
                        ? 'Admin'
                        : 'Vendor'
                    }`}</Badge>
                  </CardText>
                </CardBody>
              </NavLink>
            ) : null}
            {path === 'pages/detail' ? (
              <CardBody className="d-flex flex-wrap align-items-center m-0 col-12 p-3">
                <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_en}`}</CardText>
                <CardText className="col-xl-5 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_ar}`}</CardText>
                <CardText className="col-xl-3 col-md-12 m-xl-0 mb-md-2 text-center truncate text-white pt-1 pb-1">
                  <Badge
                    color={data.in_app ? 'success' : 'danger'}
                    className="text-one"
                  >{`${data.in_app ? 'Active' : 'Inactive'}`}</Badge>
                </CardText>
              </CardBody>
            ) : null}
          </Card>
        </NavLink>
      </Colxx>
    )
  }
}

export default CardThumbList
