import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'

class ActiveModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { openModal, target, toggleModal, action, status } = this.props
    return (
      <Modal isOpen={openModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {status}
          {/* <IntlMessages id="pages.active" /> */}
        </ModalHeader>
        <ModalBody>
          {`Are you sure you want to ${status} "${target}" ? `}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={action}
          >
            {`Yes ${status} it `}
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ActiveModal
