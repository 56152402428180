import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import { successNotify } from '../util/Notify'
import _ from 'lodash'

export default class CollectionStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  collectionDetail = null

  @observable
  imageFile = null

  @observable
  landscapeImageFile = null

  @observable
  imageFile_validation = null

  @observable
  landscapeImageFile_validation = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  openDeleteModal = false

  @observable
  showType = 'row'

  @observable
  newArName = null

  @observable
  ar_name_validation = null

  @observable
  newEnName = null

  @observable
  en_name_validation = null

  @observable
  btnDisable = false

  @observable
  successAction = false

  @observable
  successMessage = ''

  @observable
  showUploaderSqr = true

  @observable
  showUploaderRec = true

  @observable
  isDynamic = false

  @observable
  discount = false

  @observable
  typesList = []

  @observable
  selectedTypes = []

  @observable
  fromPrice = 0

  @observable
  toPrice = 0

  @observable
  discountFilter = false

  @observable
  typesIds = []

  @observable
  selectedOrderOption = { column: 'createdAt', label: 'Create Date' }

  @observable
  orderOptions = [
    { column: 'createdAt', label: 'Create Date' },
    { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
    { column: 'name_en', label: 'Name' },
    { column: 'name_en_asc', label: 'Name (Ascending)' },
  ]

  @observable
  openProductModal = false

  @observable
  products = []

  @observable
  isLoadingProduct = false

  @observable
  productList = []

  @action
  handleImageUploaderSqr = () => {
    this.showUploaderSqr = !this.showUploaderSqr
  }

  @action
  handleImageUploaderRec = () => {
    this.showUploaderRec = !this.showUploaderRec
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderSqr(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderSqr = !this.showUploaderSqr
  }

  @action
  async changeImageFileUploderRec(file) {
    this.landscapeImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderRec = !this.showUploaderRec
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      sortBy: this.order,
      search: this.search,
      limit: this.limit,
      page: this.offset,
    }
    const value = await axiFetch.request(
      'collections',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.getAllTypes(history)
      this.pagination =
        parseInt(value.data.explain.pagination.total / this.limit) +
        (value.data.explain.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getCollectionDetail = async (id, history) => {
    this.isLoading = true
    this.imageFile = null
    this.landscapeImageFile = null
    const value = await axiFetch.request(
      'collections',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.collectionDetail = value.data
      this.productList = value.data.is_dynamic ? [] : value.data.products
      this.isDynamic = value.data.is_dynamic
      if (value.data.is_dynamic) {
        const data = JSON.parse(value.data.dynamic_data)
        if (data.price) {
          if (data.price.from) {
            this.fromPrice = data.price.from
          }
          if (data.price.to) {
            this.toPrice = data.price.to
          }
        }
        if (data.hasOwnProperty('discount')) {
          this.discountFilter = true
          this.discount = data.discount
        }
        if (data.typeIds && data.typeIds.length) {
          this.typesIds = data.typeIds
        }
      }
      await this.getAllTypes(history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getAllTypes = async (history) => {
    const types = await axiFetch.request(
      'types',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (types) {
      await types.data.items.forEach((type) => {
        this.typesList.push({ value: type.id, label: type.name_en })
        this.typesIds.forEach((id) => {
          if (id === type.id) {
            this.selectedTypes.push({ value: type.id, label: type.name_en })
          }
        })
      })
    }
  }

  @action
  async deleteCollection(id, history) {
    this.isLoading = true
    let value = await axiFetch.request(
      `collections/${id}/delete`,
      false,
      false,
      false,
      'post',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      this.reset()
      setTimeout(() => {
        history.push('/app/collection')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  async handleUpdateCollection(id, history) {
    this.isLoading = true
    let data = {
      name_en: this.collectionDetail.name_en,
      name_ar: this.collectionDetail.name_ar,
      is_dynamic: this.isDynamic ? 1 : 0,
      dynamic_data: {},
    }
    if (!this.isDynamic) {
      delete data.dynamic_data
    }
    if (this.isDynamic && this.toPrice) {
      data.dynamic_data.price = {}
      data.dynamic_data.price.from = this.fromPrice
      data.dynamic_data.price.to = this.toPrice
    } else if (this.isDynamic && this.fromPrice) {
      data.dynamic_data.price.from = this.fromPrice
    }

    if (this.isDynamic && this.discountFilter) {
      data.dynamic_data.discount = this.discount
    }

    if (this.isDynamic && this.selectedTypes.length) {
      const ids = []
      await this.selectedTypes.forEach((item) => {
        ids.push(item.value)
      })
      data.dynamic_data.typeIds = ids
    }
    let body = {
      data: JSON.stringify(data),
    }
    if (this.imageFile) {
      body.file = this.imageFile
    }
    let formData = new FormData()
    for (let key in body) formData.append(key, body[key])
    let value = await axiFetch.request(
      'collections',
      false,
      id,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.isLoading = false
      await this.addProductToCollection(id)
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getCollectionDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  addProductToCollection = async (id, history) => {
    if (this.productList && this.productList.length) {
      const productIds = []

      for (const item of this.productList) {
        productIds.push(item.id)
      }
      let value = await axiFetch.request(
        `collections/${id}/add-products`,
        false,
        false,
        false,
        'post',
        false,
        { productIds: productIds },
        history
      )
      if (value) {
        return true
      }
    } else {
      return false
    }
  }

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })
    this.imageFile_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleSaveNewCollection(history)
  }

  @action
  async updateValidation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.collectionDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.collectionDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateCollection(id, history)
  }

  @action
  async handleSaveNewCollection(history) {
    this.isLoading = true
    this.btnDisable = true

    let data = {
      name_en: `${this.newEnName}`,
      name_ar: `${this.newArName}`,
      is_dynamic: this.isDynamic ? 1 : 0,
      dynamic_data: {},
    }
    if (!this.isDynamic) {
      delete data.dynamic_data
    }
    if (this.isDynamic && this.toPrice) {
      data.dynamic_data.price = {}
      data.dynamic_data.price.from = this.fromPrice
      data.dynamic_data.price.to = this.toPrice
    } else if (this.isDynamic && this.fromPrice) {
      data.dynamic_data.price.from = this.fromPrice
    }

    if (this.discountFilter) {
      data.dynamic_data.discount = this.discount
    }

    if (this.selectedTypes.length) {
      const ids = []
      await this.selectedTypes.forEach((item) => {
        ids.push(item.value)
      })
      data.dynamic_data.typeIds = ids
    }

    let body = {
      data: JSON.stringify(data),
      file: this.imageFile,
    }
    let formData = new FormData()
    for (let key in body) formData.append(key, body[key])
    const value = await axiFetch.request(
      'collections/new',
      false,
      false,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.isLoading = false
      this.openModal = false
      this.btnDisable = false
      await this.reset()
      history.push(`/app/collection/detail/${value.data}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  getProduct = async (history, page = 1) => {
    this.isLoadingProduct = true
    this.changeOpenProductModal()
    const body = {
      sortBy: {
        // name_en: -1
      },
      search: '',
      filter: {},
      limit: 500,
      page: page,
    }
    const value = await axiFetch.request(
      'products',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.products = value.data.items
      // this.paginationProduct =
      //   parseInt(value.data.pagination.total / this.limitProduct) +
      //   (value.data.pagination.total % this.limit ? 1 : 0);
      this.isLoadingProduct = false
    } else {
      this.isLoading = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newEnName = ''
    this.newArName = ''
    this.imageFile = null
    this.isDynamic = false
    this.discount = false
    // this.typesList = []
    this.selectedTypes = []
    this.fromPrice = 0
    this.toPrice = 0
    this.en_name_validation = null
    this.ar_name_validation = null
    this.imageFile_validation = null
  }

  @action
  addItemToSelected = (value) => {
    value.map((item) => this.productList.push(item))
    const intersection = _.intersectionBy(this.productList, 'id')
    this.productList = intersection
    this.openProductModal = false
  }

  @action
  removeFromList = (id) => {
    const product = this.productList.filter((item) => item.id !== id)
    this.productList = product
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  changeOpenProductModal = () => {
    this.openProductModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  toggleProductModal = () => {
    this.openProductModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeLandscapeImageFile = (file) => {
    this.landscapeImageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeIsDynamic = () => {
    this.isDynamic = !this.isDynamic
  }

  @action
  changeDiscount = () => {
    this.discount = !this.discount
  }

  @action
  handleDiscountFilter = () => {
    this.discountFilter = !this.discountFilter
  }

  @action
  changeToPrice = (value) => {
    this.toPrice = value
  }

  @action
  changeFromPrice = (value) => {
    this.fromPrice = value
  }

  @action
  setType = (value) => {
    this.selectedTypes = value.selectedOption
  }

  @action
  changeEnName = (name) => {
    this.collectionDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.collectionDetail.name_ar = name
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }
  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.collectionDetail = null

    this.imageFile = null

    this.landscapeImageFile = null

    this.imageFile_validation = null

    this.landscapeImageFile_validation = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 1

    this.order = { createdAt: -1 }

    this.filter = {}

    this.isLoading = true

    this.openModal = false

    this.openDeleteModal = false

    this.showType = 'row'

    this.newArName = null

    this.ar_name_validation = null

    this.newEnName = null

    this.en_name_validation = null

    this.btnDisable = false

    this.successAction = false

    this.successMessage = ''

    this.showUploaderSqr = true

    this.showUploaderRec = true

    this.isDynamic = false

    this.discount = false

    this.typesList = []

    this.selectedTypes = []

    this.fromPrice = 0

    this.toPrice = 0

    this.discountFilter = false

    this.typesIds = []

    this.selectedOrderOption = { column: 'createdAt', label: 'Create Date' }

    this.orderOptions = [
      { column: 'createdAt', label: 'Create Date' },
      { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
      { column: 'name_en', label: 'Name' },
      { column: 'name_en_asc', label: 'Name (Ascending)' },
    ]

    this.openProductModal = false

    this.products = []

    this.isLoadingProduct = false

    this.productList = []
  }
}
