import { observable, action } from "mobx";
import axiFetch from "../config/fetch";
import { checkInput } from "../constants/validation";
import { successNotify } from "../util/Notify";

export default class TypeDetailStore {
  @observable
  typeDetail = null;

  @observable
  isLoading = true;

  @observable
  openDeleteModal = false;

  @observable
  en_name_validation = null;

  @observable
  ar_name_validation = null;

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: "English Name",
      value: this.typeDetail.name_en,
      require: true,
      type: "string",
      min: 3,
    });
    this.ar_name_validation = await checkInput({
      name: "Arabic Name",
      value: this.typeDetail.name_ar,
      require: true,
      type: "string",
      min: 3,
    });

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateType(id, history);
  }

  @action
  getTypeDetail = async (id, history) => {
    this.isLoading = true;
    const value = await axiFetch.request(
      "types",
      false,
      id,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.typeDetail = value.data;
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  };

  @action
  deleteType = async (id, history) => {
    this.isLoading = true;
    const value = await axiFetch.request(
      "types",
      false,
      `${id}/delete`,
      false,
      "post",
      false,
      null,
      history
    );
    if (value) {
      this.openDeleteModal = false;
      successNotify("Item has been deleted successfully");
      setTimeout(() => {
        history.push("/app/type");
      }, 1000);
    } else {
      this.isLoading = false;
    }
  };

  

  @action
  handleUpdateType = async (id, history) => {
    this.isLoading = true;
    let body = {
      name_en: this.typeDetail.name_en,
      name_ar: this.typeDetail.name_ar,
    };

    const value = await axiFetch.request(
      "types",
      false,
      id,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getTypeDetail(id);
    } else {
      this.isLoading = false;
    }
  };

  

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true;
  };



  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false;
  };

 

 
  @action.bound
  changeEnName = (name) => {
    this.typeDetail.name_en = name;
  };

  @action
  changeArName = (name) => {
    this.typeDetail.name_ar = name;
  };

  @action
  reset() {
  this.typeDetail = null;

  this.isLoading = true;

  this.openDeleteModal = false;

  this.en_name_validation = null;

  this.ar_name_validation = null;
  }
}
