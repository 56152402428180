import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'

export default class BannerStore {
  @observable
  data = null

  @observable
  keys = [
    { value: 'category', label: 'Category', key: 'category' },
    { value: 'category2', label: 'All Categories', key: 'category' },
    { value: 'collection', label: 'Collection', key: 'collection' },
    { value: 'collection2', label: 'All Collections', key: 'collection' },
    { value: 'product', label: 'Product', key: 'product' },
    // { value: 'product2', label: 'All Products', key: 'product' },
    { value: 'shop', label: 'Shop', key: 'shop' },
    { value: 'shop2', label: 'All Shops', key: 'shop' },
    { value: 'url', label: 'Url', key: 'url' },
  ]

  @observable
  categoryList = []

  @observable
  shopList = []

  @observable
  collectionList = []

  @observable
  productList = []

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFileEn = null

  @observable
  imageFileAr = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  name = ''

  @observable
  btnDisable = false

  @observable
  typeList = []

  @observable
  selectedType = null

  @observable
  itemList = []

  @observable
  selectedItem = null

  @observable
  url = ''

  @observable
  productsList = []

  @observable
  en_name_validation = null

  @observable
  imageFile_validation = null

  @observable
  ar_imageFile_validation = null

  @observable
  item_validation = null

  @observable
  url_validation = null

  @observable
  type_validation = null

  @action
  async getData(history) {
    this.isLoading = true
    this.getDependencies(history)
    const value = await axiFetch.request(
      'banners',
      false,
      false,
      false,
      'get',
      false,
      false,
      history
    )
    if (value) {
      this.data = value.data.items
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const categories = await axiFetch.request(
      'categories',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (categories) {
      await categories.data.result.map((category) =>
        this.categoryList.push({
          value: category.id,
          label: category.name_en,
        })
      )
    }

    const shops = await axiFetch.request(
      'shops',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (shops) {
      await shops.data.items.map((shop) =>
        this.shopList.push({
          value: shop.id,
          label: shop.name_en,
        })
      )
    }

    const collections = await axiFetch.request(
      'collections',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (collections) {
      await collections.data.map((collection) =>
        this.collectionList.push({
          value: collection.id,
          label: collection.name_en,
        })
      )
    }

    const body = {
      sortBy: { createdAt: -1 },
      search: '',
      limit: 500,
      page: 1,
      filter: {},
    }

    const products = await axiFetch.request(
      'products',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )

    if (products) {
      await products.data.items.map((product) =>
        this.productsList.push({
          value: product.id,
          label: product.name_en,
        })
      )
    }
  }

  @action
  async validation(history) {
    if (this.selectedType && this.selectedType.label === 'Url') {
      this.url_validation = await checkInput({
        name: 'Url',
        value: this.url,
        require: true,
        type: 'string',
        min: 3,
      })
    }

    this.imageFile_validation = await checkInput({
      name: 'English Image',
      value: this.imageFileEn,
      require: true,
    })

    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    if (this.selectedType && !this.selectedType.label.includes('All ')) {
      this.item_validation = await checkInput({
        name: 'Item',
        value: this.selectedItem,
        require: true,
      })
    } else {
      this.item_validation = { res: true }
    }
    if (this.selectedType && this.selectedType.label === 'Url') {
      this.imageFile_validation.res &&
        this.url_validation.res &&
        this.type_validation.res &&
        this.handleSaveNewBanner(history)
    } else {
      this.imageFile_validation.res &&
        this.item_validation.res &&
        this.type_validation.res &&
        this.handleSaveNewBanner(history)
    }
  }

  @action
  handleSaveNewBanner = async (history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      file: this.imageFileEn,
      data: JSON.stringify({
        url: this.selectedType.value === 'url' ? this.url : '',
        deeplink:
          this.selectedType.value === 'url'
            ? ''
            : {
                type: this.selectedType.key,
                id: this.selectedType.label.includes('All ')
                  ? ''
                  : this.selectedItem.value,
              },
      }),
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      'banners/new',
      false,
      false,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      await this.toggleModal()
      this.isLoading = false
      this.btnDisable = false
      history.push(`/app/banner/detail/${value.data}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  setType(data) {
    this.selectedType = data.selectedOption
    this.selectedItem = null
    this.item_validation = null
    switch (this.selectedType.label) {
      case 'Category':
        this.itemList = [...this.categoryList]
        break
      case 'Collection':
        this.itemList = [...this.collectionList]
        break
      case 'Shop':
        this.itemList = [...this.shopList]
        break
      case 'Product':
        this.itemList = [...this.productsList]
        break
      case 'Url':
        this.url = ''
        this.url_validation = null
        break
      default:
        break
    }
  }

  @action
  setItem(data) {
    this.selectedItem = data.selectedOption
  }
  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.imageFileAr = null
    this.imageFileEn = null
    this.name = ''
    this.url = ''
    this.selectedType = null
    this.selectedItem = null
    this.en_name_validation = null
    this.imageFile_validation = null
    this.ar_imageFile_validation = null
    this.item_validation = null
    this.url_validation = null
    this.type_validation = null
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileEn(file) {
    this.imageFileEn = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeUrl = (value) => {
    this.url = value
  }

  @action
  changeImageFileAr = (file) => {
    this.imageFileAr = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeName = (name) => {
    this.name = name
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.keys = [
      { value: 'category', label: 'Category', key: 'category' },
      { value: 'category2', label: 'All Categories', key: 'category' },
      { value: 'collection', label: 'Collection', key: 'collection' },
      { value: 'collection2', label: 'All Collections', key: 'collection' },
      { value: 'product', label: 'Product', key: 'product' },
      // { value: 'product2', label: 'All Products', key: 'product' },
      { value: 'shop', label: 'Shop', key: 'shop' },
      { value: 'shop2', label: 'All Shops', key: 'shop' },
      { value: 'url', label: 'Url', key: 'url' },
    ]

    this.categoryList = []

    this.shopList = []

    this.collectionList = []

    this.productList = []

    this.pagination = 0

    this.currentPage = 1

    this.imageFileEn = null

    this.imageFileAr = null

    this.search = null

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.openModal = false

    this.name = ''

    this.btnDisable = false

    this.typeList = []

    this.selectedType = null

    this.itemList = []

    this.selectedItem = null

    this.url = ''

    this.productsList = []

    this.en_name_validation = null

    this.imageFile_validation = null

    this.ar_imageFile_validation = null

    this.item_validation = null

    this.url_validation = null

    this.type_validation = null
  }
}
