import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'

export default class CakeStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  cakeDetail = null

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = ['name_en', 'name_ar']

  @observable
  selectedOrderOption = { column: 'createdAt', label: 'Create Date' }

  @observable
  orderOptions = [
    { column: 'name_en', label: 'Title' },
    { column: 'name_en_asc', label: 'Title (Ascending)' },
    { column: 'createdAt', label: 'Create Date' },
    { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
    { column: 'price', label: 'Price' },
    { column: 'price_asc', label: 'Price (Ascending)' },
  ]

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  shopList = [{ value: 0, label: 'All' }]

  @observable
  categoryList = [{ value: 0, label: 'All' }]

  @observable
  shop = { value: 0, label: 'All' }

  @observable
  category = { value: 0, label: 'All' }

  @observable
  loadingDependencies = false

  @observable
  role = window.localStorage.getItem('lilas_role')



  @action
  async getData(history , role) {
    this.isLoading = true
    const body = {
      sortBy: this.order,
      search: this.search,
      limit: this.limit,
      page: this.offset,
      filter: this.filter,
    }

    if(role!=='admin'){
      body.filter.shopId=window.localStorage.getItem('lilas_shop_id')
    }

    const value = await axiFetch.request(
      'products',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      // await this.getDependencies(history)
      this.pagination = Math.ceil(
        value.data.explain.pagination.total / this.limit
      )
      this.isLoading = false
    } else {
      this.isLoading = false
    }
    // }
  }

  @action
  async getDependencies(history, role) {
    // this.categoryList = [{ value: 0, label: 'All' }]
    // this.shopList = [{ value: 0, label: 'All' }]
    const categories = await axiFetch.request(
      'categories',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if(role==='admin'){

      const shops = await axiFetch.request(
        'shops',
        false,
        false,
        false,
        'get',
        false,
        null,
        history
      )

      if (shops) {
        await shops.data.items.map((shop) =>
          this.shopList.push({
            value: shop.id,
            label: shop.name_en,
          })
        )
      }
    }
    if (categories) {
      await categories.data.result.map((category) =>
        this.categoryList.push({
          value: category.id,
          label: category.name_en,
        })
      )
    }

   
  }

  // @action
  // async getDependencies(history) {
  //   this.loadingDependencies = true
  //   const value = await axiFetch.request(
  //     'cake',
  //     false,
  //     false,
  //     false,
  //     'post',
  //     'getDependencies',
  //     null,
  //     history
  //   )
  //   if (value) {
  //     await value.data.categories.map((category) =>
  //       this.categoryList.push({
  //         value: category.id,
  //         label: category.title,
  //       })
  //     )
  //     this.categoryList.unshift({ value: 0, label: 'All' })

  //     await value.data.bakeries.map((item) =>
  //       this.bakeryList.push({ value: item.id, label: item.name_en })
  //     )
  //     this.bakeryList.unshift({ value: 0, label: 'All' })
  //     // return true;
  //     this.loadingDependencies = false
  //   }
  // }
  @action
  async handleKeyPress(e, history, role) {
    if (e.key === 'Enter') {
      this.currentPage = 1
      this.offset = 1
      this.getData(history, role)
    }
  }

  @action
  handleChangeSearch(value) {
    this.search = value
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeEnName = (name) => {
    this.cakeDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.cakeDetail.name_ar = name
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }
  @action
  changePage(e, history, role) {
    this.offset = e
    this.currentPage = e
    this.getData(history, role)
  }

  @action
  setCategory(value, history, role) {
    this.category = value.selectedOption
    if (this.category.label !== 'All') {
      this.filter = {
        categoryId: this.category.value,
      }
      if (this.shop && this.shop.label !== 'All') {
        this.filter.shopId = this.shop.value
      } else {
        delete this.filter.shopId
      }
    } else {
      delete this.filter.categoryId
      if (this.shop && this.shop.label !== 'All') {
        this.filter.shopId = this.shop.value
      } else {
        delete this.filter.shopId
      }
    }
    this.currentPage = 1
    this.offset = 1
    // this.filter = {bakery_id :this.bakery ?  this.bakery.value: null , category_id : this.category.value }
    this.getData(history, role)
  }

  @action
  setBakery(value, history, role) {
    this.shop = value.selectedOption
    if (this.shop.label !== 'All') {
      this.filter = {
        shopId: this.shop.value,
      }
      if (this.category && this.category.label !== 'All') {
        this.filter.categoryId = this.category.value
      } else {
        delete this.filter.categoryId
      }
    } else {
      delete this.filter.shopId
      if (this.category && this.category.label !== 'All') {
        this.filter.categoryId = this.category.value
      } else {
        delete this.filter.categoryId
      }
    }
    this.currentPage = 1
    this.offset = 1
    // this.filter = {
    //   bakery_id: this.bakery.value,
    //   category_id: this.category ? this.category.value : null
    // };
    this.getData(history, role)
  }
  @action
  changeOrderByList = async (column, history, role) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 1
    this.currentPage = 1
    this.getData(history, role)
  }

  @action
  reset() {
  this.data = null

  this.pagination = 0

  this.cakeDetail = null

  this.currentPage = 1

  this.imageFile = null

  this.search = ''

  this.searchOn = ['name_en', 'name_ar']

  this.selectedOrderOption = { column: 'createdAt', label: 'Create Date' }

  this.orderOptions = [
    { column: 'name_en', label: 'Title' },
    { column: 'name_en_asc', label: 'Title (Ascending)' },
    { column: 'createdAt', label: 'Create Date' },
    { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
    { column: 'price', label: 'Price' },
    { column: 'price_asc', label: 'Price (Ascending)' },
  ]

  this.limit = 12

  this.offset = 1

  this.order = { createdAt: -1 }

  this.filter = {}

  this.isLoading = true

  this.shopList = [{ value: 0, label: 'All' }]

  this.categoryList = [{ value: 0, label: 'All' }]

  this.shop = { value: 0, label: 'All' }

  this.category = { value: 0, label: 'All' }

  this.loadingDependencies = false
  
  this.role = window.localStorage.getItem('lilas_role')
  }
}
