import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class LoginStore {
  @observable
  email = ''

  @observable
  password = ''

  @observable
  authUser = false

  @observable
  btnDisable = false

  @observable
  loginLoading = false

  @observable
  forgetEmail = ''

  @observable
  newPassword = ''

  @observable
  confirmPassword = ''

  @observable
  isLoading = true

  @observable
  expire = null

  @observable
  response = null

  @observable
  new_password_validation = null

  @observable
  confirm_password_validation = null

  @observable
  rememberMe = false

  @observable
  rememberMeEmail = null

  @observable
  rememberMePassword = null

  @observable
  userType = null

  @observable
  bakeryId = null

  @action
  async checkUserLogin(history) {
    const userEmail = await window.localStorage.getItem('user_email')
    // const userType = await window.localStorage.getItem('cakeUserType')
    // const bakeryId = await window.localStorage.getItem('cakeBakeryId')
    // if (userType) {
    //   this.userType = userType
    // } else {
    //   history.push('/login')
    // }
    // if (bakeryId) {
    //   this.bakeryId = bakeryId
    // } else {
    //   if (userType !== 'admin') {
    //     history.push('/login')
    //   }
    // }
    if (userEmail) {
      this.authUser = true
    } else {
      history.push('/login')
    }
  }

  @action
  async loginUser(userInfo, history) {
    if (this.rememberMe) {
      window.localStorage.setItem('rememberLilasEmail', userInfo.email)
      window.localStorage.setItem('rememberLilasPass', userInfo.password)
    } else {
      window.localStorage.removeItem('rememberLilasEmail')
      window.localStorage.removeItem('rememberLilasPass')
    }
    this.loginLoading = true
    this.btnDisable = true
    const data = { email: userInfo.email, password: userInfo.password }
    const value = await axiFetch.request(
      'auth/login',
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value && value.meta.code === 200) {
      localStorage.setItem('user_email', value.data.email)
      localStorage.setItem('lilas_user_id', value.data.id)
      localStorage.setItem('lilas_shop_id', value.data.shopId)
      localStorage.setItem('lilas_userName', value.data.name)
      localStorage.setItem('lilas_role', value.data.role===9?'admin':'vendor')
      // localStorage.setItem('cakeUserType', value.data.type)
      // localStorage.setItem('cakeBakeryId', value.data.type_id)
      // this.userType = value.data.type
      // this.bakeryId = value.data.type_id
      this.authUser = true
      this.loginLoading = false
      this.btnDisable = false
      history.push('/app/dashboards/default')
    } else {
      this.loginLoading = false
      this.btnDisable = false
    }
  }

  @action
  logoutUser = (history) => {
    this.authUser = false
    localStorage.removeItem('user_email')
    localStorage.removeItem('lilas_user_id')
    localStorage.removeItem('lilas_shop_id')
    localStorage.removeItem('lilas_userName')
    localStorage.removeItem('lilas_role')
    history.replace('/login')
  }

  @action
  changeNewPassword(value) {
    this.newPassword = value
  }

  @action
  changeConfirmPassword(value) {
    this.confirmPassword = value
  }

  @action
  async validationPassword(history, code, target) {
    if (this.newPassword === this.confirmPassword) {
      this.new_password_validation = await checkInput({
        name: 'New Password',
        value: this.newPassword,
        require: true,
        min: 6,
      })
      this.confirm_password_validation = await checkInput({
        name: 'Confirm Password',
        value: this.confirmPassword,
        require: true,
        min: 6,
      })

      this.new_password_validation.res &&
        this.confirm_password_validation.res &&
        this.handleSavePassword(history, code, target)
    } else {
      this.new_password_validation = null
      this.confirm_password_validation = {
        res: false,
        msg: 'New Password And Confirm Password Must Be Matched',
      }
    }
  }

  @action
  async handleSavePassword(history, code, target) {
    this.loginLoading = true
    this.btnDisable = true
    const body = {
      hash: code,
      password: this.newPassword,
      passwordConfirm: this.confirmPassword,
    }

    if (target === 'reset-password-app') {
      const value = await axiFetch.request(
        `auth/user/resetPassword/use`,
        false,
        false,
        false,
        'post',
        false,
        body,
        history
      )
      if (value) {
        this.loginLoading = false
        this.btnDisable = false
        history.push('/login')
      } else {
        this.loginLoading = false
        this.btnDisable = false
      }
    } else {
      const value = await axiFetch.request(
        `auth/admin/resetPassword/use`,
        false,
        false,
        false,
        'post',
        false,
        body,
        history
      )
      if (value) {
        this.loginLoading = false
        this.btnDisable = false
        history.push('/login')
      } else {
        this.loginLoading = false
        this.btnDisable = false
      }
    }
  }

  @action
  async checkCode(code, history) {
    this.isLoading = true
    const body = {
      hash: code,
    }
    const value = await axiFetch.request(
      `auth/admin/resetPassword/verify`,
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.isLoading = false
    } else {
      this.isLoading = false
      this.expire = 'Your Code Is Expired, Please Try Again'
      setTimeout(() => {
        history.push('/forgot-password')
      }, 3000)
    }
  }

  @action
  async checkUserCode(code, history) {
    this.isLoading = true
    const body = {
      hash: code,
    }
    const value = await axiFetch.request(
      `auth/user/resetPassword/verify`,
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.isLoading = false
    } else {
      this.isLoading = false
      this.expire = 'Your Code Is Expired, Please Try Again'
    }
  }

  @action
  changeForgetEmail(email) {
    this.forgetEmail = email
  }

  @action
  async setEmailPassword(history) {
    this.loginLoading = true
    this.btnDisable = true

    const body = {
      email: this.forgetEmail,
    }

    const value = await axiFetch.request(
      'auth/admin/resetPassword/claim',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.loginLoading = false
      this.btnDisable = false
      successNotify('Successfully , please check your email')
      setTimeout(() => {
        history.push('/login')
      }, 2000)
    } else {
      this.loginLoading = false
      this.btnDisable = false
    }
  }
  @action
  handleRememberMe = () => {
    this.rememberMe = !this.rememberMe
  }

  @action
  checkRemember = async () => {
    let email = await window.localStorage.getItem('rememberLilasEmail')
    let pass = await window.localStorage.getItem('rememberLilasPass')

    if (email && pass) {
      this.email = email
      this.password = pass
      this.rememberMe = true
    }
  }

  @action
  _handleEmail = (value) => {
    this.email = value
  }
  @action
  _handlePassword = (value) => {
    this.password = value
  }
}
