import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FieldList from './list';
import FieldDetail from './detail';



const Field = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={FieldList} />
            <Route path={`${match.url}/detail/:id`} component={FieldDetail} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Field;