import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'

export default class AddProductStore {
  @observable
  isLoading = true

  @observable
  arName = null

  @observable
  enName = null

  @observable
  price = null

  @observable
  enDescription = null

  @observable
  arDescription = ''

  @observable
  imageFile = null

  @observable
  category = null

  @observable
  categoriesList = []

  @observable
  typesList = []

  @observable
  type = null

  @observable
  shopList = []

  @observable
  shop = null

  @observable
  stock = null

  @observable
  width = null

  @observable
  height = null

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  price_validation = null

  @observable
  category_validation = null

  @observable
  image_validation = null

  @observable
  shop_validation = null

  @observable
  type_validation = null

  @observable
  stock_validation = null

  @observable
  width_validation = null

  @observable
  height_validation = null

  @observable
  en_description_validation = null

  @observable
  ar_description_validation = null

  @action
  async validation(history , role) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.enName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.arName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.enDescription,
      require: true,
      type: 'string',
      min: 3,
    })

    // this.ar_description_validation = await checkInput({
    //   name: 'Arabic Description',
    //   value: this.arDescription,
    //   require: true,
    //   type: 'string',
    //   min: 3,
    // })

    this.category_validation = await checkInput({
      name: 'Category',
      value: this.category,
      require: true,
    })

    this.shop_validation = await checkInput({
      name: 'Shop',
      value: this.shop,
      require: true,
    })
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.price,
      require: true,
      type: 'number',
      minValue: '0',
    })

    this.stock_validation = await checkInput({
      name: 'Stock',
      value: this.stock,
      require: true,
      type: 'number',
      minValue: '0',
    })

    this.width_validation = await checkInput({
      name: 'Width',
      value: this.width,
      require: true,
      type: 'number',
      minValue: '0',
    })

    this.height_validation = await checkInput({
      name: 'Height',
      value: this.height,
      require: true,
      type: 'number',
      minValue: '0',
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.type,
      require: true,
    })

    this.image_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })

    if(role==="admin"){
    this.price_validation.res &&
      this.stock_validation.res &&
      this.width_validation.res &&
      this.height_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.en_description_validation.res &&
      this.type_validation.res &&
      this.category_validation.res &&
      this.image_validation.res &&
      this.shop_validation.res &&
      this.handleSaveProduct(history, role)
    }else{
      this.price_validation.res &&
      this.stock_validation.res &&
      this.width_validation.res &&
      this.height_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.en_description_validation.res &&
      this.type_validation.res &&
      this.category_validation.res &&
      this.image_validation.res &&
      // this.shop_validation.res &&
      this.handleSaveProduct(history, role)}
  }

  @action
  async getDependencies(history, role) {
    this.isLoading = true

    const types = await axiFetch.request(
      'types',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    const categories = await axiFetch.request(
      'categories',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )

    if(role==='admin'){
      const shops = await axiFetch.request(
        'shops',
        false,
        false,
        false,
        'get',
        false,
        null,
        history
      )
      if(shops){
        await shops.data.items.map((shop)=>
        this.shopList.push({ value: shop.id, label: shop.name_en})
        )
      }
    }
   
    if (categories) {
      await categories.data.result.map((category) =>
        this.categoriesList.push({
          value: category.id,
          label: category.name_en,
        })
      )
    }

    if (types) {
      await types.data.items.map((type) =>
        this.typesList.push({ value: type.id, label: type.name_en })
      )
    }

   

    this.isLoading = false
  }

  @action
  changeEnName(value) {
    this.enName = value
  }

  @action
  changeArName(value) {
    this.arName = value
  }

  @action
  changeEnDescription(value) {
    this.enDescription = value
  }

  @action
  changeArDescription(value) {
    this.arDescription = value
  }

  @action
  changePrice(value) {
    this.price = value
  }

  @action
  setType(value) {
    this.type = value.selectedOption
  }

  @action
  setCategory(value) {
    this.category = value.selectedOption
  }

  @action
  setShop(value) {
    this.shop = value.selectedOption
  }

  @action
  changeStock = (value) => {
    this.stock = value
  }

  @action
  changeWidth = (value) => {
    this.width = value
  }

  @action
  changeHeight = (value) => {
    this.height = value
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file) {
    if(file){
      this.imageFile = this.base64ToBlob(file, 'image/jpg')
    }
    else{
      this.imageFile=null
    }
  }

  @action
  async handleSaveProduct(history , role) {
    this.isLoading = true
    const data = {
      file: this.imageFile,
      data: JSON.stringify({
        name_en: this.enName,
        name_ar: this.arName,
        description_en: this.enDescription,
        description_ar: this.arDescription,
        price: this.price,
        stock: this.stock,
        shopId: role==='admin'? this.shop.value : window.localStorage.getItem('lilas_shop_id'),
        typeId: this.type.value,
        height: this.height,
        width: this.width,
        categoryId: this.category.value,
      }),
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      'products/new',
      false,
      false,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      await this.reset()
      this.isLoading = false
      history.push(`/app/product/detail/${value.data}`)
    } else {
      this.isLoading = false
    }
  }

  @action
  reset() {
  this.isLoading = true

  this.arName = null

  this.enName = null

  this.price = null

  this.enDescription = null

  this.arDescription = ''

  this.imageFile = null

  this.category = null

  this.categoriesList = []

  this.typesList = []

  this.type = null

  this.shopList = []

  this.shop = null

  this.stock = null

  this.width = null

  this.height = null

  this.en_name_validation = null

  this.ar_name_validation = null

  this.price_validation = null

  this.category_validation = null

  this.image_validation = null

  this.shop_validation = null

  this.type_validation = null

  this.stock_validation = null

  this.width_validation = null

  this.height_validation = null

  this.en_description_validation = null

  this.ar_description_validation = null
  }
}
