import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Card, CardBody, CustomInput } from 'reactstrap'
import { Colxx } from '../../../components/CustomBootstrap'

@inject('stores')
@observer
class DayList extends Component {
  @observable
  store = this.props.stores.shopDetail

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // this.store.getData(this.props.history);
  }

  renderCard = (data) => {
    return data.map((item, index) => {
      return (
        <div className="col-6 p-2" key={index}>
          <Card key={index} className="col-12">
            <CardBody className="d-flex align-items-center flex-wrap col-12 p-2 pt-3">
              <h4 className=" m-xl-0 mb-md-2 truncate">
                {item.dayOfWeek}
              </h4>

              <div className="d-flex flex-wrap flex-grow-1 ">
                {item.timeLapses.map((timeLapse, index) => (
                  <div key={index} className="p-3 col-4">
                    <div
                      className="d-flex align-items-center p-3"
                      style={{ border: '1px solid gray', borderRadius: '8px' }}
                    >
                      <CustomInput
                        type="checkbox"
                        key={`checkbox-${timeLapse.timeLapse.id}-${index}-${item.day}`}
                        id={`checkbox-${timeLapse.timeLapse.id}-${index}-${item.day}`}
                        checked={timeLapse.is_active}
                        onChange={() =>
                          this.store.HandleChangeTimeLapse(
                            item.day,
                            timeLapse.timeLapse.id
                          )
                        }
                      />
                      <p className="m-0">{`${timeLapse.timeLapse.open}-${timeLapse.timeLapse.close}`}</p>
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </div>
      )
    })
  }

  render() {
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row className="col-12">
            <Colxx xxs="12" className="d-flex flex-wrap">
              {this.store.shopSchedule &&
                this.renderCard(this.store.shopSchedule)}
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default DayList
