import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PhoneOrderList from './list'
import PhoneOrderDetail from './detail'

const PhoneOrder = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route path={`${match.url}/list`} component={PhoneOrderList} />
      <Route path={`${match.url}/detail/:id`} component={PhoneOrderDetail} />
      <Redirect to="/error" />
    </Switch>
  </div>
)
export default PhoneOrder
