import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Card,
  CardBody,
  CardText,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import SelectInput from '../../../components/select'

@inject('stores')
@observer
class AreaList extends Component {
  @observable
  store = this.props.stores.shopDetail

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // this.store.getData(this.props.history);
  }
  render() {
    const{shopAreaList}= this.store
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row className="col-12">
            <Colxx xxs="4" className="mt-3 mb-5">
              <p>Select Area From List</p>
              <SelectInput
                className="react-select"
                classNamePrefix="react-select"
                value={
                  this.store.selectedshopArea
                    ? this.store.selectedshopArea
                    : null
                }
                onChange={(value) => this.store.setShop(value)}
                options={shopAreaList}
                isMulti={false}
              />
            </Colxx>
            <Colxx xxs="12">
              <Separator className="mb-5" />
              <p>Supported Areas</p>
              <Colxx className="d-flex flex-wrap">
                {this.store.shopArea
                  ? this.store.shopArea.map((area, index) => (
                    <div key={index} className="p-1 mb-3 col-xl-6 col-md-12 col-12 ">
                      <Card
                        key={index}
                        className="col-12"
                        style={{
                          backgroundColor: area.is_active ? 'gray' : 'unset',
                        }}
                      >
                        <CardBody className="d-flex align-items-center flex-wrap col-12">
                          <CardText className="col-xl-3 col-md-12 m-xl-0 mb-md-2 truncate">
                            {area.label}
                          </CardText>
                          <FormGroup className="col-xl-3 col-md-12 d-flex align-items-center m-xl-0 mb-md-2 ">
                            <Label className="m-0 text-left d-xl-none d-md-flex align-items-center col-xl-2 col-md-4 ">
                              Supported:{' '}
                            </Label>
                            <div className="d-flex align-items-center col-12">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse mr-2"
                                name={area.label}
                                value={area.is_active? true : false}
                                onChange={() =>
                                  this.store.handleIsSupported(area.value)
                                }
                                // onBlur={handleBlur}
                              />
                            </div>
                          </FormGroup>
                          {area.is_active ? (
                            <>
                              <FormGroup
                                className="col-xl-6 col-md-12 d-flex align-items-center m-xl-0 mb-md-2"
                                style={{ height: 35 }}
                              >
                                <Label className="m-0 pr-1 text-left d-flex align-items-center">
                                  Price(KD):{' '}
                                </Label>
                                <div className="d-flex align-items-center flex-grow-1 ">
                                  <Input
                                    style={{ height: 35 }}
                                    className="col-12"
                                    type="number"
                                    value={area.cost}
                                    min="0"
                                    onChange={(e) =>
                                      this.store.handleChangePrice(
                                        area.value,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </>
                          ) : null}{' '}
                        </CardBody>
                      </Card>
                      </div>
                    ))
                  : null}
              </Colxx>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default AreaList
