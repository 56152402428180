import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Row, Button } from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import CardThumbList from "../../../components/Card/cardThumblist";
import DisplayOption from "../../../components/DisolayOption";
import CardImageList from "../../../components/Card/cardImageList";
import FeaturetteModal from "../../../components/Modal/featuretteModal";
import Pagination from "../../../components/List/Pagination";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

@inject("stores")
@observer
class FeaturetteList extends Component {
  @observable
  store = this.props.stores.featurette;

  @observable
  setting = this.props.stores.setting;
  constructor(props) {
    super(props);
    this.state = {
      activeFirstTab: "1",
    };
  }
  componentDidMount() {
    this.store.getData();
  }

  componentWillUnmount() {
    this.store.reset();
  }

  toggleTab = (id) => {
    this.setState({ activeFirstTab: id });
    this.store.getData();
  };

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.slide" />}
                  match={this.props.match}
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => this.store.changeOpenModal()}
                >
                  <IntlMessages id="pages.add-new" />
                </Button>
                <FeaturetteModal
                  openModal={this.store.openModal}
                  toggleModal={() => this.store.toggleModal()}
                  imageFile={this.store.imageFileEn}
                  arabicImageFile={this.store.imageFileAr}
                  changeImageFile={(file) => this.store.changeImageFileEn(file)}
                  changeArabicImageFile={(file) =>
                    this.store.changeImageFileAr(file)
                  }
                  name={this.store.name}
                  url={this.store.url}
                  changeUrl={(data) => this.store.changeUrl(data)}
                  changeName={(data) => this.store.changeName(data)}
                  handleSave={() => this.store.validation(this.props.history)}
                  btnDisable={this.store.btnDisable}
                  name_validation={this.store.en_name_validation}
                  imageFile_validation={this.store.imageFile_validation}
                  selectedType={this.store.selectedType}
                  typeList={this.store.keys}
                  setType={(data) => this.store.setType(data)}
                  selectedItem={this.store.selectedItem}
                  itemList={this.store.itemList}
                  setItem={(data) => this.store.setItem(data)}
                  ar_imageFile_validation={this.store.ar_imageFile_validation}
                  item_validation={this.store.item_validation}
                  url_validation={this.store.url_validation}
                  type_validation={this.store.type_validation}
                />
                <Row>
                  <DisplayOption />
                </Row>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12">
                {this.setting.displayMode === "thumblist" ? (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((featurette) => (
                          <CardThumbList
                            key={featurette.id}
                            data={featurette}
                            path="slide/detail"
                          />
                        ))
                      : null}
                  </Row>
                ) : (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((featurette) => (
                          <CardImageList
                            key={featurette.id}
                            data={featurette}
                            path="slide/detail"
                          />
                        ))
                      : null}
                  </Row>
                )}
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}{" "}
          </>
        )}
      </Fragment>
    );
  }
}

export default FeaturetteList;
