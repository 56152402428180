import { observable, action } from 'mobx'
import moment from 'moment'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify, errorNotify } from '../util/Notify'

export default class ShopDetailStore {
  @observable
  shopDetail = null

  @observable
  shopSchedule = null

  @observable
  shopArea = []

  @observable
  delivery_days = null

  @observable
  imageFile = null

  @observable
  coverImageFile = null

  @observable
  listOfDay = moment.weekdays()

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 10

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  is_available_validation = null

  @observable
  pre_order_validation = null

  @observable
  address_validation = null

  @observable
  ar_address_validation = null

  @observable
  phone_validation = null

  @observable
  imageFile_validation = null

  @observable
  coverImageFile_validation = null

  @observable
  shopAreaList = []

  @observable
  selectedshopArea = null

  @observable
  showUploaderMain = true

  @observable
  showUploaderCover = true

  @observable
  asap_validation = null

  @action
  handleImageUploaderMain = () => {
    this.showUploaderMain = !this.showUploaderMain
  }

  @action
  handleImageUploaderCover = () => {
    this.showUploaderCover = !this.showUploaderCover
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderMain(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderMain = !this.showUploaderMain
  }

  @action
  async changeImageFileUploderCover(file) {
    this.coverImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderCover = !this.showUploaderCover
  }

  @action
  async validation(id, history) {
    if (!this.shopDetail) {
      return
    }
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.shopDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.shopDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.asap_validation = await checkInput({
      name: 'As Soon Possible Time',
      value: this.shopDetail.asSoonPossibleTime,
      require: true,
      type: 'number',
      minValue: 10,
    })

    
    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.asap_validation.res &&
      this.handleUpdateShop(id, history)
  }

  @action
  getShopDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'shops',
      false,
      id,
      false,
      'get',
      false,
      {},
      history
    )
    if (value) {
      this.shopDetail = value.data
      await this.getShopArea(id, history)
      await this.getShopSchedule(id, history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getShopSchedule = async (id, history) => {
    const value = await axiFetch.request(
      'shops',
      false,
      id,
      'schedule',
      'get',
      false,
      {},
      history
    )
    if (value) {
      await value.data.forEach((item, index) => {
        item.dayOfWeek = this.listOfDay[index]
      })
      this.shopSchedule = value.data
    }
  }

  @action
  setShopSchedule = async (id, history) => {
    this.isLoading = true
    await this.shopSchedule.forEach(async (item) => {
      const data = []
      await item.timeLapses.forEach((time) => {
        if (time.is_active) {
          data.push(time.timeLapse.id)
        }
      })
      if (data.length) {
        await axiFetch.request(
          'shops',
          false,
          id,
          'schedule',
          'post',
          false,
          { timeLapseIds: data, is_active: true, day: item.day },
          history
        )
      }
    })

    await this.shopSchedule.forEach(async (item) => {
      const data = []
      await item.timeLapses.forEach((time) => {
        if (!time.is_active) {
          data.push(time.timeLapse.id)
        }
      })
      if (data.length) {
        await axiFetch.request(
          'shops',
          false,
          id,
          'schedule',
          'post',
          false,
          { timeLapseIds: data, is_active: false, day: item.day },
          history
        )
      }
    })

    successNotify('Item has been deleted successfully')

    this.isLoading = false
  }

  @action
  HandleChangeTimeLapse = (day, time) => {
    this.shopSchedule.forEach((item) => {
      if (item.day === day) {
        item.timeLapses.forEach((timeLapse) => {
          if (timeLapse.timeLapse.id === time) {
            timeLapse.is_active = timeLapse.is_active === 1 ? 0 : 1
          }
        })
      }
    })
  }

  @action
  getShopArea = async (id, history) => {
    if (!this.shopDetail) {
      return
    }
    const value = await axiFetch.request(
      `shops/${id}/area`,
      false,
      false,
      false,
      'get',
      false,
      false,
      history
    )

    if (value) {
      // this.shopArea = value.data.items
      this.shopAreaList = []
      await value.data.items.forEach(async (item) => {
        await item.children.forEach((area) => {
          if (!area.is_active) {
            this.shopAreaList.push({
              value: area.id,
              label: area.name_en,
            })
          } else {
            this.shopArea.push({
              value: area.id,
              label: area.name_en,
              cost: area.cost,
              is_active: area.is_active,
            })
          }
        })

        // if (item.parent_id) {
        //   if (!item.is_supported) {
        //     return this.shopAreaList.push({
        //       value: item.id,
        //       label: item.name_en,
        //     })
        //   }
        // }
      })
    } else {
      this.isLoading = false
    }
  }

  @action
  setShop(value) {
    this.selectedshopArea = value.selectedOption
    this.shopAreaList.forEach((area) => {
      if (area.value === value.selectedOption.value) {
        const index = this.shopAreaList.indexOf(area)
        if (index > -1) {
          this.shopAreaList.splice(index, 1)
        }
        this.shopArea.unshift({
          value: area.value,
          label: area.label,
          is_active: true,
          cost: 0,
        })
      }
    })
  }

  @action
  deleteShop = async (id, history) => {
    if (!this.shopDetail) {
      this.openDeleteModal = false
      this.isLoading = false
      return
    }
    this.isLoading = true
    const value = await axiFetch.request(
      'shops',
      false,
      `${id}/delete`,
      false,
      'post',
      false,
      {},
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/shop')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  handleUpdateShop = async (id, history) => {
    this.isLoading = true
    // var result = {}
    // for (var i = 0; i < this.delivery_days.length; i++) {
    //   result[Object.keys(this.delivery_days[i])[0]] = this.delivery_days[i][
    //     Object.keys(this.delivery_days[i])[0]
    //   ]
    // }
    let body = {
      perData: {
        name_en: this.shopDetail.name_en,
        name_ar: this.shopDetail.name_ar,
      },

      // is_available: this.shopDetail.is_available,
      // custom_cake_available: this.shopDetail.custom_cake_available,
      // pre_order: this.shopDetail.pre_order === 'Available' ? true : false,
      // address_en: this.shopDetail.address,
      // address_ar: this.shopDetail.address_ar,
      // phone: this.shopDetail.phone,
      // delivery_days: JSON.stringify(result),
    }
    if (this.imageFile) {
      body.logo = this.imageFile
    }
    if (this.coverImageFile) {
      body.banner = this.coverImageFile
    }
    if (this.shopDetail.email1) {
      body.perData.email1 = this.shopDetail.email1
    }
    if (this.shopDetail.email2) {
      body.perData.email2 = this.shopDetail.email2
    }
    if (this.shopDetail.phone1) {
      body.perData.phone1 = this.shopDetail.phone1
    }
    if (this.shopDetail.phone2) {
      body.perData.phone2 = this.shopDetail.phone2
    }
    if (this.shopDetail.commission) {
      body.perData.commission = this.shopDetail.commission
    }
    if (this.shopDetail.asSoonPossibleTime) {
      body.perData.asSoonPossibleTime = this.shopDetail.asSoonPossibleTime
    }

    body.data = JSON.stringify(body.perData)
    delete body.perData
    let formData = new FormData()
    for (let key in body) formData.append(key, body[key])

    const value = await axiFetch.request(
      'shops',
      false,
      id,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.imageFile = null
      this.coverImageFile = null
      successNotify('Item has been updated successfully')
      this.getShopDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  areaValidation = (id, history) => {
    let priceValidation = false
    let timeValidation = false

    this.shopArea.forEach((area) => {
      if (area.is_supported) {
        !area.price && (priceValidation = true)
        !area.delivery_time && (timeValidation = true)
      }
    })
    if (priceValidation) {
      errorNotify('Please fill all the price fields.')
    } else if (timeValidation) {
      errorNotify('Please fill all the delivery fields.')
    } else {
      this.handleUpdateshopArea(id, history)
    }
  }

  @action
  handleUpdateshopArea = async (id, history) => {
    this.isLoading = true
    var areas = []
    this.shopArea.forEach((area) => {
      // if (area.is_supported) {
      areas = [
        ...areas,
        {
          areaId: area.value,
          cost: area.cost,
          is_active: area.is_active ? true : false,
        },
      ]
      // }
    })

    this.shopAreaList.forEach((area) => {
      areas = [
        ...areas,
        {
          areaId: area.value,
          cost: 0,
          is_active: false,
        },
      ]
    })

    const body = { recipe: areas }
    const value = await axiFetch.request(
      'shops',
      false,
      `${id}/area`,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      this.selectedshopArea = null
      this.shopArea = []
      this.shopAreaList = []
      await this.getShopArea(id, history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeCoverImageFile = (file) => {
    this.coverImageFile = file
  }

  @action
  changeEnName = (name) => {
    this.shopDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.shopDetail.name_ar = name
  }

  @action
  changeAddress = (value) => {
    this.shopDetail.address = value
  }

  @action
  changeArAddress = (value) => {
    this.shopDetail.address_ar = value
  }

  @action
  changePhone1 = (number) => {
    this.shopDetail.phone1 = number
  }

  @action
  changePhone2 = (number) => {
    this.shopDetail.phone2 = number
  }

  @action
  changeEmail1 = (value) => {
    this.shopDetail.email1 = value
  }

  @action
  changeEmail2 = (value) => {
    this.shopDetail.email2 = value
  }

  @action
  changeAsSoonPossibleTime(number) {
    this.shopDetail.asSoonPossibleTime = number
  }

  @action
  changeCommission(number) {
    this.shopDetail.commission = number
  }

  @action
  handleChangePreOrder(value) {
    this.shopDetail.pre_order =
      value === 'Available' ? 'UnAvailable' : 'Available'
  }

  @action
  handleChangeIsAvailable(value) {
    this.shopDetail.is_available = !value
  }

  @action
  handleChangeCustomCakeAvailable = (value) => {
    this.shopDetail.custom_cake_available = !value
  }

  @action
  handleIsAvailable(index, value, day) {
    this.delivery_days[index][day].available = !value
  }

  @action
  handleIsSupported(id) {
    this.shopArea.forEach((area) => {
      if (area.value === id) {
        area.is_active = !area.is_active
        const index = this.shopArea.indexOf(area)
        if (index > -1) {
          this.shopArea.splice(index, 1)
        }
        this.shopAreaList.push({ value: area.value, label: area.label })
        this.selectedshopArea = { value: area.value, label: area.label }
      }
    })
  }

  @action
  handleChangeTime(id, value) {
    this.shopArea.forEach((area) => {
      if (area.id === id) {
        area.delivery_time = value
      }
    })
  }

  @action
  handleChangePrice(id, value) {
    this.shopArea.forEach((area) => {
      if (area.value === id) {
        area.cost = value
      }
    })
  }

  @action
  changeCompleteHandler() {}

  @action
  changeStartHandler() {}

  @action
  timeChangeHandler(time, index, day) {
    this.delivery_days[index][day].time_from = time.start
    this.delivery_days[index][day].time_to = time.end
  }

  @action
  reset() {
  this.shopDetail = null

  this.shopSchedule = null

  this.shopArea = []

  this.delivery_days = null

  this.imageFile = null

  this.coverImageFile = null

  this.listOfDay = moment.weekdays()

  this.search = null

  this.searchOn = null

  this.limit = 10

  this.offset = 0

  this.order = { id: -1 }

  this.filter = {}

  this.isLoading = true

  this.openDeleteModal = false

  this.en_name_validation = null

  this.ar_name_validation = null

  this.is_available_validation = null

  this.pre_order_validation = null

  this.address_validation = null

  this.ar_address_validation = null

  this.phone_validation = null

  this.imageFile_validation = null

  this.coverImageFile_validation = null

  this.shopAreaList = []

  this.selectedshopArea = null

  this.showUploaderMain = true

  this.showUploaderCover = true

  this.asap_validation = null
  }
}
