import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'

export default class FieldStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { name_en: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  newArName = ''

  @observable
  newEnName = ''

  @observable
  title = ''

  @observable
  state = 0

  @observable
  fieldsList = []

  @observable
  selectedField = null

  @observable
  btnDisable = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  field_validation = null

  @observable
  selectedOrderOption = { column: 'name_en', label: 'Name' }

  @observable
  orderOptions = [
    { column: 'createdAt', label: 'Create Date' },
    { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
    { column: 'name_en', label: 'Name' },
    { column: 'name_en_asc', label: 'Name (Ascending)' },
    { column: 'name_ar', label: 'Arabic Name' },
    { column: 'name_ar_asc', label: 'Arabic Name (Ascending)' },
  ]

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.field_validation = await checkInput({
      name: 'Type',
      value: this.selectedField,
      require: true,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.field_validation.res &&
      this.handleSaveNewType(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters: this.filter,
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }
    const value = await axiFetch.request(
      'addresses/types',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      await this.getDependencies(history)
      this.pagination = value.data.explain.pagination.totalPages
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const fields = await axiFetch.request(
      'addresses/fields',
      false,
      false,
      false,
      'get',
      false,
      null,
      history
    )
    if (fields) {
      await fields.data.items.map((field) =>
        this.fieldsList.push({
          value: field.id,
          label: field.title,
        })
      )
    }
  }

  @action
  handleSaveNewType = async (history) => {
    const ids = []
    await this.selectedField.forEach((item) => {
      ids.push(item.value)
    })
    this.isLoading = true
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
      field_ids: ids,
    }
    const value = await axiFetch.request(
      'addresses/types/new',
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      this.openModal = false
      this.btnDisable = false
      history.push(`/app/addressType/detail/${value.data}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = null
    this.newEnName = null
    this.en_name_validation = null
    this.ar_name_validation = null
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  setType(data) {
    this.selectedField = data.selectedOption
  }


  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  reset() {
  this.data = null

  this.pagination = 0

  this.currentPage = 1

  this.imageFile = null

  this.search = ''

  this.searchOn = null

  this.limit = 12

  this.offset = 1

  this.order = { name_en: -1 }

  this.filter = {}

  this.isLoading = true

  this.openModal = false

  this.newArName = ''

  this.newEnName = ''

  this.title = ''

  this.state = 0

  this.fieldsList = []

  this.selectedField = null

  this.btnDisable = false

  this.en_name_validation = null

  this.ar_name_validation = null

  this.field_validation = null

  this.selectedOrderOption = { column: 'name_en', label: 'Name' }

  this.orderOptions = [
    { column: 'createdAt', label: 'Create Date' },
    { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
    { column: 'name_en', label: 'Name' },
    { column: 'name_en_asc', label: 'Name (Ascending)' },
    { column: 'name_ar', label: 'Arabic Name' },
    { column: 'name_ar_asc', label: 'Arabic Name (Ascending)' },
  ]
  }
}
