import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import moment from 'moment'

export default class DashboardStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  event = []

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  calLoading = false

  @observable
  btnDisable = false

  @observable
  preparingOrders = null

  @observable
  sentOrders = null

  @observable
  canceledOrders = null

  @observable
  complatedOrders = null

  @observable
  totalSale = null

  @observable
  totalSaleItem = null

  @observable
  recentOrders = null

  @observable
  topFilling = null

  @observable
  topFlavor = null

  @observable
  topOuterShell = null

  @observable
  topBakeries = null

  @observable
  topSellers = null

  @observable
  months = moment.months()

  @observable
  years = null

  @observable
  target = 'month'

  @observable
  targetMonth = moment().format('MMMM')

  @observable
  targetYear = moment().format('YYYY')

  @observable
  labels = []

  @observable
  sale = []

  @observable
  approvalsList = null

  @observable
  openApprovalModal = false

  @observable
  liveEventsList = []

  @observable
  availableYears = []

  @action
  async getTodayData(history) {
    this.isLoading = true
    this.sale = []
    this.labels = []
    const body = {
      [this.target]:
        this.target === 'today'
          ? true
          : this.target === 'month'
          ? {
              date:
                moment(
                  `${moment().month(this.targetMonth).format('M')}/15/${
                    this.targetYear
                  } 10:00:00 AM `
                ).format('X') * 1000,
            }
          : this.target === 'year'
          ? {
              date:
                moment(`6/15/${this.targetYear} 10:00:00 AM `).format('X') *
                1000,
            }
          : true,
    }
    const value = await axiFetch.request(
      'dashboard/report',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )

    if (value) {
      this.data = value.data

      value.data.chart.forEach((item) => {
        this.labels.push(
          this.target === 'today'
            ? item.x
            : this.target === 'year'
            ? moment().month(item.x).format('MMMM')
            : this.target === 'month'
            ? item.x
            : item.x
        )
        this.sale.push(item.y)
      })

      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getBaseData = async (history) => {
    const value = await axiFetch.request(
      'dashboard/base',
      false,
      false,
      false,
      'get',
      false,
      false,
      history
    )

    if (value) {
      this.availableYears = value.data.availableYears
    }
  }

  @action
  async getMonthDataCal(m, y, history) {
    this.event = []
    this.calLoading = true
    const body = {
      month: {
        date: `${moment(`${m}/15/${y} 10:00:00 AM `).format('X') * 1000}`,
      },
    }
    const value = await axiFetch.request(
      'dashboard/calendar',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )

    if (value) {
      value.data.rawCalendar.forEach((item) => {
        this.event.push(
          {
            key: `${item.day}`,
            title: item.amount ? `Amount: ${item.amount} KD` : null,
            start: `${y}/${m}/${item.day}`,
            end: `${y}/${m}/${item.day}`,
          },
          item.commissionSum && {
            key: `${item.day}`,
            title: item.count ? `Count: ${item.count}` : null,
            start: `${y}/${m}/${item.day}`,
            end: `${y}/${m}/${item.day}`,
          }
        )
      })
      this.calLoading = false
    }
  }

  @action
  handleFilter(history) {
    switch (this.target) {
      case 'allTime':
        this.getTodayData(history)
        break
      case 'month':
        this.getTodayData(history)
        break
      case 'year':
        this.getTodayData(history)
        break

      case 'today':
        this.getTodayData(history)
        break

      default:
        break
    }
  }

  @action
  getWaitingApproval = async (history) => {
    this.calLoading = true
    const value = await axiFetch.request(
      'dashboard/waitingApproval',
      false,
      false,
      false,
      'get',
      false,
      false,
      history
    )

    if (value) {
      this.calLoading = false
      this.approvalsList = value.data
      return value.data
    } else {
      this.calLoading = false
      return []
    }
  }

  @action
  getLiveEvents = async (history) => {
    const value = await axiFetch.request(
      'dashboard/live',
      false,
      false,
      false,
      'get',
      false,
      false,
      history
    )

    if (value) {
      this.liveEventsList = value.data
      return value.data
    } else {
      return []
    }
  }

  @action
  handleApprovalModal = () => {
    this.openApprovalModal = !this.openApprovalModal
  }

  @action
  goToEventDetail = (id, history) => {
    this.handleApprovalModal()
  }

  @action
  handleTarget(value) {
    this.target = value
  }

  @action
  handleMonth(value) {
    this.targetMonth = value
  }

  @action
  handleYear(value) {
    this.targetYear = value
  }
}
