import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TypeList from './list';
import TypeDetail from './detail';



const Type = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={TypeList} />
            <Route path={`${match.url}/detail/:id`} component={TypeDetail} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Type;