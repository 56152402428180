import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row } from 'reactstrap'
import { Colxx } from '../../../components/CustomBootstrap'
import CardThumbList from '../../../components/Card/cardThumblist'
import Pagination from '../../../components/List/Pagination'
// import "./react-toastify/dist/ReactToastify.css";

@inject('stores')
@observer
class AreaList extends Component {
  @observable
  store = this.props.stores.area

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    !this.store.data &&
      this.store.getData(this.props.history, this.props.parentId)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row className="col-12">
              <Colxx xxs="12">
                <Row>
                  {this.store.data
                    ? this.store.data.map((item) => (
                        <CardThumbList
                          key={item.id}
                          data={item}
                          // path="area/detail"
                          path="area/city"
                        />
                      ))
                    : null}
                </Row>
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e, this.props.history, this.props.parentId)}
                currentPage={this.store.currentPage}
              />
            ) : null}{' '}
          </>
        )}
      </Fragment>
    )
  }
}

export default AreaList
