import React, { Component } from 'react'
import { Route, withRouter, Switch, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import Sidebar from '../containers/Sidebar'
import TopNav from '../containers/TopNav'
import Dashboards from './dashboards'
import Category from './category'
import Product from './product'
import Type from './type'
import Order from './order'
import Area from './area'
import Collection from './collection'
import Shop from './shop'
import Customers from './customer'
import Featurette from './featurette'
import AppHomepage from './appHomepage'
import Banner from './banner/index'
import Coupon from "./coupon"
import Static from './static'
import Settings from "./setting/index";
import PhoneOrder from './phoneOrder/index'
import Profile from "./profile";
import Users from "./users";
import Field from './field/index'
import AddressType from './addressType/index'


// import Gallery from "./gallery";
// import Filling from "./filling";
// import Flavor from "./flavor";
// import Shape from "./shape";
// import OuterShell from "./outerShell";
// import Layer from "./layer";
// import Collection from "./collection";
// import BakeryInfo from "./bakeryInfo/index";

@inject('stores')
@observer
class MainRoute extends Component {
  @observable
  store = this.props.stores.menu

  @observable
  login = this.props.stores.login
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.login.checkUserLogin(this.props.history)
  }
  render() {
    const { match } = this.props
    const { containerClassnames } = this.store
    return (
      <div id="app-container" className={containerClassnames}>
        <TopNav history={this.props.history} userName={localStorage.getItem('lilas_userName')}></TopNav>
        <Sidebar location={this.props.location} history={this.props.history}/>
        <main>
          <div className="container-fluid">
            <Switch>
              <Route path={`${match.url}/dashboards`} component={Dashboards} />
              <Route path={`${match.url}/category`} component={Category} />
              <Route path={`${match.url}/product`} component={Product} />
              <Route path={`${match.url}/type`} component={Type} />
              <Route path={`${match.url}/order`} component={Order} />
              <Route path={`${match.url}/phoneOrder`} component={PhoneOrder} />
              <Route path={`${match.url}/area`} component={Area} />
              <Route path={`${match.url}/collection`} component={Collection} />
              <Route path={`${match.url}/shop`} component={Shop} />
              <Route path={`${match.url}/customers`} component={Customers} />
              <Route path={`${match.url}/slide`} component={Featurette} />
              <Route path={`${match.url}/banner`} component={Banner} />
              <Route path={`${match.url}/appHome`} component={AppHomepage} />
              <Route path={`${match.url}/coupon`} component={Coupon} />
              <Route path={`${match.url}/pages`} component={Static} />
              <Route path={`${match.url}/settings`} component={Settings} />
              <Route path={`${match.url}/profile`} component={Profile} />
              <Route path={`${match.url}/users`} component={Users} />
              <Route path={`${match.url}/field`} component={Field} />
              <Route path={`${match.url}/addressType`} component={AddressType} />




              {/* <Route path={`${match.url}/category`} component={Category} />
              <Route path={`${match.url}/gallery`} component={Gallery} />
              <Route path={`${match.url}/bakery`} component={Bakery} />
              <Route path={`${match.url}/filling`} component={Filling} />
              <Route path={`${match.url}/flavor`} component={Flavor} />
              <Route path={`${match.url}/shape`} component={Shape} />
              <Route path={`${match.url}/outerShell`} component={OuterShell} />
              <Route path={`${match.url}/layer`} component={Layer} />
              <Route path={`${match.url}/collection`} component={Collection} />
              <Route path={`${match.url}/bakeryInfo`} component={BakeryInfo} /> */}

              <Redirect to="/error" />
            </Switch>
          </div>
        </main>
      </div>
    )
  }
}

export default withRouter(MainRoute)
