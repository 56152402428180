import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'

export default class FieldStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 1

  @observable
  order = { createdAt: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  newArName = ''

  @observable
  newEnName = ''

  @observable
  title = ''

  @observable
  state = 0

  @observable
  typesList = [
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'phone', label: 'Phone' },
  ]

  @observable
  stylesList = [
    { value: 'half', label: 'Half' },
    { value: 'full', label: 'Full' },
  ]

  @observable
  selectedType = null

  @observable
  selectedStyle = null

  @observable
  btnDisable = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  title_validation = null

  @observable
  type_validation = null

  @observable
  style_validation = null

  @observable
  selectedOrderOption = { column: 'createdAt', label: 'Create Date' }

  @observable
  orderOptions = [
    { column: 'createdAt', label: 'Create Date' },
    { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
    { column: 'name_en', label: 'Name' },
    { column: 'name_en_asc', label: 'Name (Ascending)' },
    { column: 'name_ar', label: 'Arabic Name' },
    { column: 'name_ar_asc', label: 'Arabic Name (Ascending)' },
  ]

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.title_validation = await checkInput({
      name: 'Title',
      value: this.title,
      require: true,
      type: 'string',
      min: 3,
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,

    })
    this.style_validation = await checkInput({
      name: 'Style',
      value: this.selectedStyle,
      require: true,

    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.title_validation.res &&
      this.type_validation.res &&
      this.style_validation.res &&
      this.handleSaveNewField(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      sorts: this.order,
      search: this.search,
      filters: this.filter,
      pagination: {
        limit: this.limit,
        page: this.offset,
      },
    }
    const value = await axiFetch.request(
      'addresses/fields',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination = value.data.explain.pagination.totalPages
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  handleSaveNewField = async (history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
      title: this.title,
      style:this.selectedStyle.value,
      is_required: this.state,
      joiconfig_type: this.selectedType.value,
    }
    const value = await axiFetch.request(
      'addresses/fields/new',
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      this.openModal = false
      this.btnDisable = false
      history.push(`/app/field/detail/${value.data}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = null
    this.newEnName = null
    this.en_name_validation = null
    this.ar_name_validation = null
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  changeTitle = (value) => {
    this.title = value
  }

  @action
  changeState = () => {
    this.state = this.state ? 0 : 1
  }
  @action
  setType(data) {
    this.selectedType = data.selectedOption
  }

  @action
  setStyle(data) {
    this.selectedStyle = data.selectedOption
  }

  @action
  changePage(e) {
    this.offset = e
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 1
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 1
    this.currentPage = 1
    this.getData(history)
  }

  @action
  reset() {
    this.data = null
  
    this.pagination = 0
  
    this.currentPage = 1
  
    this.imageFile = null
  
    this.search = ''
  
    this.searchOn = null
  
    this.limit = 12
  
    this.offset = 1
  
    this.order = { createdAt: -1 }
  
    this.filter = {}
  
    this.isLoading = true
  
    this.openModal = false
  
    this.newArName = ''
  
    this.newEnName = ''
  
    this.title = ''
  
    this.state = 0
  
    this.typesList = [
      { value: 'text', label: 'Text' },
      { value: 'number', label: 'Number' },
      { value: 'phone', label: 'Phone' },
    ]
  
    this.stylesList = [
      { value: 'half', label: 'Half' },
      { value: 'full', label: 'Full' },
    ]
  
    this.selectedType = null
  
    this.selectedStyle = null
  
    this.btnDisable = false
  
    this.en_name_validation = null
  
    this.ar_name_validation = null
  
    this.title_validation = null
  
    this.type_validation = null
  
    this.style_validation = null
  
    this.selectedOrderOption = { column: 'createdAt', label: 'Create Date' }
  
    this.orderOptions = [
      { column: 'createdAt', label: 'Create Date' },
      { column: 'createdAt_asc', label: 'Create Date (Ascending)' },
      { column: 'name_en', label: 'Name' },
      { column: 'name_en_asc', label: 'Name (Ascending)' },
      { column: 'name_ar', label: 'Arabic Name' },
      { column: 'name_ar_asc', label: 'Arabic Name (Ascending)' },
    ]
  }
}
